import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { defer, timer } from 'rxjs';
import { map, tap, delay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import {
  Init,
  ActionTypes,
  SetLanguage,
  SetCurrency
} from './settings-core.action';
import {
  DomainLanguageMap,
  DomainCurrencyMap,
  LanguageDomainRedirectMap
} from './settings-core.constant';

@Injectable()
export class SettingsCoreEffect {
  @Effect()
  private _onInit$ = defer(() => timer(0)).pipe(
    map(() => new Init())
  );

  @Effect()
  private _onSetDefaultLanguage$ = this._actions$.pipe(
    ofType<Init>(ActionTypes.Init),
    map(() => new SetLanguage(this._getDomainLanguage(), false))
  );

  @Effect()
  private _onSetDefaultCurrency$ = this._actions$.pipe(
    ofType(ActionTypes.Init),
    map(() => new SetCurrency(this._getDomainCurrency()))
  );

  @Effect({ dispatch: false })
  private _onSetLanguage$ = this._actions$.pipe(
    ofType<SetLanguage>(ActionTypes.SetLanguage),
    tap(({ payload }) => this._translate.use(payload)),
    tap(({ payload, meta }) => {
      if (environment.redirectLanguage && LanguageDomainRedirectMap[payload] && meta) {
        window.location.replace(LanguageDomainRedirectMap[payload] + window.location.pathname);
      }
    })
  );

  @Effect({ dispatch: false })
  private _onActivatePrerender$ = this._actions$.pipe(
    ofType<Init>(ActionTypes.Init),
    delay(100),
    tap(() => (window as any).prerenderReady = true)
  );


  constructor(
    private _actions$: Actions,
    private _translate: TranslateService
  ) { }

  private _getDomain() {
    return window.location.hostname;
  }

  private _getDomainLanguage() {
    return DomainLanguageMap[this._getDomain()] || this._translate.getBrowserLang();
  }

  private _getDomainCurrency() {
    return DomainCurrencyMap[this._getDomain()] || 'czk';
  }
}
