import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  CustomFlexDirective,
  CustomFlexClassDirective,
  CustomFlexGapDirective
} from './flex.directive';

@NgModule({
  declarations: [
    CustomFlexDirective,
    CustomFlexClassDirective,
    CustomFlexGapDirective
  ],
  exports: [
    FlexLayoutModule,
    CustomFlexDirective,
    CustomFlexClassDirective,
    CustomFlexGapDirective
  ]
})
export class FlexModule { }
