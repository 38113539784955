import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IntercomApi {
  showNewMessage(message?: string) {
    if ((window as any).Intercom) {
      (window as any).Intercom(
        'showNewMessage',
        message
      );
    }
  }

  update(data: any) {
    if ((window as any).Intercom) {
      (window as any).Intercom(
        'update',
        data
      );
    }
  }
}
