import { createEntityAdapter } from '@ngrx/entity';
import { BlogEntity, TagEntity } from './blog-core.model';

export const postsAdapter = createEntityAdapter<BlogEntity>({
  selectId: (blog: BlogEntity) => blog.slug
});

export const tagsAdapter = createEntityAdapter<any>({
  selectId: (tag: TagEntity) => tag.slug
});
