<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281 1079">
  <path d="M3.1 1079.3h-3l-.3-206.7c0-16.4 6.4-32 16.8-40.5l.1-.1 97.9-66.9c9.7-8 15.6-22.6 15.6-38.1l-.1-47.7-.2-148.7c0-16.4 6.4-32 16.8-40.5l51.2-42.1c9.7-8 15.7-22.6 15.7-38.2l-.8-408h3l.7 407.9c0 16.4-6.4 32-16.8 40.5l-51.2 42.1c-9.7 8-15.7 22.6-15.7 38.2l.3 196.4c0 16.4-6.4 32-16.8 40.5l-.1.1-97.9 66.9c-9.7 8-15.6 22.6-15.6 38.1l.4 206.8z"/>
  <path d="M10.3 1079.3h-3L7 875.9c0-15.3 5.8-29.6 15.3-37.4l.1-.1 98.7-67.9c10.6-8.7 17.1-24.5 17.1-41.2l-.1-47.7-.2-149.2c0-15.3 5.8-29.6 15.3-37.4l51.2-42.1c10.6-8.7 17.2-24.5 17.1-41.3l-.7-409.8h3l.7 409.7c0 17.6-7 34.3-18.2 43.6l-51.2 42.1c-8.8 7.2-14.2 20.7-14.2 35.1l.3 197c0 17.6-7 34.3-18.2 43.6l-.1.1-98.7 67.9c-8.8 7.2-14.2 20.7-14.2 35l.3 203.4z"/>
  <path d="M17.5 1079.3h-3l-.3-200.1c0-14.1 5.3-27.2 13.8-34.3l.1-.1 99.5-68.8c11.5-9.5 18.6-26.5 18.6-44.3l-.3-197.6c0-14.1 5.3-27.2 13.8-34.3l51.2-42.1c11.5-9.5 18.6-26.5 18.6-44.4l-.6-411.5h3l.7 411.5c0 18.8-7.5 36.7-19.7 46.7l-51.2 42.1c-7.8 6.4-12.8 18.9-12.7 32l.3 197.6c0 18.8-7.5 36.7-19.7 46.7l-.1.1L30 847.3c-7.7 6.4-12.7 18.9-12.7 31.9l.2 200.1z"/>
  <path d="M24.7 1079.3h-3l-.3-196.8c0-12.7 4.8-25 12.4-31.2l.1-.1 100.3-69.7c12.6-10.4 20-28.1 20-47.5l-.3-198c0-12.7 4.8-25 12.4-31.2l51.2-42.1c12.6-10.4 20.1-28.1 20.1-47.5l-.7-413.4h3l.7 413.4c0 20.3-7.9 38.9-21.2 49.8l-51.2 42.1c-5.2 4.3-11.3 14.4-11.3 28.9l.3 198.1c0 20.3-7.9 38.9-21.2 49.8v.1L35.7 853.7c-5.2 4.3-11.3 14.4-11.2 28.8l.2 196.8z"/>
  <path d="M32 1079.3h-3l-.3-193.4c0-14.1 5.9-23.9 10.9-28.1l.1-.1 101.1-70.6c13.5-11.1 21.5-30 21.5-50.6l-.3-198.7c0-14.1 5.9-23.9 10.9-28.1l51.2-42.1c13.5-11.1 21.6-30 21.5-50.6l-.7-415.2h3l.7 415.2c0 21.5-8.4 41.2-22.6 52.9L174.8 512c-4.9 4-9.8 13.6-9.8 25.7l.3 198.7c0 21.5-8.4 41.2-22.6 52.9l-.1.1-101.2 70.7c-4.9 4.1-9.8 13.6-9.8 25.7l.4 193.5z"/>
  <path d="M39.2 1079.2h-3l-.3-190.1c0-11.9 4.7-21.1 9.5-25l.1-.1 101.9-71.5c14.4-11.9 23-31.9 22.9-53.7l-.3-199.2c0-11.9 4.7-21.1 9.4-25l51.2-42.1c14.4-11.9 23-31.9 23-53.7l-.7-417h3l.7 417c0 22.6-9 43.6-24.1 56L181.3 517c-4.2 3.4-8.4 11.8-8.4 22.6l.3 199.3c0 22.6-9 43.6-24.1 56l-.1.1-101.8 71.5c-4.2 3.5-8.3 11.8-8.3 22.6l.3 190.1z"/>
  <path d="M46.4 1079.2h-3l-.3-186.8c0-10.5 4.1-18.7 8-21.9l.1-.1 102.7-72.5c15.3-12.6 24.5-33.9 24.4-56.8l-.3-199.7c0-10.5 4.1-18.7 8-21.9l51.2-42.1c15.4-12.6 24.5-33.9 24.5-56.8L261 1.8h3l.7 418.9c0 23.8-9.5 45.9-25.5 59.1L188 521.9c-3.3 2.7-6.9 10-6.9 19.5l.3 199.8c0 23.8-9.5 45.9-25.6 59.1l-.1.1L53 872.9c-3.3 2.8-6.9 10-6.8 19.5l.2 186.8z"/>
  <path d="M53.6 1079.2h-3l-.3-183.5c0-9.8 3.9-16.6 6.5-18.8l.1-.1 103.5-73.4c16.2-13.4 25.9-35.8 25.9-59.9l-.3-200.2c0-9.8 3.9-16.6 6.5-18.8l51.2-42.1c16.3-13.4 26-35.8 25.9-59.9L269 1.8h3l.7 420.7c0 25-10.1 48.3-27 62.2l-51.2 42.1c-2.1 1.7-5.4 7.7-5.4 16.4l.3 200.4c0 25-10.1 48.3-27 62.2l-.1.1-103.6 73.5c-2.1 1.8-5.4 7.7-5.4 16.4l.3 183.4z"/>
  <path d="M60.9 1079.2h-3l-.3-180.1c0-8.7 3.4-14.3 5.1-15.6l.1-.1L167 809.1c17.2-14.1 27.4-37.7 27.3-63l-.3-201c0-8.7 3.4-14.3 5.1-15.6l51.2-42.1c17.2-14.1 27.4-37.7 27.4-63L277 1.7h3l.7 422.5c0 26.2-10.6 50.6-28.5 65.3L201 531.8c-1.1.9-4 5.7-4 13.3l.3 201c0 26.2-10.6 50.6-28.5 65.3l-.1.1-104.2 74.3c-1.1.9-3.9 5.7-3.9 13.3l.3 180.1z"/>
</svg>
