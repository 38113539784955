import { BREAKPOINT } from '@angular/flex-layout';
const ɵ0 = [
    {
        alias: 'sd',
        mediaQuery: 'screen and (min-width: 600px) and (max-width: 700px)',
        priority: 810
    },
    {
        alias: 'md-sd',
        mediaQuery: 'screen and (min-width: 701px) and (max-width: 1279px)',
        priority: 880
    },
    {
        alias: 'lt-sd',
        mediaQuery: 'screen and (max-width: 700px)',
        priority: 860
    },
    {
        alias: 'gt-sd',
        mediaQuery: 'screen and (min-width: 701px)',
        priority: -810
    }
];
export const customBreakpointsProvider = {
    provide: BREAKPOINT,
    useValue: ɵ0,
    multi: true
};
export { ɵ0 };
