import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { defer, timer, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Init, ActionTypes, ListRequest, ListLocalSuccess, ListFail } from './references-core.action';
import { ReferencesCoreApi } from './references-core.api';
export class ReferencesCoreEffect {
    constructor(_actions$, _api) {
        this._actions$ = _actions$;
        this._api = _api;
        this._onInit$ = defer(() => timer(0)).pipe(map(() => new Init()));
        this._onInitListRequst$ = this._actions$.pipe(ofType(ActionTypes.Init), map(() => new ListRequest()));
        this._onListRequest$ = this._actions$.pipe(ofType(ActionTypes.ListRequest), switchMap(() => this._api
            .list$()
            .pipe(map((data) => new ListLocalSuccess(data)), catchError(() => of(new ListFail())))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ReferencesCoreEffect.prototype, "_onInit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ReferencesCoreEffect.prototype, "_onInitListRequst$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ReferencesCoreEffect.prototype, "_onListRequest$", void 0);
