import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of, defer, timer } from 'rxjs';
import { ServicesCoreApi } from './services-core.api';
import { ListRequest, ActionTypes, ListLocalSuccess, ListFail } from './services-core.action';
export class ServicesCoreEffect {
    constructor(_actions$, _api) {
        this._actions$ = _actions$;
        this._api = _api;
        this._onInit$ = defer(() => timer(0)).pipe(map(() => new ListRequest()));
        this._onListRequest$ = this._actions$.pipe(ofType(ActionTypes.ListRequest), switchMap(() => this._api
            .list$()
            .pipe(map((data) => new ListLocalSuccess(data)), catchError((err) => {
            console.warn(err);
            return of(new ListFail());
        }))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ServicesCoreEffect.prototype, "_onInit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ServicesCoreEffect.prototype, "_onListRequest$", void 0);
