import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReferencesCoreEnum } from './references-core.constant';
import { ReferencesCoreEffect } from './references-core.effect';
import { reducer } from './references-core.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ReferencesCoreEffect ]),
    StoreModule.forFeature(ReferencesCoreEnum.Id, reducer)
  ]
})
export class ReferencesCoreModule {

}
