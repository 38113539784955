import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WpPost } from '@app/core/wp-core';
import { map } from 'rxjs/operators';
import { LanguageEnumEntity } from './blog-core.model';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class BlogCoreApi {
  constructor(private _http: HttpClient) { }

  list$(options: {
    page?: number;
    tags?: number[];
    language: keyof LanguageEnumEntity;
  } = { page: 1, tags: [], language: environment.blogTranslationCategories.cs }) {
    const fTags = options.tags && options.tags.length
      ? `&tags=${options.tags.join(',')}`
      : '';

    const category = options.language;

    return this._http.get<WpPost[]>(
      `/api/posts?_embed&per_page=10${fTags}&page=${options.page}&categories=${category}`,
      { observe: 'response' }
    );
  }

  tags$() {
    return this._http.get<any[]>(`/api/tags`);
  }

  detail$(slug: string) {
    return this._http
      .get<WpPost[]>(`/api/posts?slug=${slug}&_embed`)
      .pipe(map((data) => data[0]));
  }
}
