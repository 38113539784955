import { Action } from '@app/models';

export enum ActionTypes {
  Init = '[Settings Init]',
  SetLanguage = '[Settings Core] Set Language',
  SetCurrency = '[Settigs Core] Set Currency'
}

export class Init implements Action {
  readonly type = ActionTypes.Init;
}

export class SetLanguage implements Action {
  readonly type = ActionTypes.SetLanguage;

  constructor(public payload: string, public meta = true) { }
}

export class SetCurrency implements Action {
  readonly type = ActionTypes.SetCurrency;

  constructor(public payload: string) { }
}

export type Actions
  = Init
  | SetLanguage
  | SetCurrency;
