/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./tag.component";
var styles_TagComponent = [i0.styles];
var RenderType_TagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagComponent, data: {} });
export { RenderType_TagComponent as RenderType_TagComponent };
function View_TagComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "__tag  __tag--link"]], [[1, "rel", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, [" #", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.link; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.noFollow.indexOf(_co.text) !== (0 - 1)) ? "nofollow" : null); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.text; _ck(_v, 2, 0, currVal_4); }); }
function View_TagComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "__tag"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" #", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_TagComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.link; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-tag", [], null, null, null, View_TagComponent_0, RenderType_TagComponent)), i1.ɵdid(1, 49152, null, 0, i4.TagComponent, [], null, null)], null, null); }
var TagComponentNgFactory = i1.ɵccf("vsh-tag", i4.TagComponent, View_TagComponent_Host_0, { link: "link", text: "text" }, {}, []);
export { TagComponentNgFactory as TagComponentNgFactory };
