<div
  class="__content"
  #contentRef
  [ngClass]="{
    'has-light-bg': background === 'light',
    'has-page-bg': background === 'page',
    'has-grey-bg': background === 'grey',
    'has-alpha-bg': background === 'alpha',
    'has-beta-bg': background === 'beta',
    'has-gamma-bg': background === 'gamma',
    'has-light-color': color === 'light',
    'has-alpha-color': color === 'alpha',
    'has-beta-color': color === 'beta',
    'has-gamma-color': color === 'gamma',
    'has-dark-color': color === 'dark',
    'has-whitespace-none': whitespace === 'none',
    'has-whitespace-small': whitespace === 'small',
    'has-whitespace-medium': whitespace === 'medium',
    'has-whitespace-big': whitespace === 'big'
  }">

  <div class="__header" *ngIf="name || sectionDescRef || sectionHeaderContentRef">

    <vsh-wrap
      class="__header-texts"
      *ngIf="name || sectionDescRef">

      <h2
        *ngIf="name"
        class="__title"
        [class.has-beta-color]="color === 'dark'">
        {{ name }}
      </h2>

      <p class="c-perex  __desc" *ngIf="sectionDescRef">
        <ng-template [ngTemplateOutlet]="sectionDescRef.templateRef"></ng-template>
      </p>

    </vsh-wrap>

    <vsh-wrap *ngIf="sectionHeaderContentRef">
      <div class="__header-content">
        <ng-template [ngTemplateOutlet]="sectionHeaderContentRef.templateRef"></ng-template>
      </div>
    </vsh-wrap>

    <vsh-wrap class="u-text--center" *ngIf="name || sectionDescRef || sectionHeaderContentRef">
      <vsh-tilde class="__tilde" [color]="tildeColor"></vsh-tilde>
    </vsh-wrap>

  </div>

  <ng-content></ng-content>

</div>
