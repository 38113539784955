import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Angulartics2Module } from 'angulartics2';
import { LogoModule } from '@app/common/logo';
import { TildeModule } from '@app/common/tilde';
import { WrapModule } from '@app/common/wrap';
import { AppBarComponent } from './app-bar.component';
import { AppBarTranslationsModule } from './app-bar.translations';

@NgModule({
  declarations: [ AppBarComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatRippleModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    AppBarTranslationsModule,
    Angulartics2Module,
    LogoModule,
    TildeModule,
    WrapModule
  ],
  exports: [ AppBarComponent ]
})
export class AppBarModule { }
