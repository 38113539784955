import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FourOFourPage } from './four-o-four.page';

const routes: Routes = [
  {
    path: '404',
    component: FourOFourPage,
    data: {
      animation: '404',
      topBg: 'beta',
      appBarQuickLinkMode: 'contact',
      footerMode: 'full'
    }
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ]
})
export class FourOFourRoutesModule { }
