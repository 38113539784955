import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectReferencesCoreHomepageList } from '@app/core/references-core';
import { AppState } from '@app/models';
import { CaseStudyEntity } from '@app/core/case-studies-core';

@Component({
  selector: 'vsh-clients-strip',
  templateUrl: './clients-strip.component.html',
  styleUrls: [ './clients-strip.component.scss' ]
})
export class ClientsStripComponent {
  clientsTotal = 2856;
  flipPerson = false;
  clients$ = this._store.pipe(select(selectReferencesCoreHomepageList));

  @Input()
  showCaseStudies = false;

  @Input()
  data: CaseStudyEntity[];

  constructor(private _store: Store<AppState>) { }

  trackBy(index: number) {
    return index;
  }
}
