import { Actions, ActionTypes } from './references-core.action';
import { ReferencesCoreState } from './references-core.model';

export const initialState: ReferencesCoreState = {
  list: []
};

export function reducer(
  state = initialState,
  action: Actions
): ReferencesCoreState {

  switch (action.type) {
    case ActionTypes.ListLocalSuccess:
      return {
        ...state,
        list: action.payload
      };
  }

  return state;
}
