import { Injectable, Inject, ElementRef, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { map, auditTime } from 'rxjs/operators';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollStateService {
  private _scrollPosition = {
    top: 0,
    left: 0
  };
  private _scrolled$ = this._scrollDispatcher.scrolled();
  private _scrollPos$ = this._scrolled$.pipe(map(() => this._calculateScrollPosition()));

  onDone$ = new EventEmitter<boolean>();

  getScrollPosition() {
    return this._scrollPosition;
  }

  getScrolled$(audit?: number) {
    if (audit) {
      return this._scrollPos$.pipe(auditTime(audit));
    }
    return this._scrollPos$;
  }

  constructor(
    private _scrollDispatcher: ScrollDispatcher,
    private _scroll: PageScrollService,
    @Inject(DOCUMENT)
    private _document: any
  ) {
    this._scrollPos$.subscribe((v) => this._scrollPosition = v);
  }

  scrollTo(ref: ElementRef | HTMLElement, offset = 0) {
    this._scroll.scroll({
      document: this._document,
      scrollTarget: (ref as any).nativeElement
        ? (ref as ElementRef).nativeElement
        : ref,
      scrollOffset: offset,
      scrollFinishListener: this.onDone$
    });


  }

  private _calculateScrollPosition() {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const left = window.pageXOffset || document.documentElement.scrollLeft;

    return { top, left };
  }
}
