<div
  class="__wrap"
  @open
  *ngIf="state$ | async">

  <div class="__overlay"></div>

  <div class="__close-button-wrap">
    <button
      type="button"
      tabindex="-1"
      [zpsNgrxDialogClose]="dialogKey"
      class="__close-button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="__progress-wrap" *ngIf="sending">
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>

  <div
    class="__content"
    *ngIf="(meta$ | async) as meta">

    <form
      [formGroup]="form"
      (submit)="onSubmit($event)">

      <div
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="12px grid">

        <div
          class="__layout-item  __layout-item--no-bottom-padding"
          fxFlex="100"
          fxFlex.lt-md="100">
          <mat-form-field
            class="__form-field"
            [floatLabel]="'always'"
            appearance="outline">

            <mat-label>
              <span translate="features.contactPop.mailLabel"></span>
            </mat-label>

            <input
              matInput
              type="text"
              class="__mail-input"
              [placeholder]="'features.contactPop.mailPlaceholder' | translate"
              [formControl]="form.get('mail')"
            />

          </mat-form-field>
        </div>

        <div
          class="__layout-item"
          fxFlex="100">

          <ng-container *ngIf="meta.type === 'contact' || !defaultHiddenText">
            <mat-form-field
              class="__form-field"
              [floatLabel]="'always'"
              appearance="outline">

              <mat-label>
                <span [translate]="meta.type === 'contact'
                  ? 'features.contactPop.messageLabel'
                  : 'features.contactPop.inquiryMessageLabel'"></span>
              </mat-label>

              <textarea
                matInput
                rows="5"
                [placeholder]="meta.type === 'contact'
                  ? ('features.contactPop.messageContactPlaceholder' | translate)
                  : ''"
                [formControl]="form.get('text')"></textarea>

            </mat-form-field>
          </ng-container>

          <button
            class="__add-note"
            (click)="defaultHiddenText = false"
            *ngIf="meta.type !== 'contact' && defaultHiddenText">
            <mat-icon class="__add-note_icon">add_circle_outline</mat-icon>
            <span class="__add-note_text" translate="features.contactPop.messageInquiryPlaceholder"></span>
          </button>

        </div>

      </div>

      <div>
        <vsh-break class="__break"></vsh-break>
      </div>

<!--
      <div class="u-text--center">
        <vsh-tilde [color]="'gamma'" class="__top-tilde"></vsh-tilde>
      </div> -->

      <div
        *ngIf="meta?.meta?.service"
        class="__inquiry-info">

        <h2
          *ngIf="meta?.meta?.service"
          class="__inquiry-info_variant-main">
          {{ meta.meta.service }}
        </h2>

        <!-- basket -->
        <ng-container *ngIf="meta.type === 'basket'">
          <div class="__basket-item" *ngFor="let basketItem of meta.meta.basket">

            <h4 class="__basket-item_name">{{ basketItem.name }}</h4>

            <p class="__basket-item_desc">{{ basketItem.desc }}</p>

            <vsh-price
              class="__basket-item_price"
              *ngIf="meta.meta.basket?.length > 1"
              [price]="basketItem.price"
              [currency]="meta.meta.currency">
            </vsh-price>

          </div>

          <div class="__basket-total">
            <vsh-price
              class="__basket-total_price"
              [price]="meta.meta.total"
              [currency]="meta.meta.currency">
            </vsh-price>
          </div>

        </ng-container>

        <!-- other -->
        <ng-container *ngIf="meta.type !== 'basket'">

          <span
            *ngIf="meta?.meta?.variant && meta.meta.variant.length"
            class="__inquiry-info_variant-secondary">
            <strong>{{ meta.meta.variant[0] }}</strong>
            <ng-container *ngIf="meta.meta.variant[1]"> - {{ meta.meta.variant[1] }}</ng-container>
            <ng-container *ngIf="meta.meta.variant[2]"> - {{ meta.meta.variant[2] }}</ng-container>
          </span>

          <!-- <div *ngIf="meta?.meta?.price">
            <vsh-tilde [color]="'beta'" class="__tilde"></vsh-tilde>
          </div> -->

          <vsh-price
            *ngIf="meta?.meta?.price"
            [price]="meta.meta.price"
            [currency]="meta.meta.currency"
            class="__inquiry-info_price">
          </vsh-price>
<!--
          <div class="u-text--center">
            <vsh-tilde [color]="'gamma'" class="__top-tilde"></vsh-tilde>
          </div> -->

        </ng-container>

      </div>

      <div
        class="__additionals"
        *ngIf="meta?.meta?.additionalItems">

        <h4 class="__additionals_title" translate="features.contactPop.interestAdditional"></h4>

        <div
          class="__additional-item" *ngFor="let item of meta?.meta?.additionalItems; let last = last;"
          [class.is-last]="last">
          <mat-checkbox
            [checked]="false"
            (change)="toggleAdditionalItem($event, item)">
            <div fxLayout="row wrap">

              <div fxFlex="60">
                <div class="__additional-item_text">
                  <div>{{ item.name }} <span class="__additional-badge" *ngIf="item.badge">{{ item.badge }}</span></div>
                  <span class="__additional-desc">{{ item.desc }}</span>
                </div>
              </div>

              <div fxFlex="40">
                <div class="u-text--right">
                  <span translate="app.from"></span>&nbsp;
                  <vsh-price
                    [price]="item.price"
                    [currency]="currency$ | async">
                  </vsh-price>
                </div>
              </div>

            </div>

          </mat-checkbox>
        </div>
      </div>

      <div *ngIf="meta?.meta?.service">
        <vsh-break class="__break  __break--bottom"></vsh-break>
      </div>

      <div class="__buttons">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="center center"
          fxLayoutGap="0px grid">

          <div
            fxFlex="100"
            fxFlex.lt-md="100">
            <div>
              <button
                type="submit"
                class="[ c-button  c-button--dense ]  [ __button ]  [ __main-button ]"
                color="primary"
                [disabled]="sending"
                mat-raised-button>
                <span
                  *ngIf="meta.type === 'contact'"
                  translate="features.contactPop.sendContactButton">
                </span>
                <span
                  *ngIf="meta.type === 'inquiry' || meta.type === 'basket'"
                  [angulartics2On]="meta.mainEventProperties ? 'click' : undefined"
                  angularticsAction="Contact_Form_Sent"
                  [angularticsProperties]="meta.mainEventProperties"
                  translate="features.contactPop.sendInquiryButton">
                </span>
                &nbsp;<mat-icon>send</mat-icon>
              </button>
            </div>
          </div>

          <div
            fxFlex="100"
            fxFlex.lt-md="100">

            <div class="u-text--center">
              <vsh-tilde class="__chat-tilde" [color]="'beta'"></vsh-tilde>
            </div>

            <div>
              <div class="u-text--center">

                <button
                  type="button"
                  (click)="onChat$.next()"
                  [angulartics2On]="meta.secondaryEventProperties ? 'click' : undefined"
                  angularticsAction="Intercom_Chat_Initiated"
                  [angularticsProperties]="meta.secondaryEventProperties"
                  class="[ c-button  c-button--dense ]  [ __button ]  [ __secondary-button ]"
                  mat-stroked-button>
                  <mat-icon class="__secondary-button_icon" svgIcon="intercom-chat"></mat-icon>&nbsp;
                  <span translate="features.contactPop.chatButton"></span>
                </button>

                <span class="__chat-desc">
                  <span
                    *ngIf="meta.type === 'contact'"
                    translate="features.contactPop.chatButtonDescContact">
                  </span>
                  <span
                    *ngIf="meta.type === 'inquiry' || meta.type === 'basket'"
                    translate="features.contactPop.chatButtonDescInquiry">
                  </span>
                </span>
              </div>

            </div>
          </div>

        </div>
      </div>

    </form>

  </div>

</div>

<ng-template #chatSnackTemplateRef>
  <div [innerHTML]="'features.contactPop.chatSnack' | translate"></div>
</ng-template>

<ng-template #successSnackTemplateRef>

  <div
    *ngIf="(meta$ | async)?.type === 'contact'"
    [innerHTML]="'features.contactPop.successContactSnack' | translate">
  </div>

  <div
    *ngIf="(meta$ | async)?.type === 'inquiry'"
    [innerHTML]="'features.contactPop.successInquirySnack' | translate">
  </div>

</ng-template>


<ng-template #failSnackTemplateRef>
  <div [innerHTML]="'features.contactPop.failSnack' | translate"></div>
</ng-template>
