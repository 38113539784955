import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  Directive,
  ContentChild,
  ElementRef
} from '@angular/core';

@Directive({
  selector: '[vshSectionDesc]'
})
export class SectionDescDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[vshSectionHeaderContent]'
})
export class SectionHeaderContentDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'vsh-section',
  templateUrl: './section.component.html',
  styleUrls: [ './section.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionComponent {
  @Input()
  background: 'page' | 'light' | 'grey' | 'alpha' | 'beta' | 'gamma';

  @Input()
  color: 'light' | 'alpha' | 'beta' | 'gamma' | 'dark';

  @Input()
  tildeColor: 'light' | 'alpha' | 'beta' | 'gamma' = 'alpha';

  @Input()
  whitespace: 'none' | 'small' | 'medium' | 'big' = 'medium';

  @Input()
  name: string;

  @ContentChild(SectionDescDirective, { static: false })
  sectionDescRef: SectionDescDirective;

  @ContentChild(SectionHeaderContentDirective, { static: false })
  sectionHeaderContentRef: SectionHeaderContentDirective;

  constructor(public elementRef: ElementRef) { }

}
