/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../wrap/wrap.component.ngfactory";
import * as i4 from "../wrap/wrap.component";
import * as i5 from "../tilde/tilde.component.ngfactory";
import * as i6 from "../tilde/tilde.component";
import * as i7 from "./section.component";
var styles_SectionComponent = [i0.styles];
var RenderType_SectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SectionComponent, data: {} });
export { RenderType_SectionComponent as RenderType_SectionComponent };
function View_SectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "__title"]], [[2, "has-beta-color", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.color === "dark"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.name; _ck(_v, 1, 0, currVal_1); }); }
function View_SectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_SectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "c-perex  __desc"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionComponent_5)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sectionDescRef.templateRef; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "vsh-wrap", [["class", "__header-texts"]], null, null, null, i3.View_WrapComponent_0, i3.RenderType_WrapComponent)), i1.ɵdid(1, 49152, null, 0, i4.WrapComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SectionComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SectionComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.sectionDescRef; _ck(_v, 5, 0, currVal_1); }, null); }
function View_SectionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_SectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "vsh-wrap", [], null, null, null, i3.View_WrapComponent_0, i3.RenderType_WrapComponent)), i1.ɵdid(1, 49152, null, 0, i4.WrapComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "__header-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionComponent_7)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sectionHeaderContentRef.templateRef; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SectionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "vsh-wrap", [["class", "u-text--center"]], null, null, null, i3.View_WrapComponent_0, i3.RenderType_WrapComponent)), i1.ɵdid(1, 49152, null, 0, i4.WrapComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "vsh-tilde", [["class", "__tilde"]], null, null, null, i5.View_TildeComponent_0, i5.RenderType_TildeComponent)), i1.ɵdid(3, 49152, null, 0, i6.TildeComponent, [], { color: [0, "color"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tildeColor; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "__header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.name || _co.sectionDescRef); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.sectionHeaderContentRef; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.name || _co.sectionDescRef) || _co.sectionHeaderContentRef); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SectionComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, [["contentRef", 1]], null, 6, "div", [["class", "__content"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "has-light-bg": 0, "has-page-bg": 1, "has-grey-bg": 2, "has-alpha-bg": 3, "has-beta-bg": 4, "has-gamma-bg": 5, "has-light-color": 6, "has-alpha-color": 7, "has-beta-color": 8, "has-gamma-color": 9, "has-dark-color": 10, "has-whitespace-none": 11, "has-whitespace-small": 12, "has-whitespace-medium": 13, "has-whitespace-big": 14 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "__content"; var currVal_1 = _ck(_v, 3, 1, [(_co.background === "light"), (_co.background === "page"), (_co.background === "grey"), (_co.background === "alpha"), (_co.background === "beta"), (_co.background === "gamma"), (_co.color === "light"), (_co.color === "alpha"), (_co.color === "beta"), (_co.color === "gamma"), (_co.color === "dark"), (_co.whitespace === "none"), (_co.whitespace === "small"), (_co.whitespace === "medium"), (_co.whitespace === "big")]); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ((_co.name || _co.sectionDescRef) || _co.sectionHeaderContentRef); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "vsh-section", [], null, null, null, View_SectionComponent_0, RenderType_SectionComponent)), i1.ɵdid(1, 49152, null, 2, i7.SectionComponent, [i1.ElementRef], null, null), i1.ɵqud(603979776, 1, { sectionDescRef: 0 }), i1.ɵqud(603979776, 2, { sectionHeaderContentRef: 0 })], null, null); }
var SectionComponentNgFactory = i1.ɵccf("vsh-section", i7.SectionComponent, View_SectionComponent_Host_0, { background: "background", color: "color", tildeColor: "tildeColor", whitespace: "whitespace", name: "name" }, {}, ["*"]);
export { SectionComponentNgFactory as SectionComponentNgFactory };
