import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FlexModule } from '@app/core/flex';
import { HeroModule } from '@app/common/hero';
import { TildeModule } from '@app/common/tilde';
import { AboutStripComponent } from './about-strip.component';
import { AboutStripTranslationsModule } from './about-strip.translations';

@NgModule({
  declarations: [ AboutStripComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    NgxPageScrollModule,
    FlexModule,
    AboutStripTranslationsModule,
    TildeModule,
    HeroModule
  ],
  exports: [ AboutStripComponent ]
})
export class AboutStripModule { }
