import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {
  query,
  trigger,
  style,
  transition,
  animate,
  stagger,
  group
} from '@angular/animations';

@Component({
  selector: 'vsh-claim-items',
  templateUrl: './claim-items.component.html',
  styleUrls: [ './claim-items.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('anim', [
      transition(':increment, :decrement', [

        group([

          query('.__comma', [
            style({ opacity: 0 })
          ]),

          query('.__letter', [
            style({ opacity: 0 })
          ])

        ]),

        query('.__letter', [
          style({
            opacity: 0,
            transform: 'translate3d(0, -3px, 0)'
          }),
          stagger(50, [
            animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
              opacity: 1,
              transform: 'translate3d(0, 0, 0)'
            }))
          ])
        ], { optional: true }),

        query('.__comma', [
          style({ opacity: 0 }),
          animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 1
          }))
        ])

      ])
    ])
  ]
})
export class ClaimItemsComponent {

  selectedIndex = 0;

  get items() {
    return [ this._items[this.selectedIndex] ];
  }

  // tslint:disable:max-line-length
  private _items = [
    '<span class="__letter">h</span><span class="__letter">o</span><span class="__letter">s</span><span class="__letter">t</span><span class="__letter">i</span><span class="__letter">n</span><span class="__letter">g</span>',
    '<span class="__letter">c</span><span class="__letter">l</span><span class="__letter">o</span><span class="__letter">u</span><span class="__letter">d</span>',
    '<span class="__letter">s</span><span class="__letter">e</span><span class="__letter">r</span><span class="__letter">v</span><span class="__letter">e</span><span class="__letter">r</span>',
    // '<span class="__letter">k</span><span class="__letter">u</span><span class="__letter">b</span><span class="__letter">e</span><span class="__letter">r</span><span class="__letter">n</span><span class="__letter">e</span><span class="__letter">t</span><span class="__letter">e</span><span class="__letter">s</span>'
  ];

  constructor(private _cdRef: ChangeDetectorRef) {
    setInterval(() => {
      if ((this.selectedIndex + 1) !== this._items.length) {
        this.selectedIndex = this.selectedIndex + 1;
      } else {
        this.selectedIndex = 0;
      }

      this._cdRef.markForCheck();
    }, 5000);
  }

  trackBy(index: number) {
    return index;
  }
}
