export const environment = {
  production: true,
  redirectLanguage: true,
  apiEndpoint: '/wp/wp-json/wp/v2',
  mailedEndpoint: '/api/contact',
  newsletterEndpoint: '/api/subscribe',
  blogTranslationCategories: {
    cs: 1,
    en: 13,
    sk: 1, // 226 sk uses czech translations
    pl: 229
  }
};
