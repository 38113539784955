<h2 class="__title" translate="common.addressBox.placeTitle"></h2>

<address>
  <p class="__text">
    VSHosting s.r.o.<br/>
    Sodomkova 1579/5<br/>
    102 00 Praha 15
  </p>
</address>

<a
  href="https://goo.gl/maps/JPUNaoLSjpC2"
  target="__blank"
  class="__map-button">
  <span translate="common.addressBox.showOnMapButton"></span>
</a>
