<vsh-section class="__section">

  <h1 class="__title">404</h1>

  <h2 class="__heading" translate="routed.fourOFour.heading"></h2>

  <p class="__looking-for">
    {{ 'routed.fourOFour.lookingFor' | translate }} <a [routerLink]="[ '/services' ]" translate="routed.fourOFour.ourServices"></a>, <a [routerLink]="[ '/references' ]" translate="routed.fourOFour.references"></a> {{ 'routed.fourOFour.or' | translate }} <a [routerLink]="[ '/blog' ]" translate="routed.fourOFour.blog"></a>?
  </p>

  <vsh-tilde class="__tilde"></vsh-tilde>

  <div>
    <a
      mat-stroked-button
      class="c-button"
      [routerLink]="['/']">
      <span translate="routed.fourOFour.toHp"></span>
    </a>
  </div>

</vsh-section>
