// tslint:disable
import { Type } from '@angular/core';
import { FlexDirective, ClassDirective, LayoutGapDirective } from '@angular/flex-layout';
export function _inheritCtorParametersMetadata(target, base) {
    target['ctorParameters'] = () => {
        const baseParameters = base['ctorParameters'];
        return (typeof baseParameters === 'function' ? baseParameters() : baseParameters) || [];
    };
}
const selectorFlex = `[fxFlex.sd], [fxFlex.gt-sd], [fxFlex.lt-sd], [fxFlex.md-sd]`;
const inputsFlex = ['fxFlex.sd', 'fxFlex.lt-sd', 'fxFlex.md-sd'];
export class CustomFlexDirective extends FlexDirective {
    constructor() {
        super(...arguments);
        this.inputs = inputsFlex;
    }
}
_inheritCtorParametersMetadata(CustomFlexDirective, FlexDirective);
const selectorClass = `[ngClass.sd], [ngClass.gt-sd], [ngClass.lt-sd], [ngClass.md-sd]`;
const inputsClass = ['ngClass.sd', 'ngClass.gt-sd', 'ngClass.lt-sd', 'ngClass.md-sd'];
export class CustomFlexClassDirective extends ClassDirective {
    constructor() {
        super(...arguments);
        this.inputs = inputsClass;
    }
}
_inheritCtorParametersMetadata(CustomFlexClassDirective, ClassDirective);
const selectorGap = `[fxLayoutGap.lt-sd]`;
const inputsGap = ['fxLayoutGap.lt-sd'];
export class CustomFlexGapDirective extends LayoutGapDirective {
    constructor() {
        super(...arguments);
        this.inputs = inputsGap;
    }
}
_inheritCtorParametersMetadata(CustomFlexGapDirective, LayoutGapDirective);
