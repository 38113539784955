import {
  Component,
  ViewChild,
  AfterContentInit,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { SatPopoverAnchoringService } from '@app/common/popover';
import { AboutPopComponent } from '@app/common/about-pop';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vsh-about-pop-wrap',
  templateUrl: './about-pop-wrap.component.html',
  styleUrls: [ './about-pop-wrap.component.scss' ],
  providers: [ SatPopoverAnchoringService ]
})
export class AboutPopWrapComponent implements AfterContentInit, OnDestroy {
  @ViewChild(AboutPopComponent, { static: true })
  aboutPopRef: AboutPopComponent;

  private _els: HTMLCollection;
  private _translationChangeSubscription: Subscription;

  onEnter = (el: Element, attr: string) => {
    return () => {
      this.aboutPopRef.open(attr as any, new ElementRef(el));
    };
  }

  onLeave = () => {
    this.aboutPopRef.close();
  }

  constructor(
    private _elementRef: ElementRef,
    private _translate: TranslateService
  ) { }

  ngAfterContentInit() {
    setTimeout(() => {
      this.addListeners();
    });

    this._translationChangeSubscription = this._translate.onLangChange.subscribe(() => {
      this.removeListeners();
      setTimeout(() => {
        this.addListeners();
      });
    });
  }

  ngOnDestroy() {
    this._els = undefined;
    this.removeListeners();
    if (this._translationChangeSubscription) {
      this._translationChangeSubscription.unsubscribe();
    }
  }

  addListeners() {
    this._els = this._elementRef.nativeElement.querySelectorAll('[data-about-pop]');

    if (this._els) {
      for (let i = 0; i < this._els.length; i++) {
        const el = this._els[i];
        const attr = el.getAttribute('data-about-pop');

        el.addEventListener('mouseenter', this.onEnter(el, attr));
        el.addEventListener('mouseleave', this.onLeave);
      }
    }
  }

  removeListeners() {
    if (this._els) {
      for (let i = 0; i < this._els.length; i++) {
        const el = this._els[i];
        const attr = el.getAttribute('data-about-pop');

        this._els[i].removeEventListener('mouseenter', this.onEnter(el, attr));
        this._els[i].removeEventListener('mouseleave', this.onLeave);
      }
    }
  }


}
