/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./price.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./price.component";
var styles_PriceComponent = [i0.styles];
var RenderType_PriceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PriceComponent, data: {} });
export { RenderType_PriceComponent as RenderType_PriceComponent };
function View_PriceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], null, null); }
export function View_PriceComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "c-price__num"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriceComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "__currency  c-price__currency"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.forceSign && (_co.price > 0)); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.price, _co.rounding)); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.currency; _ck(_v, 7, 0, currVal_2); }); }
export function View_PriceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-price", [], null, null, null, View_PriceComponent_0, RenderType_PriceComponent)), i1.ɵdid(1, 49152, null, 0, i3.PriceComponent, [], null, null)], null, null); }
var PriceComponentNgFactory = i1.ɵccf("vsh-price", i3.PriceComponent, View_PriceComponent_Host_0, { price: "price", currency: "currency", rounding: "rounding", forceSign: "forceSign" }, {}, []);
export { PriceComponentNgFactory as PriceComponentNgFactory };
