import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  invoicesTitle: 'Fakturační údaje'
};

const EN = {
  invoicesTitle: 'Invoice details'
};

const SK = {
  invoicesTitle: 'Fakturačné údaje'
};

const PL = {
  invoicesTitle: 'Informacje rozliczeniowe'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class InvoiceBoxTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        invoiceBox: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        invoiceBox: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        invoiceBox: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        invoiceBox: PL
      }
    }, true);

  }
}
