import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  title: 'Naši klienti',
  caseStudiesTitle: 'Případové studie a naši klienti',
  allReferences: 'Všechny reference a případové studie',
  otherClients: 'dalších<br/>klientů'
};

const EN = {
  title: 'Our clients',
  caseStudiesTitle: 'Case studies and our clients',
  allReferences: 'All references and case studies',
  otherClients: 'other<br/>clients'
};

const SK = {
  title: 'Naši klienti',
  caseStudiesTitle: 'Prípadové štúdie a naši klienti',
  allReferences: 'Všetky referencie a prípadové štúdie',
  otherClients: 'ďalších<br/>klientov'
};

const PL = {
  title: 'Nasi klienci',
  caseStudiesTitle: 'Studia przypadków i nasi klienci',
  allReferences: 'Wszystkie referencje i studia przypadków',
  otherClients: 'inni<br/>klienci'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class ClientsStripTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        clientsStrip: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        clientsStrip: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        clientsStrip: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        clientsStrip: PL
      }
    }, true);

  }
}
