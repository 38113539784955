/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/common";
import * as i9 from "./gallery.component";
var styles_GalleryComponent = [i0.styles];
var RenderType_GalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryComponent, data: { "animation": [{ type: 7, name: "slider", definitions: [{ type: 1, expr: ":increment", animation: { type: 3, steps: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "500ms" }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "500ms" }], options: { optional: true } }], options: null }, options: null }, { type: 1, expr: ":decrement", animation: { type: 3, steps: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "500ms" }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "500ms" }], options: { optional: true } }], options: null }, options: null }], options: {} }] } });
export { RenderType_GalleryComponent as RenderType_GalleryComponent };
function View_GalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "__item"]], [[2, "has-background-contain", null], [4, "backgroundImage", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.backgroundMode === "contain"); var currVal_1 = (("url(" + _v.context.$implicit.url) + ")"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GalleryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "__control  __control--next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedIndex((_co.selectedIndex + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "__control_button  [ __button  __button--next ]"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_right"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_GalleryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "__control  __control--prev"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedIndex((_co.selectedIndex - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "__control_button  [ __button  __button--prev ]"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_left"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_GalleryComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "__items"]], [[24, "@slider", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_1)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "__spanner"]], [[4, "paddingBottom", "%"]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; var currVal_2 = _co.trackBy; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (_co.selectedIndex !== (_co.dataLength - 1)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.selectedIndex !== 0); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedIndex; _ck(_v, 0, 0, currVal_0); var currVal_5 = (_co.fittedRatio ? (100 / _co.fittedRatio) : (100 / _co.ratio)); _ck(_v, 7, 0, currVal_5); }); }
export function View_GalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-gallery", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GalleryComponent_0, RenderType_GalleryComponent)), i1.ɵdid(1, 4243456, null, 0, i9.GalleryComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var GalleryComponentNgFactory = i1.ɵccf("vsh-gallery", i9.GalleryComponent, View_GalleryComponent_Host_0, { ratio: "ratio", fitToParent: "fitToParent", backgroundMode: "backgroundMode", data: "data" }, {}, []);
export { GalleryComponentNgFactory as GalleryComponentNgFactory };
