import { Injectable, QueryList } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { ScrollStateService } from '@app/services';
import { ScrollElementDirective } from './scroll-scene.component';

interface SceneOptions {
  offset: number;
  duration: number;
  items: ScrollElementDirective[] | QueryList<ScrollElementDirective>;
}

type Items = ScrollElementDirective[] | QueryList<ScrollElementDirective>;

export interface Scene {
  offset: number;
  duration: number;
  offset$: BehaviorSubject<number>;
  duration$: BehaviorSubject<number>;
  items: Items;
  id: string;
  progress$: Observable<any>;
  subscription: Subscription;
}

@Injectable({ providedIn: 'root' })
export class ScrollSceneService {
  private _scenes: Scene[] = [];

  constructor(
    private _scrollState: ScrollStateService
  ) {
  }

  addScene({ offset, duration, items }: SceneOptions) {
    const offset$ = new BehaviorSubject<number>(offset);
    const duration$ = new BehaviorSubject<number>(duration);

    const newScene: Scene = {
      offset,
      duration,
      offset$,
      duration$,
      items,
      id: `scene_${this._scenes.length + 1}`,
      progress$: this._createProgress$(duration$, offset$),
      subscription: undefined
    };

    // newScene.subscription = newScene.progress$.subscribe((p) => {
    //   newScene.items.forEach((ins) => {
    //     ins.seek(p);
    //   });
    // });

    this._scenes.push(newScene);

    return newScene;
  }

  updateItems(items: Items, scene: Scene) {
    scene.items = items;
  }

  updateDuration(duration: number, scene: Scene) {
    scene.duration = duration;
    scene.duration$.next(duration);
  }

  updateOffset(offset: number, scene: Scene) {
    scene.offset = offset;
    scene.offset$.next(offset);
  }

  destroy() {
    this._scenes.forEach((s) => {
      if (s.subscription) {
        s.subscription.unsubscribe();
      }
    });

    this._scenes = [];
  }

  removeScene(scene: Scene) {
    if (scene && scene.subscription) {
      scene.subscription.unsubscribe();
    }

    this._scenes = this._scenes.filter((s) => scene && (s.id !== scene.id));
  }

  get scrollPosition() {
    return this._scrollState.getScrollPosition();
  }

  private _createProgress$(
    duration$: BehaviorSubject<number>,
    offset$: BehaviorSubject<number>
  ) {
    return this._scrollState.getScrolled$().pipe(
      withLatestFrom(duration$, offset$),
      map(([ _, duration, offset ]) => {
        const scrollTop = this._scrollState.getScrollPosition().top;
        const finalDuration = duration + offset;

        let progress = 0;

        if ((scrollTop >= offset)
          && (scrollTop <= finalDuration)) {
          progress = (scrollTop - offset) / (finalDuration - offset);
          return progress;
        }

        if ((scrollTop > finalDuration) && progress !== 1) {
          return 1;
        }

        if ((scrollTop <= 0) && progress !== 0) {
          return 0;
        }

        return 0;
      }),
      distinctUntilChanged()
    );
  }

}
