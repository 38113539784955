import { Action } from '@app/models';
import { ReferenceEntity } from './references-core.model';

export enum ActionTypes {
  Init = '[References Core] Init',

  ListRequest = '[References Core] List Request',
  ListFail = '[References Core] List Fail',
  ListLocalSuccess = '[References Core] List Local Success'
}

export class Init implements Action {
  readonly type = ActionTypes.Init;
}

export class ListRequest implements Action {
  readonly type = ActionTypes.ListRequest;
}

export class ListFail implements Action {
  readonly type = ActionTypes.ListFail;
}

export class ListLocalSuccess implements Action {
  readonly type = ActionTypes.ListLocalSuccess;

  constructor(public payload: ReferenceEntity[]) { }
}

export type Actions
  = Init
  | ListRequest
  | ListFail
  | ListLocalSuccess;
