import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  solutionsAndServices: 'Služby a řešení',
  referencesAndCaseStudies: 'Reference a případové studie',
  showVariants: 'Zobrazit varianty',
  showConfigurations: 'Zobrazit konfigurace',
  showCalculation: 'Zobrazit kalkulaci',
  sendInquiry: 'Poptat službu',
  inquiryBasic: 'Poptat',
  variants: 'Varianty',
  calculation: 'Kalkulace',
  configurations: 'Konfigurace',
  from: 'od',
  noTaxPrices: 'Všechny uvedené ceny jsou bez DPH',
  month: 'měsíc',
  price: 'Cena',
  unlimited: 'neomezeně',
  contract: {
    '6m': '6 měsíční smlouva',
    '12m': '12 měsíční smlouva',
    'without': 'bez smlouvy'
  },
  olderArticles: 'Zobrazit starší články',
  individualPricing: 'Individuální ceník',
  traditionalPublicCloud: 'Tradiční public cloud'
};

const EN = {
  solutionsAndServices: 'Services & Solutions',
  referencesAndCaseStudies: 'References and case studies',
  showVariants: 'Show options',
  showConfigurations: 'Show configurations',
  showCalculation: 'Show calculation',
  sendInquiry: 'Inquire',
  inquiryBasic: 'Inquiry',
  variants: 'Options',
  calculation: 'Calculation',
  configurations: 'Configurations',
  from: 'from',
  noTaxPrices: 'All prices are without VAT',
  month: 'month',
  price: 'Price',
  unlimited: 'unlimited',
  contract: {
    '6m': '6 months contract',
    '12m': '12 months contract',
    'without': 'without contract'
  },
  olderArticles: 'Show older blog posts',
  individualPricing: 'Individual pricing',
  traditionalPublicCloud: 'Traditional public cloud'
};

const SK = {
  solutionsAndServices: 'Služby a riešenia',
  referencesAndCaseStudies: 'Referencie a prípadové štúdie',
  showVariants: 'Zobraziť možnosti',
  showConfigurations: 'Zobraziť konfigurácie',
  showCalculation: 'Zobraziť kalkulácie',
  sendInquiry: 'Poslať dotaz',
  inquiryBasic: 'Dotaz',
  variants: 'Možnosti',
  calculation: 'kalkulácia',
  configurations: 'Konfigurácia',
  from: 'od',
  noTaxPrices: 'Všetky uvedené ceny sú bez DPH',
  month: 'mesiac',
  price: 'cena',
  unlimited: 'neobmedzený',
  contract: {
    '6m': '6 mesačná zmluva',
    '12m': '12 mesačná zmluva',
    'without': 'bez zmluvy'
  },
  olderArticles: 'Zobraziť staršie príspevky blogu',
  individualPricing: 'Individuálne ceny',
  traditionalPublicCloud: 'Tradičný public cloud'
};

const PL = {
  solutionsAndServices: 'Usługi i rozwiązania',
  referencesAndCaseStudies: 'Referencje i studium przypadku',
  showVariants: 'Pokaż opcje',
  showConfigurations: 'Pokaż konfiguracje',
  showCalculation: 'Pokaż kalkulację',
  sendInquiry: 'Wyślij zapytanie',
  inquiryBasic: 'Zapytanie',
  variants: 'Opcje',
  calculation: 'Obliczenie',
  configurations: 'Konfiguracja',
  from: 'od',
  noTaxPrices: 'Wszystkie ceny bez podatku VAT',
  month: 'miesiąc',
  price: 'cena',
  unlimited: 'nieograniczony',
  contract: {
    '6m': 'Umowa na 6 miesięcy',
    '12m': 'Umowa na 12 miesięcy',
    'without': 'bez umowy'
  },
  olderArticles: 'Pokaż starsze wpisy na blogu',
  individualPricing: 'Indywidualna wycena',
  traditionalPublicCloud: 'Tradycyjny public cloud'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class AppTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      app: CS
    }, true);

    this._translate.setTranslation('en', {
      app: EN
    }, true);

    this._translate.setTranslation('sk', {
      app: SK
    }, true);

    this._translate.setTranslation('pl', {
      app: PL
    }, true);

  }
}
