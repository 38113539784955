<div
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  fxLayoutGap="48px grid"
  fxLayoutGap.lt-md="0px grid">
  <div
    class="__blog"
    fxFlex="33.3334"
    fxFlex.md="50"
    fxFlex.lt-md="100"
    [class.__blog--lt-md]="media.isActive('lt-md')"
    [class.is-last]="last"
    *ngFor="let item of blogs.slice(0, 3); let index = index; let last = last; trackBy: trackBy">
    <a
      class="__blog_link"
      [routerLink]="[ '/blog', item.slug ]">
      <vsh-hero
        [minHeight]="'200px'"
        [contentMaxWidth]="'90%'"
        [contentOffsetY]="'-50px'"
        [contentAlign]="'center'"
        [animationOffset]="'-50vh'"
        [contentPadding]="'24px'"
        [contentBackground]="'light'"
        disableImageAnimation
        disableFeatureAnimation
        [image]="item?._embedded['wp:featuredmedia']
          && item?._embedded['wp:featuredmedia'][0]
          && item?._embedded['wp:featuredmedia'][0].media_details
            ? item?._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
            : undefined"
        [mode]="'vertical'">

        <h2
          class="__blog_title"
          [innerHTML]="item.title.rendered">
        </h2>

        <div
          class="__blog_excerpt"
          [innerHTML]="item.excerpt.rendered">
        </div>

        <div class="__blog_tags">
          <vsh-tag
            *ngFor="let tag of item._tags"
            [text]="tag.name"
            [link]="[ '/', 'blog', 'list', { tags: tag.slug } ]">
          </vsh-tag>
        </div>

        <div class="__blog_author-date" *ngIf="item._embedded">

          <vsh-blog-info
            [personImage]="item._embedded?.author[0].avatar_urls[96]"
            [name]="item._embedded?.author[0].name"
            [size]="20"
            [date]="item.date_gmt">
          </vsh-blog-info>

        </div>

      </vsh-hero>
    </a>
  </div>
</div>

<div class="u-text--center">
  <a
    class="[ c-button  c-button--dense  c-button--gamma ]  __archive-button"
    color="secondary"
    mat-button
    [routerLink]="[ '/blog', 'list' ]">
    <mat-icon>archive</mat-icon>&nbsp;<span translate="app.olderArticles"></span>
  </a>
</div>
