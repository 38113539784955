import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  query,
  trigger,
  style,
  transition,
  animate,
  group
} from '@angular/animations';
import { GalleryItem } from './gallery.model';

@Component({
  selector: 'vsh-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: [ './gallery.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slider', [
      transition(':increment', group([
        query(':enter', [
          style({
            opacity: 0
          }),
          animate('500ms', style({
            opacity: 1
          }))
        ], { optional: true }),
        query(':leave', [
          animate('500ms', style({
            opacity: 0
          }))
        ], { optional: true })
      ])),
      transition(':decrement', group([
        query(':enter', [
          style({
            opacity: 0
          }),
          animate('500ms', style({
            opacity: 1
          }))
        ], { optional: true }),
        query(':leave', [
          animate('500ms', style({
            opacity: 0
          }))
        ], { optional: true })
      ]))
    ])
  ],
})
export class GalleryComponent implements AfterViewInit {
  selectedIndex = 0;

  @Input()
  ratio: number;

  @Input()
  fitToParent: HTMLElement;

  @Input()
  backgroundMode: 'cover' | 'contain' = 'cover';

  @Input()
  set data(v: GalleryItem[]) {
    this._data = v;
  }

  get data() {
    return [ this._data[this.selectedIndex] ];
  }

  get dataLength() {
    return this._data.length;
  }

  fittedRatio: number;

  private _data: GalleryItem[];

  constructor(private _cdRef: ChangeDetectorRef) { }

  @HostListener('window:resize')
  onWindowResize() {
    if (this.fitToParent) {
      this._fitToParent();
    }
  }

  trackBy(_: number, item: GalleryItem) {
    return item.id;
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  ngAfterViewInit() {
    if (this.fitToParent) {
      this._fitToParent();

      setTimeout(() => {
        this._fitToParent();
      }, 50);
    }
  }

  private _fitToParent() {
    this.fittedRatio = this.fitToParent.clientWidth / this.fitToParent.clientHeight;
    this._cdRef.markForCheck();
    this._cdRef.detectChanges();
  }
}
