import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  meta: {
    title: '404',
    ogTitle: '',
    description: '',
    image: '/assets/meta/404.png'
  },
  toHp: 'Přejít na homepage',
  heading: 'Vámi hledanou stránku nelze zobrazit',
  lookingFor: 'Možná hledáte',
  ourServices: 'naše služby',
  references: 'reference a případové studie',
  blog: 'náš blog',
  or: 'nebo'
};

const EN = {
  meta: {
    title: '404',
    ogTitle: '',
    description: '',
    image: '/assets/meta/404.png'
  },
  toHp: 'Go to homepage',
  heading: 'The page you are looking for cannot be displayed',
  lookingFor: `Perhaps you're looking`,
  ourServices: 'our services',
  references: 'references and case studies',
  blog: 'our blog',
  or: 'or'
};

const SK = {
  meta: {
    title: '404',
    ogTitle: '',
    description: '',
    image: '/assets/meta/404.png'
  },
  toHp: 'Prejsť na homepage',
  heading: 'Vami hľadanú stránku nemožno zobraziť',
  lookingFor: `Možno sa pozeráš`,
  ourServices: 'naše služby',
  references: 'referencie a prípadové štúdie',
  blog: 'náš blog',
  or: 'alebo'
};

const PL = {
  meta: {
    title: '404',
    ogTitle: '',
    description: '',
    image: '/assets/meta/404.png'
  },
  toHp: 'Przejdź do strony głównej',
  heading: 'Strona, której szukasz, nie może zostać wyświetlona',
  lookingFor: `Być może szukasz`,
  ourServices: 'nasze usługi',
  references: 'referencje i studia przypadków',
  blog: 'nasz blog',
  or: 'lub'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class FourOFourTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      routed: {
        fourOFour: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      routed: {
        fourOFour: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      routed: {
        fourOFour: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      routed: {
        fourOFour: PL
      }
    }, true);

  }
}
