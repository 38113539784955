import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from '@zerops/fe/dialog';
import { FlexModule } from '@app/core/flex';
import { ScrollSceneModule } from '@app/common/scroll-scene';
import { WrapModule } from '@app/common/wrap';
import { GalleryModule } from '@app/common/gallery';
import {
  HeroComponent,
  HeroContentDirective,
  HeroFeatureDirective
} from './hero.component';

@NgModule({
  declarations: [
    HeroComponent,
    HeroContentDirective,
    HeroFeatureDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    DialogModule,
    WrapModule,
    FlexModule,
    ScrollSceneModule,
    GalleryModule
  ],
  exports: [
    HeroComponent,
    HeroContentDirective,
    HeroFeatureDirective
  ]
})
export class HeroModule { }
