import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SettingsCoreEnum } from './settings-core.constant';
import { SettingsCoreEffect } from './settings-core.effect';
import { reducer } from './settings-core.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ SettingsCoreEffect ]),
    StoreModule.forFeature(SettingsCoreEnum.Id, reducer)
  ]
})
export class SettingsCoreModule {

}
