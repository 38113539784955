import {
  Component,
  ChangeDetectionStrategy,
  AfterContentInit,
  Input,
  OnDestroy,
  ElementRef,
  Directive,
  ContentChildren,
  QueryList,
  AfterViewInit
} from '@angular/core';
import {
  coerceNumberProperty,
  coerceBooleanProperty
} from '@angular/cdk/coercion';
import { tween } from 'popmotion';
import keys from 'lodash-es/keys';
import {
  ScrollSceneService,
  Scene
} from './scroll-scene.service';

@Directive({
  selector: '[vshScrollElement]',
  exportAs: 'scrollElement'
})
export class ScrollElementDirective implements AfterViewInit {
  @Input()
  vshScrollElement: {
    from: any;
    to: any;
  };

  @Input()
  set sceneExclude(v) {
    this._sceneExclude = coerceBooleanProperty(v);
  }
  get sceneExclude() {
    return this._sceneExclude;
  }

  tweenInstance: any;
  playbackInstance: any;

  private _sceneExclude: boolean;

  constructor(public elementRef: ElementRef) { }

  ngAfterViewInit() {
    // if (this.vshScrollElement) {
    //   const el = this.elementRef.nativeElement;

    //   this.tweenInstance = tween({
    //     from: this.vshScrollElement.from,
    //     to: this.vshScrollElement.to
    //   });

    //   const interpolatedProperties = keys(this.vshScrollElement.from);

    //   this.playbackInstance = this.tweenInstance.start((v) => {
    //     interpolatedProperties.forEach((k) => {
    //       el.style[k] = v[k];
    //     });
    //   });

    //   this.playbackInstance.stop();
    // }
  }

  seek(v: number) {
    if (this.vshScrollElement) {
      this.playbackInstance.seek(v);
    }
  }
}

@Component({
  selector: 'vsh-scroll-scene',
  templateUrl: './scroll-scene.component.html',
  styleUrls: [ './scroll-scene.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollSceneComponent implements AfterContentInit, OnDestroy {
  @Input()
  offset: number;

  @Input()
  duration: number;

  @Input()
  elementOffset: string | number;

  @ContentChildren(ScrollElementDirective, { descendants: true })
  els: QueryList<ScrollElementDirective>;

  private _scene: Scene;
  private _elementRect = {
    top: 0,
    left: 0,
    height: 0
  };
  private _windowSize: {
    width: number;
    height: number;
  };
  private _pageSize: {
    width: number;
    height: number;
  };

  constructor(
    private _elementRef: ElementRef,
    private _scrollSceneService: ScrollSceneService
  ) { }

  ngAfterContentInit() {
    // this.els.changes.subscribe(() => {
    //   this.refresh();
    //   this._calculateElementPosition();

    //   this._updateScene(true);
    // });

    // setTimeout(() => {
    //   this.refresh();
    //   this._calculateElementPosition();

    //   this._scene = this._scrollSceneService.addScene({
    //     offset: this._getOffset(),
    //     duration: this._getDuration(),
    //     items: this.els.filter((el) => !el.sceneExclude)
    //   });

    // });
  }

  ngOnDestroy() {
    this._scrollSceneService.removeScene(this._scene);
  }

  refresh(t = false) {
    this._calculateElementPosition();
    this._calculateWindowAndPageSize();
    this._updateScene(true);

    if (!t) {
      setTimeout(() => {
        this.refresh(true);
      }, 500);
    }
  }

  private _getDuration() {
    const realDuration = this.duration !== undefined
      ? coerceNumberProperty(this.duration)
      : this._elementRect.height;

    return realDuration + (-this._parseHeight(this.elementOffset));
  }

  private _getOffset() {
    const realOffset = this.offset !== undefined
      ? coerceNumberProperty(this._parseHeight(this.offset))
      : this._elementRect.top;

    return realOffset + this._parseHeight(this.elementOffset);
  }

  private _updateScene(updateItems = false) {
    if (this._scene) {
      if (updateItems) {
        this._scrollSceneService.updateItems(
          this.els.toArray(),
          this._scene
        );
      }

      this._scrollSceneService.updateOffset(
        this._getOffset(),
        this._scene
      );

      this._scrollSceneService.updateDuration(
        this._getDuration(),
        this._scene
      );
    }
  }

  private _calculateWindowAndPageSize() {
    this._windowSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this._pageSize = {
      width: document.body.clientWidth,
      height: document.body.clientHeight
    };

  }

  private _calculateElementPosition() {
    const scrollBoundingPosition = this._elementRef.nativeElement.getBoundingClientRect();

    this._elementRect = {
      top: scrollBoundingPosition.top + this._scrollSceneService.scrollPosition.top,
      left: scrollBoundingPosition.left + this._scrollSceneService.scrollPosition.left,
      height: scrollBoundingPosition.height
    };
  }

  private _parseHeight(num: number | string) {
    let type = 'pixels';
    let finalSize: number | string;

    if (typeof num === 'string') {
      if (num.indexOf('eh') !== -1) {
        type = 'elementHeight';
      }

      if (num.indexOf('vh') !== -1) {
        type = 'viewHeight';
      }

      if (num.indexOf('ph') !== -1) {
        type = 'pageHeight';
      }
    }

    switch (type) {
      case 'elementHeight':
        finalSize =  this._elementRect.height * (coerceNumberProperty((num as any).replace('eh', '')) / 100);
        break;
      case 'viewHeight':
        finalSize = this._windowSize.height * (coerceNumberProperty((num as any).replace('vh', '')) / 100);
        break;
      case 'pageHeight':
        finalSize = this._pageSize.height * (coerceNumberProperty((num as any).replace('ph', '')) / 100);
        break;
      case 'pixels':
      default:
        finalSize = num;
        break;
    }

    return coerceNumberProperty(finalSize);

  }

}
