import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServicesCoreEnum } from './services-core.constant';
import { ServicesCoreEffect } from './services-core.effect';
import { reducer } from './services-core.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ServicesCoreEffect ]),
    StoreModule.forFeature(ServicesCoreEnum.Id, reducer)
  ]
})
export class ServicesCoreModule {

}
