import {
  Component,
  ViewChild,
  ViewContainerRef,
  ElementRef
} from '@angular/core';
import { SatPopover, SatPopoverAnchoringService } from '@app/common/popover';

@Component({
  selector: 'vsh-about-pop',
  templateUrl: './about-pop.component.html',
  styleUrls: [ './about-pop.component.scss' ],
  providers: [ SatPopoverAnchoringService ]
})
export class AboutPopComponent {
  @ViewChild('datacenterPopRef', { read: SatPopover, static: true })
  datacenterPopRef: SatPopover;

  @ViewChild('connectivityPopRef', { read: SatPopover, static: true })
  connectivityPopRef: SatPopover;

  @ViewChild('aboutPopRef', { read: SatPopover, static: true })
  aboutPopRef: SatPopover;

  constructor(
    private _anchoringService: SatPopoverAnchoringService,
    private _viewContainerRef: ViewContainerRef
  ) { }

  open(type: 'datacenter' | 'about' | 'connectivity', el: ElementRef) {
    let popRef: SatPopover;

    switch (type) {
      case 'datacenter':
        popRef = this.datacenterPopRef;
        break;
      case 'connectivity':
        popRef = this.connectivityPopRef;
        break;
      case 'about':
        popRef = this.aboutPopRef;
        break;
    }

    this._anchoringService.anchor(
      popRef,
      this._viewContainerRef,
      el
    );

    this._anchoringService.openPopover();
  }

  close() {
    this._anchoringService.closePopover();
  }
}
