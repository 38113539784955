import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BlogCoreApi {
    constructor(_http) {
        this._http = _http;
    }
    list$(options = { page: 1, tags: [], language: environment.blogTranslationCategories.cs }) {
        const fTags = options.tags && options.tags.length
            ? `&tags=${options.tags.join(',')}`
            : '';
        const category = options.language;
        return this._http.get(`/api/posts?_embed&per_page=10${fTags}&page=${options.page}&categories=${category}`, { observe: 'response' });
    }
    tags$() {
        return this._http.get(`/api/tags`);
    }
    detail$(slug) {
        return this._http
            .get(`/api/posts?slug=${slug}&_embed`)
            .pipe(map((data) => data[0]));
    }
}
BlogCoreApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlogCoreApi_Factory() { return new BlogCoreApi(i0.ɵɵinject(i1.HttpClient)); }, token: BlogCoreApi, providedIn: "root" });
