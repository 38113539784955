import { NgModule } from '@angular/core';
import { BrowserComponent } from './browser.component';

@NgModule({
  declarations: [ BrowserComponent ],
  exports: [ BrowserComponent ]
})
export class BrowserModule {

}
