import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ServicesCoreApi {
    constructor(_http) {
        this._http = _http;
    }
    list$() {
        return this._http.get('/assets/services.json');
    }
}
ServicesCoreApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServicesCoreApi_Factory() { return new ServicesCoreApi(i0.ɵɵinject(i1.HttpClient)); }, token: ServicesCoreApi, providedIn: "root" });
