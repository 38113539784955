import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  // tooltips
  langChangeTooltip: 'Změnit jazyk',
  currencyChangeTooltip: 'Změnit měnu',
  loginTooltip: 'Přihlášení do klientské zóny',
  contactTooltip: 'Zobrazit kontakty',
  searchTooltip: 'Otevřít vyhledávání'
};

const SK = {
  // tooltips
  langChangeTooltip: 'Zmeniť jazyk',
  currencyChangeTooltip: 'Zmeniť menu',
  loginTooltip: 'Prihlásenie do klientskej zóny',
  contactTooltip: 'Zobraziť kontakty',
  searchTooltip: 'Otvoriť vyhľadávanie'
};

const PL = {
  // tooltips
  langChangeTooltip: 'Zmień język',
  currencyChangeTooltip: 'Zmień walutę',
  loginTooltip: 'Zaloguj się do strefy klienta',
  contactTooltip: 'Pokaż kontakty',
  searchTooltip: 'Otwórz wyszukiwanie'
};

const EN = {
  // tooltips
  langChangeTooltip: 'Change language',
  currencyChangeTooltip: 'Change currency',
  contactTooltip: 'Show contact',
  loginTooltip: 'Client zone login',
  searchTooltip: 'Open search'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class AppBarTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      features: {
        appBar: CS
      }
    }, true);

    this._translate.setTranslation('sk', {
      features: {
        appBar: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      features: {
        appBar: PL
      }
    }, true);

    this._translate.setTranslation('en', {
      features: {
        appBar: EN
      }
    }, true);

  }
}
