import { createFeatureSelector, createSelector } from '@ngrx/store';
import { postsAdapter, tagsAdapter } from './blog-core.adapter';
import { BlogCoreState } from './blog-core.model';
import { BlogCoreEnum } from './blog-core.constant';

const {
  selectIds: selectPostsIds,
  selectEntities: selectPostsEntities,
  selectAll: selectPostsAll,
  selectTotal: selectPostsTotal,
} = postsAdapter.getSelectors();

const {
  selectIds: selectTagsIds,
  selectEntities: selectTagsEntities,
  selectAll: selectTagsAll,
  selectTotal: selectTagsTotal,
} = tagsAdapter.getSelectors();


export const selectState = createFeatureSelector<BlogCoreState>(BlogCoreEnum.Id);

export const selectPosts = createSelector(
  selectState,
  (s) => s.posts
);

export const selectBlogIds = createSelector(
  selectPosts,
  selectPostsIds
);

export const selectBlogEntities = createSelector(
  selectPosts,
  selectPostsEntities
);

export const selectAllBlogs = createSelector(
  selectPosts,
  selectPostsAll
);

export const selectBlogsTotal = createSelector(
  selectPosts,
  selectPostsTotal
);

export const selectBlogsDbTotal = createSelector(
  selectState,
  (s) => s.totalPosts
);

export const selectTags = createSelector(
  selectState,
  (s) => s.tags
);

export const selectTagIds = createSelector(
  selectTags,
  selectTagsIds
);

export const selectTagEntities = createSelector(
  selectTags,
  selectTagsEntities
);

export const selectTagAll = createSelector(
  selectTags,
  selectTagsAll
);

export const selectTagTotal = createSelector(
  selectTags,
  selectTagsTotal
);
