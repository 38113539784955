// tslint:disable
import { Directive, Type } from '@angular/core';
import {
  FlexDirective,
  ClassDirective,
  LayoutGapDirective
} from '@angular/flex-layout';

export function _inheritCtorParametersMetadata(target: Type<any>, base: Type<any>) {
  (target as any)['ctorParameters'] = () => {
    const baseParameters = (base as any)['ctorParameters'];
    return (typeof baseParameters === 'function' ? baseParameters() : baseParameters) || [];
  };
}

const selectorFlex = `[fxFlex.sd], [fxFlex.gt-sd], [fxFlex.lt-sd], [fxFlex.md-sd]`;
const inputsFlex = [ 'fxFlex.sd', 'fxFlex.lt-sd', 'fxFlex.md-sd' ];

@Directive({ selector: selectorFlex, inputs: inputsFlex })
export class CustomFlexDirective extends FlexDirective {
  protected inputs = inputsFlex;
}

_inheritCtorParametersMetadata(CustomFlexDirective, FlexDirective);

const selectorClass = `[ngClass.sd], [ngClass.gt-sd], [ngClass.lt-sd], [ngClass.md-sd]`;
const inputsClass = [ 'ngClass.sd', 'ngClass.gt-sd', 'ngClass.lt-sd', 'ngClass.md-sd' ];

@Directive({ selector: selectorClass, inputs: inputsClass })
export class CustomFlexClassDirective extends ClassDirective {
  protected inputs = inputsClass;
}

_inheritCtorParametersMetadata(CustomFlexClassDirective, ClassDirective);

const selectorGap = `[fxLayoutGap.lt-sd]`;
const inputsGap = [ 'fxLayoutGap.lt-sd' ];

@Directive({ selector: selectorGap, inputs: inputsGap })
export class CustomFlexGapDirective extends LayoutGapDirective {
  protected inputs = inputsGap;
}

_inheritCtorParametersMetadata(CustomFlexGapDirective, LayoutGapDirective);
