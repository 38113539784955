import { BlogEntity } from './blog-core.model';
import flatten from 'lodash-es/flatten';
import { WpTerm } from '../wp-core';

export const blogProjected = (item: BlogEntity) => ({
  ...item,
  _tags: flatten<WpTerm>(item._embedded['wp:term']).filter(
    (t) => t.taxonomy === 'post_tag'
  )
});
