import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { SectionModule } from '@app/common/section';
import { TildeModule } from '@app/common/tilde';
import { FourOFourPage } from './four-o-four.page';
import { FourOFourRoutesModule } from './four-o-four.routes';
import { FourOFourTranslationsModule } from './four-o-four.translations';

@NgModule({
  declarations: [ FourOFourPage ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    SectionModule,
    TildeModule,
    FourOFourTranslationsModule,
    FourOFourRoutesModule
  ]
})
export class FourOFourModule { }
