<span
  [@anim]="selectedIndex"
  *ngFor="let item of items; trackBy: trackBy;">

  <span class="__letters">
    <span [innerHTML]="item"></span>
  </span>

  <span class="__comma">,</span>

</span>
