/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claim-items.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./claim-items.component";
var styles_ClaimItemsComponent = [i0.styles];
var RenderType_ClaimItemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimItemsComponent, data: { "animation": [{ type: 7, name: "anim", definitions: [{ type: 1, expr: ":increment, :decrement", animation: [{ type: 3, steps: [{ type: 11, selector: ".__comma", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: null }, { type: 11, selector: ".__letter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: null }], options: null }, { type: 11, selector: ".__letter", animation: [{ type: 6, styles: { opacity: 0, transform: "translate3d(0, -3px, 0)" }, offset: null }, { type: 12, timings: 50, animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translate3d(0, 0, 0)" }, offset: null }, timings: "400ms cubic-bezier(0.4, 0.0, 0.2, 1)" }] }], options: { optional: true } }, { type: 11, selector: ".__comma", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "300ms cubic-bezier(0.4, 0.0, 0.2, 1)" }], options: null }], options: null }], options: {} }] } });
export { RenderType_ClaimItemsComponent as RenderType_ClaimItemsComponent };
function View_ClaimItemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], [[24, "@anim", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "__letters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "__comma"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [","]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedIndex; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
export function View_ClaimItemsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimItemsComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.trackBy; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ClaimItemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-claim-items", [], null, null, null, View_ClaimItemsComponent_0, RenderType_ClaimItemsComponent)), i1.ɵdid(1, 49152, null, 0, i3.ClaimItemsComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var ClaimItemsComponentNgFactory = i1.ɵccf("vsh-claim-items", i3.ClaimItemsComponent, View_ClaimItemsComponent_Host_0, {}, {}, []);
export { ClaimItemsComponentNgFactory as ClaimItemsComponentNgFactory };
