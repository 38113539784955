import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  socialTitle: 'Sociální sítě'
};

const EN = {
  socialTitle: 'Social sites'
};

const SK = {
  socialTitle: 'Sociálne siete'
};

const PL = {
  socialTitle: 'Sieci społecznościowe'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class FooterTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      features: {
        footer: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      features: {
        footer: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      features: {
        footer: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      features: {
        footer: PL
      }
    }, true);

  }
}
