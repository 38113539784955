import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '@app/common/price';
import { PriceRangeComponent } from './price-range.component';

@NgModule({
  declarations: [ PriceRangeComponent ],
  imports: [
    CommonModule,
    TranslateModule,
    PriceModule
  ],
  exports: [ PriceRangeComponent ]
})
export class PriceRangeModule { }
