<a
  *ngIf="link"
  class="__tag  __tag--link"
  [routerLink]="link"
  [attr.rel]="noFollow.indexOf(text) !== -1 ? 'nofollow' : null">
  #{{ text }}
</a>

<span
  *ngIf="!link"
  class="__tag">
  #{{ text }}
</span>
