<h2
  *ngIf="!showCaseStudies"
  class="u-text--center"
  translate="common.clientsStrip.title">
</h2>

<h2
  *ngIf="showCaseStudies"
  class="u-text--center"
  translate="common.clientsStrip.caseStudiesTitle">
</h2>

<div class="__separator-tilde">
  <vsh-tilde color="gamma"></vsh-tilde>
</div>

<vsh-wrap
  *ngIf="showCaseStudies"
  class="__case-studies-wrap">
  <vsh-case-study-carousel
    [data]="data">
  </vsh-case-study-carousel>
</vsh-wrap>

<vsh-wrap class="__clients">

  <div
    fxLayout="row wrap"
    fxLayoutAlign="top top"
    fxLayoutGap="48px grid">

    <div
      fxFlex="33.3334"
      fxFlex.lt-md="50"
      class="__client-layout-item"
      *ngFor="let item of (clients$ | async); trackBy: trackBy;">
      <div class="__client">
        <div
          class="__client_content"
          fxLayout="row"
          fxLayoutAlign="center center">
          <img
            fxFlex="100"
            class="__client_image"
            [class.has-opacity]="!showCaseStudies"
            [src]="item.logo | safe: 'url'"
          />
        </div>
      </div>
    </div>

    <div
      fxFlex="33.3334"
      fxFlex.lt-md="50"
      class="__client-layout-item">
      <div class="__client">
        <a
          [routerLink]="[ '/', 'references' ]"
          class="__client_content  __client_content--link"
          fxLayout="row"
          fxLayoutAlign="center center">
          <div fxFlex="100">
            <h2>+{{ clientsTotal }} <span class="__client_content-sub" [innerHTML]="'common.clientsStrip.otherClients' | translate"></span></h2>
          </div>
        </a>
      </div>
    </div>

  </div>

</vsh-wrap>

<div
  *ngIf="showCaseStudies"
  class="u-text--center">
  <button
    mat-stroked-button
    class="c-button  __cta"
    [routerLink]="[ '/', 'references' ]"
    [innerHTML]="'common.clientsStrip.allReferences' | translate">
  </button>
</div>

<vsh-cable-two class="__cable  __cable--first"></vsh-cable-two>
