import { Action } from '@app/models';
import { ServiceEntity } from './services-core.model';

export enum ActionTypes {
  ListRequest = '[Services Core] List Request',
  ListFail = '[Services Core] List Fail',
  ListLocalSuccess = '[Services Core] List Local Success'
}

export class ListRequest implements Action {
  readonly type = ActionTypes.ListRequest;
}
export class ListFail implements Action {
  readonly type = ActionTypes.ListFail;
}

export class ListLocalSuccess implements Action {
  readonly type = ActionTypes.ListLocalSuccess;

  constructor(public payload: ServiceEntity<any>[]) { }
}

export type Actions
  = ListRequest
  | ListFail
  | ListLocalSuccess;
