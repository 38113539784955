/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wrap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./wrap.component";
var styles_WrapComponent = [i0.styles];
var RenderType_WrapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WrapComponent, data: {} });
export { RenderType_WrapComponent as RenderType_WrapComponent };
export function View_WrapComponent_0(_l) { return i1.ɵvid(2, [i1.ɵncd(null, 0)], null, null); }
export function View_WrapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-wrap", [], null, null, null, View_WrapComponent_0, RenderType_WrapComponent)), i1.ɵdid(1, 49152, null, 0, i2.WrapComponent, [i1.ElementRef], null, null)], null, null); }
var WrapComponentNgFactory = i1.ɵccf("vsh-wrap", i2.WrapComponent, View_WrapComponent_Host_0, {}, {}, ["*"]);
export { WrapComponentNgFactory as WrapComponentNgFactory };
