import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TildeComponent } from './tilde.component';

@NgModule({
  declarations: [ TildeComponent ],
  imports: [ CommonModule ],
  exports: [ TildeComponent ]
})
export class TildeModule { }
