import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS
} from '@angular/material/tooltip';
import { MatIconRegistry } from '@angular/material/icon';
import localeCs from '@angular/common/locales/cs';
import { Router, NavigationEnd } from '@angular/router';
import {
  TranslateModule,
  TranslateCompiler,
  TranslateService
} from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DialogModule } from '@zerops/fe/dialog';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { RouterEffects } from 'ngrx-router';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { META_CONFIG, MetaModule } from '@app/core/meta';
import { customBreakpointsProvider, FlexModule } from '@app/core/flex';
import { FooterModule } from '@app/features/footer';
import { AppBarModule } from '@app/features/app-bar';
import { MenuModule } from '@app/features/menu';
import { ContactPopModule } from '@app/features/contact-pop';
import { ScrollSceneModule } from '@app/common/scroll-scene';
import { HomeModule } from './modules/pages/-home';
import { FourOFourModule } from './modules/pages/-four-o-four';
import { CoreModule } from './modules/core';
import { metaReducers } from './reducers';
import { ApiInterceptorModule } from './interceptors';
import { AppRoutesModule } from './app.routes';
import { AppComponent } from './app.component';
import { AppTranslationsModule } from './app.translations';

registerLocaleData(localeCs);

export function easing(t: number, b: number, c: number, d: number): number  {
  if (t === 0) { return b; }
  if (t === d) { return b + c; }
  if ((t /= d / 2) < 1) { return c / 2 * Math.pow(2, 10 * (t - 1)) + b; }
  return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
}

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    NgxPageScrollCoreModule.forRoot({
      duration: 550,
      easingLogic: easing
    }),
    StoreModule.forRoot({}, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
    }),
    EffectsModule.forRoot([ RouterEffects ]),
    DialogModule.forRoot(),
    CoreModule,
    MetaModule.forRoot(),
    ApiInterceptorModule,
    AppRoutesModule,
    Angulartics2Module.forRoot(),
    FlexModule,
    AppBarModule,
    FourOFourModule,
    HomeModule,
    FooterModule,
    ScrollSceneModule,
    MenuModule,
    ContactPopModule,
    AppTranslationsModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'cs'
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 400
      } as MatTooltipDefaultOptions
    },
    customBreakpointsProvider,
    {
      provide: META_CONFIG,
      useValue: {
        baseTitle: 'vshosting~'
      }
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(
    private _translate: TranslateService,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _rendererFactory: RendererFactory2
  ) {

    // translations
    this._translate.setDefaultLang(this._translate.getBrowserLang());
    this._translate.use(this._translate.getBrowserLang());

    // custom icons
    [ 'facebook', 'instagram', 'linkedin', 'twitter', 'intercom-chat' ].forEach((name) => {
      this._iconRegistry.addSvgIcon(
        name,
        this._sanitizer.bypassSecurityTrustResourceUrl(
          `/assets/custom-icons/${name}.svg`
        )
      );
    });

    // intercom boot
    (window as any).Intercom('boot', {
      app_id: 'yzomhz6p'
    });


    // TODO: refactor and create new service
    const renderer = this._rendererFactory.createRenderer(undefined, undefined);
    const langsMap = {
      com: 'en',
      pl: 'pl',
      sk: 'sk',
      cz: 'x-default'
    };
    this._router.events.subscribe((d) => {
      if (d instanceof NavigationEnd) {
        document.querySelectorAll('[hreflang]').forEach((el) => el.remove());

        const head = document.head;
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;

        const url = d.url.split('?')[0];
        [ 'com', 'pl', 'sk', 'cz' ].forEach((lang) => {
          const link = renderer.createElement('link');
          const href = protocol + '//' + hostname.substring(0, hostname.lastIndexOf('.')) + '.' + lang + url;

          renderer.setAttribute(link, 'rel', 'alternate');
          renderer.setAttribute(link, 'hreflang', langsMap[lang]);
          renderer.setAttribute(link, 'href', href);
          renderer.appendChild(head, link);
        });
      }
    });

  }
}
