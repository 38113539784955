import { Routes } from '@angular/router';
const ɵ0 = () => import("./modules/pages/+about/about.module.ngfactory").then(m => m.AboutModuleNgFactory), ɵ1 = {
    animation: 'about',
    topBg: 'gamma',
    appBarQuickLinkMode: 'services',
    footerMode: 'full'
}, ɵ2 = () => import("./modules/pages/+tech/tech.module.ngfactory").then(m => m.TechModuleNgFactory), ɵ3 = {
    animation: 'tech',
    topBg: 'grey',
    appBarQuickLinkMode: 'contact',
    footerMode: 'full'
}, ɵ4 = () => import("./modules/pages/+services/services.module.ngfactory").then(m => m.ServicesModuleNgFactory), ɵ5 = {
    animation: 'services',
    topBg: 'grey',
    appBarQuickLinkMode: 'services',
    footerMode: 'full'
}, ɵ6 = () => import("./modules/pages/+work-with-us/work-with-us.module.ngfactory").then(m => m.WorkWithUsModuleNgFactory), ɵ7 = {
    animation: 'work-with-us',
    topBg: 'grey',
    appBarQuickLinkMode: 'contact',
    footerMode: 'full'
}, ɵ8 = () => import("./modules/pages/+contact/contact.module.ngfactory").then(m => m.ContactModuleNgFactory), ɵ9 = {
    animation: 'contact',
    topBg: 'grey',
    appBarQuickLinkMode: 'services',
    footerMode: 'compact'
}, ɵ10 = () => import("./modules/pages/+documents/documents.module.ngfactory").then(m => m.DocumentsModuleNgFactory), ɵ11 = {
    animation: 'documents',
    topBg: 'page',
    appBarQuickLinkMode: 'services',
    footerMode: 'full'
}, ɵ12 = () => import("./modules/pages/+references/references.module.ngfactory").then(m => m.ReferencesModuleNgFactory), ɵ13 = {
    animation: 'references',
    topBg: 'page',
    appBarQuickLinkMode: 'services',
    footerMode: 'full'
}, ɵ14 = () => import("./modules/pages/+case-study/case-study.module.ngfactory").then(m => m.CaseStudyModuleNgFactory), ɵ15 = {
    animation: 'case-study',
    topBg: 'grey',
    appBarQuickLinkMode: 'services',
    footerMode: 'full'
}, ɵ16 = () => import("./modules/pages/+blog/blog.module.ngfactory").then(m => m.BlogModuleNgFactory), ɵ17 = {
    animation: 'blog',
    topBg: 'grey',
    appBarQuickLinkMode: 'services',
    footerMode: 'full'
};
const routes = [
    {
        path: 'about',
        loadChildren: ɵ0,
        data: ɵ1
    },
    {
        path: 'tech',
        loadChildren: ɵ2,
        data: ɵ3
    },
    {
        path: 'services',
        loadChildren: ɵ4,
        data: ɵ5
    },
    {
        path: 'work-with-us',
        loadChildren: ɵ6,
        data: ɵ7
    },
    {
        path: 'contact',
        loadChildren: ɵ8,
        data: ɵ9
    },
    {
        path: 'documents',
        loadChildren: ɵ10,
        data: ɵ11
    },
    {
        path: 'references',
        loadChildren: ɵ12,
        data: ɵ13
    },
    {
        path: 'references/:case',
        loadChildren: ɵ14,
        data: ɵ15
    },
    {
        path: 'blog',
        loadChildren: ɵ16,
        data: ɵ17
    },
];
export class AppRoutesModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
