import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  placeTitle: 'Kde nás najdete',
  showOnMapButton: 'Zobrazit na mapě'
};

const EN = {
  placeTitle: 'Where to find us',
  showOnMapButton: 'Show on map'
};

const SK = {
  placeTitle: 'Kde nás nájdete',
  showOnMapButton: 'Zobraziť na mape'
};

const PL = {
  placeTitle: 'Gdzie nas znaleźć',
  showOnMapButton: 'Pokaż na mapie'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class AddressBoxTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        addressBox: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        addressBox: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        addressBox: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        addressBox: PL
      }
    }, true);

  }
}
