<div class="u-text--center">

  <button
    mat-button
    type="button"
    (click)="clicked.emit($event)"
    class="[ c-button  u-color--copyInvert  c-button--bg-alpha ]">
    <mat-icon class="__icon  u-color--identityBetaColor" svgIcon="intercom-chat"></mat-icon>&nbsp;
    <span>{{ baseText }}</span>&nbsp;<span fxHide.lt-lg>{{ moreText }}</span>
  </button>

  <p fxHide.gt-md class="__desc" [style.color]="isGamma ? '#fff' : 'inherit'">
    {{ moreText }}
  </p>

</div>
