import { NgModule } from '@angular/core';
import { SatPopoverModule } from '@app/common/popover';
import { AboutPopModule } from '@app/common/about-pop';
import { AboutPopWrapComponent } from './about-pop-wrap.component';

@NgModule({
  declarations: [ AboutPopWrapComponent ],
  imports: [
    SatPopoverModule,
    AboutPopModule
  ],
  exports: [ AboutPopWrapComponent ]
})
export class AboutPopWrapModule { }
