<div class="__tags" [class.is-active]="activeTags.length">

  <button
    *ngFor="let tag of possibleTags; trackBy: trackBy;"
    class="__tag"
    [class.is-active]="activeTags.includes(tag)"
    (click)="toggleActiveTag(tag)"
    type="button">
    + {{ tag }}
  </button>

  <p class="__tags_desc">
    <mat-icon class="__tags_desc-icon">filter_list</mat-icon>
    <span translate="common.services.filterHint"></span>
  </p>

</div>

<div class="__separator">
  <!-- <vsh-tilde class="__tilde" color="alpha"></vsh-tilde> -->
</div>

<div
  fxLayout="row wrap"
  fxLayoutGap="48px grid"
  fxLayoutGap.lt-md="0px grid">
  <div
    class="__item"
    [class.is-active]="tagIncludes(item.category)"
    [class.is-active-active]="tagIncludes(item.category) && activeTags.length > 0"
    [class.is-faded]="activeTags.length > 0"
    [class.is-touch]="platform.ANDROID || platform.IOS"
    [class.__item--lt-sm]="media.isActive('lt-sm')"
    fxFlex="33.3334"
    fxFlex.lt-lg="50"
    fxFlex.lt-md="100"
    *ngFor="let item of (services$ | async); trackBy: trackBy;">
    <div class="__item_content">
      <span
        *ngIf="item.inMenu"
        class="__favorite"
        translate="common.services.favoriteTag">
      </span>

      <a
        matRipple
        class="__item_link"
        angulartics2On="click"
        angularticsAction="Click"
        [angularticsProperties]="servicesEventTags[item.id]?.main"
        [routerLink]="item.link">

        <h3 class="__item_name">{{ item.name }}</h3>

        <p class="__item_desc">{{ item.shortDesc }}</p>

        <vsh-price-range
          *ngIf="item?.fromToPrice && item?.fromToPrice?.from !== undefined && item?.fromToPrice?.to !== undefined"
          class="__item_price-range"
          [currency]="currency$ | async"
          [priceFrom]="item.fromToPrice.from"
          [priceTo]="item.fromToPrice.to">
        </vsh-price-range>

        <p
          *ngIf="!item?.fromToPrice || item?.fromToPrice.from === undefined || item?.fromToPrice.to === undefined"
          class="__item_individual-pricing"
          translate="app.individualPricing">
        </p>

      </a>

      <div
        class="__buttons"
        [class.is-expanded]="expanded">

        <a
          mat-stroked-button
          angulartics2On="click"
          angularticsAction="Click"
          [angularticsProperties]="servicesEventTags[item.id]?.secondary"
          [routerLink]="item.link"
          class="[ c-button c-button--mini ] __button  __button--main">
          <span translate="common.services.detailButton"></span>
        </a>

        <button
          mat-button
          type="button"
          angulartics2On="click"
          angularticsAction="Contact_Form_Initiated"
          [angularticsProperties]="servicesEventTags[item.id]?.support"
          [color]="'accent'"
          (click)="onContact(item.name)"
          class="[ c-button  c-button--mini ]  __button  __button--secondary">
          <mat-icon>mail_outline</mat-icon>&nbsp;<span translate="common.services.inquiryButton"></span>
        </button>

      </div>

    </div>
  </div>
</div>
