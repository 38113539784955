import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { CaseStudyEntity } from '@app/core/case-studies-core';

@Component({
  selector: 'vsh-case-study-carousel',
  templateUrl: './case-study-carousel.component.html',
  styleUrls: [ './case-study-carousel.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseStudyCarouselComponent implements OnInit {

  currentPage = 0;
  pagedData: CaseStudyEntity[];
  maxPageSize: number;
  cachedPageSize: number;

  @Input()
  pageSize = 2;

  @Input()
  set data(v: CaseStudyEntity[]) {
    this._data = v;
    this.cachedPageSize = this.pageSize;
    this.paginate(this.currentPage, v);
    this.setMaxPageSize();
  }

  get data() {
  return this._data;
  }

  private _data: CaseStudyEntity[];

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._breakpointObserver.observe('(max-width: 1076px)')
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((result) => {
        if (result.matches) {
          this.pageSize = 1;
        } else {
          this.pageSize = this.cachedPageSize;
        }

        this.setMaxPageSize();
        this.paginate(0);
        this._cdRef.detectChanges();
      });
  }

  paginate(page: number, arr?: CaseStudyEntity[]) {
    this.currentPage = page;
    if (this.pageSize < 1 || arr ? !arr : !this.data) { return []; }
    const basePage = page * this.pageSize;
    const data = page < 0 || basePage >= (arr ? arr.length : this.data.length)
      ? []
      : arr
        ? arr.slice(basePage, basePage + this.pageSize)
        : this.data.slice(basePage, basePage + this.pageSize);

    if (this.data && this.data.length > 1 && data.length === 1 && this.pageSize > 1) {
      const index = this.data.indexOf(this.data.find((item) => item.id === data[data.length - 1].id));
      data.unshift(this.data[index - 1]);
    }

    this.pagedData = data;
  }

  setMaxPageSize() {
    this.maxPageSize = Math.round(this.data.length / this.pageSize);
  }

}


