import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  contactTitle: 'Kontaktujte nás',
  inquiryTitle: 'Nezávazná poptávka',
  mailLabel: 'E-mail nebo telefonní číslo',
  mailPlaceholder: 'Podle toho jakou formu komunikace preferujete',
  messageLabel: 'Zpráva',
  inquiryMessageLabel: 'Přiložit text',
  messageContactPlaceholder: 'Chcete pomoci s výběrem, nebo mluvit s naším obchodníkem? Dejte nám vědět a my budeme do 24h hodin reagovat',
  messageInquiryPlaceholder: 'Přidat informace o vašem současném řešení?',
  sendContactButton: 'Odeslat zprávu',
  sendInquiryButton: 'Odeslat poptávku',
  chatButton: 'Kontaktujte nás po chatu',
  chatButtonDescContact: 'Chcete to vyřešit co nejrychleji? Kliknutí zkopíruje Vaší zprávu do našeho chatu.',
  chatButtonDescInquiry: 'Chcete to vyřešit co nejrychleji? Kliknutí zkopíruje Vaší poptávku do našeho chatu.',
  chatSnack: 'Zmáčkněte <strong>ENTER</strong> nebo tlačítko <mat-icon class="__snack-icon">send</mat-icon> pro odeslání zprávy do chatu.',
  successContactSnack: 'Zpráva úspěšně odeslána. Brzy se ozveme!',
  successInquirySnack: 'Poptávka úspěšně odeslána. Brzy se ozveme!',
  failSnack: 'Zprávu se nepodařilo odeslat, zkuste to znovu, nebo použijte chat.',
  interestAdditional: 'Mám také zájem o'
};

const EN = {
  contactTitle: 'Contact us',
  inquiryTitle: 'Tentative inquiry',
  mailLabel: 'E-mail or phone number',
  mailPlaceholder: 'Depending on your preferred form of communication',
  messageLabel: 'Message',
  // tslint:disable-next-line:max-line-length
  messageContactPlaceholder: `Would you like some assistance or to speak with one of our specialist? Let us know and we will get back to you within 24 hours.`,
  messageInquiryPlaceholder: 'Add information about your current solution',
  sendContactButton: 'Send message',
  sendInquiryButton: 'Send inquiry',
  inquiryMessageLabel: 'Attached text',
  chatButton: 'Contact us via chat',
  chatButtonDescContact: 'Want things done as quickly as possible? Click the button above to send your message to our chat.',
  chatButtonDescInquiry: 'Want things done as quickly as possible? Click the button above to send your message to our chat.',
  chatSnack: 'Press <strong>BACKSPACE</strong> or <mat-icon class="__snack-icon">send</mat-icon> button to send the chat message.',
  successContactSnack: 'Message was successfully sent. We will get back to you as soon as possible',
  successInquirySnack: 'Inquiry was successfully sent. We will get back to you as soon as possible',
  failSnack: 'Message could not be sent, please try again or try our chat.',
  interestAdditional: `I'm also interested in`
};

const SK = {
  contactTitle: 'Kontaktujte nás',
  inquiryTitle: 'Nezáväzný dopyt',
  mailLabel: 'E-mail alebo telefónne číslo',
  mailPlaceholder: 'Podľa toho akú formu komunikácie preferujete',
  messageLabel: 'Správa',
  inquiryMessageLabel: 'Priložiť text',
  // tslint:disable-next-line:max-line-length
  messageContactPlaceholder: 'Chcete pomôcť s výberom, alebo hovoriť s našim obchodníkom? Dajte nám vedieť a my budeme do 24h hodín reagovať',
  messageInquiryPlaceholder: 'Pridať informácie o vašom súčasnom riešení?',
  sendContactButton: 'Odoslať správu',
  sendInquiryButton: 'Odoslať dopyt',
  chatButton: 'Napíšte nám po chatu',
  chatButtonDescContact: 'Chcete to vyriešiť čo najrýchlejšie? Kliknutím skopírujete Vašu správu do nášho chatu.',
  chatButtonDescInquiry: 'Chcete to vyriešiť čo najrýchlejšie? Kliknutím skopírujete Vašu požiadavku do nášho chatu.',
  chatSnack: 'Stlačte <strong>ENTER</strong> alebo tlačidlo <mat-icon class="__ snack-icon">send</mat-icon> pre odoslanie správy do chatu.',
  successContactSnack: 'Zpráva úspěšně odeslána. Brzy se ozveme!',
  successInquirySnack: 'Dopyt bol úspešne odoslaný. Brzy se ozveme!',
  failSnack: 'Správu sa nepodarilo odoslať, skúste to znova, alebo použite chat.',
  interestAdditional: 'Mám tiež záujem o'
};

const PL = {
  contactTitle: 'Skontaktuj się z nami',
  inquiryTitle: 'Niewiążące zapotrzebowanie',
  mailLabel: 'E-mail lub numer telefonu',
  mailPlaceholder: 'W zależności od preferowanej formy komunikacji',
  messageLabel: 'wiadomość',
  inquiryMessageLabel: 'Dołącz tekst',
  // tslint:disable-next-line:max-line-length
  messageContactPlaceholder: 'Chcesz pomóc przy wyborze lub porozmawiać z naszym handlowcem? Poinformuj nas, a my odpowiemy w ciągu 24 godzin',
  messageInquiryPlaceholder: 'Dodaj informacje o swoim obecnym rozwiązaniu?',
  sendContactButton: 'Wyślij wiadomość',
  sendInquiryButton: 'Wyślij zapytanie',
  chatButton: 'Skontaktuj się z nami po czacie',
  chatButtonDescContact: 'Chcesz go rozwiązać tak szybko, jak to możliwe? Kliknięcie spowoduje skopiowanie wiadomości na nasz czat.',
  chatButtonDescInquiry: 'Chcesz go rozwiązać tak szybko, jak to możliwe? Kliknięcie spowoduje skopiowanie zapytania do naszego czatu.',
  chatSnack: 'Naciśnij <strong>ENTER</strong> lub <mat-icon class ="__ snack-icon">send</mat-icon>, aby wysłać wiadomość na czat.',
  successContactSnack: 'Wiadomość wysłana pomyślnie. Wkrótce skontaktujemy się z Tobą!',
  successInquirySnack: 'Zapytanie zostało pomyślnie wysłane. Wkrótce skontaktujemy się z Tobą!',
  failSnack: 'Nie udało się wysłać wiadomości, spróbuj ponownie lub użyj czatu.',
  interestAdditional: 'Interesuje mnie także'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class ContactPopTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      features: {
        contactPop: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      features: {
        contactPop: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      features: {
        contactPop: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      features: {
        contactPop: PL
      }
    }, true);

  }
}
