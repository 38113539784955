import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CaseStudiesCoreEnum } from './case-studies-core.constant';
import { CaseStudiesCoreEffect } from './case-studies-core.effect';
import { reducer } from './case-studies-core.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ CaseStudiesCoreEffect ]),
    StoreModule.forFeature(CaseStudiesCoreEnum.Id, reducer)
  ]
})
export class CaseStudiesCoreModule {

}
