import { DomSanitizer } from '@angular/platform-browser';
import { RendererFactory2 } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { MatIconRegistry } from '@angular/material/icon';
import localeCs from '@angular/common/locales/cs';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
registerLocaleData(localeCs);
export function easing(t, b, c, d) {
    if (t === 0) {
        return b;
    }
    if (t === d) {
        return b + c;
    }
    if ((t /= d / 2) < 1) {
        return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
    }
    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
}
const ɵ0 = {
    showDelay: 400
}, ɵ1 = {
    baseTitle: 'vshosting~'
};
export class AppModule {
    constructor(_translate, _iconRegistry, _sanitizer, _router, _rendererFactory) {
        this._translate = _translate;
        this._iconRegistry = _iconRegistry;
        this._sanitizer = _sanitizer;
        this._router = _router;
        this._rendererFactory = _rendererFactory;
        // translations
        this._translate.setDefaultLang(this._translate.getBrowserLang());
        this._translate.use(this._translate.getBrowserLang());
        // custom icons
        ['facebook', 'instagram', 'linkedin', 'twitter', 'intercom-chat'].forEach((name) => {
            this._iconRegistry.addSvgIcon(name, this._sanitizer.bypassSecurityTrustResourceUrl(`/assets/custom-icons/${name}.svg`));
        });
        // intercom boot
        window.Intercom('boot', {
            app_id: 'yzomhz6p'
        });
        // TODO: refactor and create new service
        const renderer = this._rendererFactory.createRenderer(undefined, undefined);
        const langsMap = {
            com: 'en',
            pl: 'pl',
            sk: 'sk',
            cz: 'x-default'
        };
        this._router.events.subscribe((d) => {
            if (d instanceof NavigationEnd) {
                document.querySelectorAll('[hreflang]').forEach((el) => el.remove());
                const head = document.head;
                const protocol = window.location.protocol;
                const hostname = window.location.hostname;
                const url = d.url.split('?')[0];
                ['com', 'pl', 'sk', 'cz'].forEach((lang) => {
                    const link = renderer.createElement('link');
                    const href = protocol + '//' + hostname.substring(0, hostname.lastIndexOf('.')) + '.' + lang + url;
                    renderer.setAttribute(link, 'rel', 'alternate');
                    renderer.setAttribute(link, 'hreflang', langsMap[lang]);
                    renderer.setAttribute(link, 'href', href);
                    renderer.appendChild(head, link);
                });
            }
        });
    }
}
export { ɵ0, ɵ1 };
