import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'vsh-blog-info',
  templateUrl: './blog-info.component.html',
  styleUrls: [ './blog-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogInfoComponent {
  @Input()
  personImage: string;

  @Input()
  name: string;

  @Input()
  size: number;

  @Input()
  date: Date;
}
