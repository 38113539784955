import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CaseStudiesCoreApi {
    constructor(_http) {
        this._http = _http;
    }
    list$() {
        return this._http.get('/assets/case-studies.json');
    }
}
CaseStudiesCoreApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CaseStudiesCoreApi_Factory() { return new CaseStudiesCoreApi(i0.ɵɵinject(i1.HttpClient)); }, token: CaseStudiesCoreApi, providedIn: "root" });
