import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '@app/core/meta';

@Component({
  selector: 'vsh-four-o-four-page',
  templateUrl: './four-o-four.page.html',
  styleUrls: [ './four-o-four.page.scss' ]
})
export class FourOFourPage {
  constructor(
    private _meta: MetaService,
    private _metaNative: Meta,
    private _translate: TranslateService
  ) {

    // tell prerender to return 404 status code
    this._metaNative.addTag({
      name: 'prerender-status-code',
      content: '404'
    });

    this._translate
      .get([
        'routed.fourOFour.meta.ogTitle',
        'routed.fourOFour.meta.title',
        'routed.fourOFour.meta.description',
        'routed.fourOFour.meta.image'
      ])
      .subscribe((meta: any) => {
        this._meta.set({
          ogTitle: meta['routed.fourOFour.meta.ogTitle'],
          title: meta['routed.fourOFour.meta.title'],
          description: meta['routed.fourOFour.meta.description'],
          image: meta['routed.fourOFour.meta.image']
        });
      });
  }
}

