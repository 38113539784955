import {
  Component,
  Input
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'vsh-about-strip',
  templateUrl: './about-strip.component.html',
  styleUrls: [ './about-strip.component.scss' ]
})
export class AboutStripComponent {
  @Input()
  hideSecondaryButton = false;

  constructor(public media: MediaObserver) { }
}
