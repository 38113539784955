import { createEntityAdapter } from '@ngrx/entity';
const ɵ0 = (blog) => blog.slug;
export const postsAdapter = createEntityAdapter({
    selectId: ɵ0
});
const ɵ1 = (tag) => tag.slug;
export const tagsAdapter = createEntityAdapter({
    selectId: ɵ1
});
export { ɵ0, ɵ1 };
