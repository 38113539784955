import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LogoModule } from '@app/common/logo';
import { PriceModule } from '@app/common/price';
import { PriceRangeModule } from '@app/common/price-range';
import { ServiceItemComponent } from './service-item.component';

@NgModule({
  declarations: [ ServiceItemComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    LogoModule,
    PriceModule,
    PriceRangeModule
  ],
  exports: [ ServiceItemComponent ]
})
export class ServiceItemModule { }
