<div
  class="__content"
  [ngClass]="{
    'is-retracted': _retracted,
    'is-hidden': _hidden
  }">
  <vsh-wrap
    class="__wrap"
    [class.has-menu-open]="menuOpen"
    [class.__wrap--dense]="media.isActive('lt-sm') || media.isActive('lt-md')"
    [class.__wrap--no-padding]="media.isActive('lt-sd')">
    <div fxLayout="row">

      <div fxFlex="20">
        <a
          matRipple
          [routerLink]="['/']"
          class="__logo-wrap">
          <vsh-logo
            [condensed]="_retracted || media.isActive('xs')"
            class="__logo">
          </vsh-logo>
        </a>
      </div>

      <div
        fxFlex="80"
        class="u-text--right">
        <div class="__actions">

          <div
            fxHide.lt-md
            *ngIf="(quickLinkMode$ | async) as quickLinkMode"
            class="__quick-link  __actions_item">

            <div
              class="__quick-link_item"
              [class.is-visible]="quickLinkMode === 'contact'">
              <a
                class="__actions_item-contact-item  __actions_item--full-button"
                [matTooltip]="'features.appBar.contactTooltip' | translate"
                [routerLink]="[ '/contact' ]"
                mat-button>

                <span class="__actions_item-contact-item  __actions_item-contact-item--faded"><span class="__actions_item-contact-country-code">+420</span> 246 035 835</span>

                <vsh-tilde color="grey" class="__actions_item-contact-tilde"></vsh-tilde>

                <span class="__actions_item-contact-item  __actions_item-contact-item--faded">{{ (activeLanguage$ | async) === 'cs' ? 'obchod@vshosting.cz' : 'sales@vshosting.eu' }}</span>

              </a>
            </div>

            <div
              class="__quick-link_item"
              [class.is-visible]="quickLinkMode === 'services'">
              <a
                class="__actions_item-contact-item  [ __actions_item--full-button  __actions_item--full-button-alt ]"
                [routerLink]="[ '/services/list' ]"
                mat-button>
                <span
                  class="__actions_item-contact-item--faded"
                  translate="app.solutionsAndServices">
                </span>
              </a>
            </div>

          </div>


          <span fxHide.lt-md class="__actions_item  __actions_separator"></span>

          <!-- <button
            type="button"
            class="__actions_item  __actions_item--faded"
            [matTooltip]="'features.appBar.searchTooltip' | translate"
            mat-icon-button>
            <mat-icon>search</mat-icon>
          </button> -->

          <a
            href="https://admin.vshosting.cloud"
            target="_blank"
            rel="nofollow"
            class="__actions_item  __actions_item--faded"
            [matTooltipShowDelay]="600"
            [matTooltip]="'features.appBar.loginTooltip' | translate"
            mat-icon-button>
            <mat-icon>lock</mat-icon>
          </a>

          <button
            type="button"
            class="[ __actions_item  __actions_item--faded ]  __language-button"
            [matMenuTriggerFor]="languageMenuRef"
            [matTooltipShowDelay]="600"
            [matTooltip]="'features.appBar.langChangeTooltip' | translate"
            mat-icon-button>
            <mat-icon class="__language_icon">language</mat-icon>
          </button>

          <button
            type="button"
            class="[ __actions_item  __actions_item--faded ]  __language-button"
            [matMenuTriggerFor]="currencyMenuRef"
            [matTooltipShowDelay]="600"
            [matTooltip]="'features.appBar.currencyChangeTooltip' | translate"
            mat-icon-button>
            <span class="__currency-icon">
              {{ (activeCurrency$ | async)?.name }}
            </span>
          </button>

          <span class="__actions_item  __actions_separator"></span>

          <!-- <button
            class="[ __actions_item  __actions_item--full-button ]  __services-button"
            mat-button>
            <mat-icon class="__services-icon">apps</mat-icon>
            <span translate="common.appBar.servicesButton"></span>
          </button> -->

          <!-- [matTooltip]="'common.appBar.menuTooltip' | translate" -->

          <button
            class="__actions_item  __actions_menu-item  __actions_item--full-button"
            mat-button
            type="button"
            angulartics2On="click"
            angularticsAction="Click"
            [angularticsProperties]="{
              'event_category': 'menu',
              'event_label':'https://vshosting.cz'
            }"
            (click)="menuClicked.emit()"
            color="alpha">
            <mat-icon class="__actions_menu-item-icon">menu</mat-icon>
            <span class="__actions_menu-text">menu</span>
          </button>

        </div>
      </div>

    </div>
  </vsh-wrap>
</div>

<div
  class="__bg"
  [ngClass]="{
    'is-retracted': _retracted,
    'is-hidden': _hidden
  }">
</div>

<mat-menu #languageMenuRef="matMenu">
  <button
    *ngFor="let item of languages"
    mat-menu-item
    type="button"
    (click)="onSetLanguage$.next(item.key)">
    {{ item.name }}
  </button>
</mat-menu>

<mat-menu #currencyMenuRef="matMenu">
  <button
    *ngFor="let item of currencies"
    mat-menu-item
    type="button"
    (click)="onSetCurrency$.next(item.key)">
    <span class="__currency-item">{{ item.key }}</span>
  </button>
</mat-menu>

<!--
  <ul class="c-inline-list  __list">
    <li class="__app-bar_list-item">
      <a
        class="__app-bar-item"
        [routerLink]="[ '/' ]">
        <span translate="common.appBar.homeLink"></span>
      </a>
    </li>
    <li class="__app-bar_list-item">
      <a
        class="__app-bar-item"
        [routerLink]="[ '/about' ]">
        <span translate="common.appBar.aboutLink"></span>
      </a>
    </li>
    <li class="__app-bar_list-item">
      <a
        class="__app-bar-item"
        [routerLink]="[ '/blog' ]">
        <span translate="common.appBar.blogLink"></span>
      </a>
    </li>
  </ul>
  -->
