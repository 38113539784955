import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vsh-browser',
  templateUrl: './browser.component.html',
  styleUrls: [ './browser.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserComponent {

}
