import {
  Component,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'vsh-address-box',
  templateUrl: './address-box.component.html',
  styleUrls: [ './address-box.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressBoxComponent {

}
