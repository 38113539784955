import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'vsh-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: [ './price-range.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceRangeComponent {
  @Input()
  priceFrom: string | number;

  @Input()
  priceTo: string | number;

  @Input()
  currency: string;

  @Input()
  inline = true;

  @Input()
  noTax = false;
}
