<div
  class="__layout"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="12px grid">

  <div
    fxFlex="none"
    fxFlex.xs="100"
    class="__layout-item">
    <vsh-person-badge
      class="__person-badge"
      [url]="personImage"
      [name]="name"
      [size]="size">
    </vsh-person-badge>
  </div>

  <div
    class="__layout-item"
    fxFlex="none"
    fxFlex.xs="100">

    <span class="__item_publication-text"><span [innerHTML]="'common.blogInfo.published' | translate"></span>&nbsp;</span>

    <span class="__item_date">{{ date | date: 'short' }}</span>

  </div>

</div>
