import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { map, filter, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ActiveRouteDataService {
  private _data = this._router
    .events
    .pipe(
      filter((e) => !!(e instanceof NavigationEnd)),
      map(() => {
        let activeRoute = this._router.routerState.snapshot.root;

        while (activeRoute.firstChild) {
          activeRoute = activeRoute.firstChild;
        }

        return activeRoute.data ? activeRoute.data : activeRoute.parent.data;
      }),
      shareReplay(1)
    );

  constructor(private _router: Router) {

  }

  getData$() {
    return this._data;
  }
}
