import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FourOFourPage } from './modules/pages/-four-o-four/four-o-four.page';

const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./modules/pages/+about/about.module').then(m => m.AboutModule),
    data: {
      animation: 'about',
      topBg: 'gamma',
      appBarQuickLinkMode: 'services',
      footerMode: 'full'
    }
  },
  {
    path: 'tech',
    loadChildren: () => import('./modules/pages/+tech/tech.module').then(m => m.TechModule),
    data: {
      animation: 'tech',
      topBg: 'grey',
      appBarQuickLinkMode: 'contact',
      footerMode: 'full'
    }
  },
  {
    path: 'services',
    loadChildren: () => import('./modules/pages/+services/services.module').then(m => m.ServicesModule),
    data: {
      animation: 'services',
      topBg: 'grey',
      appBarQuickLinkMode: 'services',
      footerMode: 'full'
    }
  },
  {
    path: 'work-with-us',
    loadChildren: () => import('./modules/pages/+work-with-us/work-with-us.module').then(m => m.WorkWithUsModule),
    data: {
      animation: 'work-with-us',
      topBg: 'grey',
      appBarQuickLinkMode: 'contact',
      footerMode: 'full'
    }
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/pages/+contact/contact.module').then(m => m.ContactModule),
    data: {
      animation: 'contact',
      topBg: 'grey',
      appBarQuickLinkMode: 'services',
      footerMode: 'compact'
    }
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/pages/+documents/documents.module').then(m => m.DocumentsModule),
    data: {
      animation: 'documents',
      topBg: 'page',
      appBarQuickLinkMode: 'services',
      footerMode: 'full'
    }
  },
  {
    path: 'references',
    loadChildren: () => import('./modules/pages/+references/references.module').then(m => m.ReferencesModule),
    data: {
      animation: 'references',
      topBg: 'page',
      appBarQuickLinkMode: 'services',
      footerMode: 'full'
    }
  },
  {
    path: 'references/:case',
    loadChildren: () => import('./modules/pages/+case-study/case-study.module').then(m => m.CaseStudyModule),
    data: {
      animation: 'case-study',
      topBg: 'grey',
      appBarQuickLinkMode: 'services',
      footerMode: 'full'
    }
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/pages/+blog/blog.module').then(m => m.BlogModule),
    data: {
      animation: 'blog',
      topBg: 'grey',
      appBarQuickLinkMode: 'services',
      footerMode: 'full'
    }
  },
  // {
  //   path: 'fear-calculator',
  //   loadChildren: () => import('./modules/pages/+fear-calculator/fear-calculator.module').then(m => m.FearCalculatorModule),
  //   data: {
  //     animation: 'fear-calculator',
  //     appBarQuickLinkMode: 'services',
  //     footerMode: 'full'
  //   }
  // }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }) ],
  exports: [ RouterModule ]
})
export class AppRoutesModule { }
