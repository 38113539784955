/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./person-badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../avatar/avatar.component.ngfactory";
import * as i3 from "../avatar/avatar.component";
import * as i4 from "./person-badge.component";
var styles_PersonBadgeComponent = [i0.styles];
var RenderType_PersonBadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonBadgeComponent, data: {} });
export { RenderType_PersonBadgeComponent as RenderType_PersonBadgeComponent };
export function View_PersonBadgeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-avatar", [["class", "__avatar"]], null, null, null, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(1, 49152, null, 0, i3.AvatarComponent, [], { size: [0, "size"], url: [1, "url"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; var currVal_1 = _co.url; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.name; _ck(_v, 3, 0, currVal_2); }); }
export function View_PersonBadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-person-badge", [], null, null, null, View_PersonBadgeComponent_0, RenderType_PersonBadgeComponent)), i1.ɵdid(1, 49152, null, 0, i4.PersonBadgeComponent, [], null, null)], null, null); }
var PersonBadgeComponentNgFactory = i1.ɵccf("vsh-person-badge", i4.PersonBadgeComponent, View_PersonBadgeComponent_Host_0, { size: "size", url: "url", name: "name" }, {}, []);
export { PersonBadgeComponentNgFactory as PersonBadgeComponentNgFactory };
