/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./address-box.component";
var styles_AddressBoxComponent = [i0.styles];
var RenderType_AddressBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressBoxComponent, data: {} });
export { RenderType_AddressBoxComponent as RenderType_AddressBoxComponent };
export function View_AddressBoxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "__title"], ["translate", "common.addressBox.placeTitle"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "address", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "p", [["class", "__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" VSHosting s.r.o."])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sodomkova 1579/5"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 102 00 Praha 15 "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "__map-button"], ["href", "https://goo.gl/maps/JPUNaoLSjpC2"], ["target", "__blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["translate", "common.addressBox.showOnMapButton"]], null, null, null, null, null)), i1.ɵdid(11, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "common.addressBox.placeTitle"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "common.addressBox.showOnMapButton"; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_AddressBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-address-box", [], null, null, null, View_AddressBoxComponent_0, RenderType_AddressBoxComponent)), i1.ɵdid(1, 49152, null, 0, i3.AddressBoxComponent, [], null, null)], null, null); }
var AddressBoxComponentNgFactory = i1.ɵccf("vsh-address-box", i3.AddressBoxComponent, View_AddressBoxComponent_Host_0, {}, {}, []);
export { AddressBoxComponentNgFactory as AddressBoxComponentNgFactory };
