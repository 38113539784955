import { QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { ScrollStateService } from '@app/services';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/scroll-state.service";
export class ScrollSceneService {
    constructor(_scrollState) {
        this._scrollState = _scrollState;
        this._scenes = [];
    }
    addScene({ offset, duration, items }) {
        const offset$ = new BehaviorSubject(offset);
        const duration$ = new BehaviorSubject(duration);
        const newScene = {
            offset,
            duration,
            offset$,
            duration$,
            items,
            id: `scene_${this._scenes.length + 1}`,
            progress$: this._createProgress$(duration$, offset$),
            subscription: undefined
        };
        // newScene.subscription = newScene.progress$.subscribe((p) => {
        //   newScene.items.forEach((ins) => {
        //     ins.seek(p);
        //   });
        // });
        this._scenes.push(newScene);
        return newScene;
    }
    updateItems(items, scene) {
        scene.items = items;
    }
    updateDuration(duration, scene) {
        scene.duration = duration;
        scene.duration$.next(duration);
    }
    updateOffset(offset, scene) {
        scene.offset = offset;
        scene.offset$.next(offset);
    }
    destroy() {
        this._scenes.forEach((s) => {
            if (s.subscription) {
                s.subscription.unsubscribe();
            }
        });
        this._scenes = [];
    }
    removeScene(scene) {
        if (scene && scene.subscription) {
            scene.subscription.unsubscribe();
        }
        this._scenes = this._scenes.filter((s) => scene && (s.id !== scene.id));
    }
    get scrollPosition() {
        return this._scrollState.getScrollPosition();
    }
    _createProgress$(duration$, offset$) {
        return this._scrollState.getScrolled$().pipe(withLatestFrom(duration$, offset$), map(([_, duration, offset]) => {
            const scrollTop = this._scrollState.getScrollPosition().top;
            const finalDuration = duration + offset;
            let progress = 0;
            if ((scrollTop >= offset)
                && (scrollTop <= finalDuration)) {
                progress = (scrollTop - offset) / (finalDuration - offset);
                return progress;
            }
            if ((scrollTop > finalDuration) && progress !== 1) {
                return 1;
            }
            if ((scrollTop <= 0) && progress !== 0) {
                return 0;
            }
            return 0;
        }), distinctUntilChanged());
    }
}
ScrollSceneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollSceneService_Factory() { return new ScrollSceneService(i0.ɵɵinject(i1.ScrollStateService)); }, token: ScrollSceneService, providedIn: "root" });
