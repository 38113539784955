import { Actions, ActionTypes } from './settings-core.action';
import { SettingsCoreState } from './settings-core.model';

export const initialState: SettingsCoreState = {
  language: undefined,
  currency: undefined
};

export function reducer(
  state = initialState,
  action: Actions
): SettingsCoreState {

  switch (action.type) {
    case ActionTypes.SetCurrency:
      return {
        ...state,
        currency: action.payload
      };

    case ActionTypes.SetLanguage:
      return {
        ...state,
        language: action.payload
      };
  }

  return state;
}
