import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  datacenter: {
    title: `Naše datacentrum hostuje přes 8 tisíc fyzických serverů.`,
    desc: `ServerPark je jedním z nejmodernějších a nejbezpečnějších datacenter.
      Všechny aktivní prvky mají <strong>zdvojenou redundanci</strong> a správu datacentra zajišťuje <strong>on-site tým 24/7</strong>.`
  },
  connectivity: {
    title: `Nejrychlejší možné připojení díky TIER 1 propojení.`,
    desc: `Přímé 100Gbps propojení s globálními <strong>TIER 1 operátory</strong> a členství v peeringových centrech
      zajišťuje maximální rychlost, dostupnost a spolehlivost připojení. Provozujeme <strong>vlastní CDN a
      klíčové optické trasy</strong>. Naše infrastruktura je postavena na <strong>špičkovém hardware</strong>.`
  },
  team: {
    title: `Kvalitní tým s 13 lety zkušeností.`,
    desc: `Tým 50+ profesionálů vám poskytne konzultaci vhodného řešení, zajistí návrh infrastruktury,
      správu s ohledem na spolehlivost a bezpečnost a následnou optimalizaci a škálování.
      Máme unikátně rychlé <strong>reakční doby v jednotkách minut.</strong>`
  },
  openButton: `Kliknutím otevřete v novém okně`
};

const SK = {
  datacenter: {
    title: `Naše datacentrum hostuje vyše 8 tisíc fyzických serverov.`,
    desc: `ServerPark je jedným z najmodernejších a najbezpečnejších datacentier. Všetky aktívne prvky majú
      <strong>zdvojenú redundanciu</strong> a správu datacentra zaisťuje <strong>on-site tím 24/7.</strong>.`
  },
  connectivity: {
    title: `Najrýchlejšie možné pripojenie vďaka TIER&nbsp;1 prepojeniu.`,
    desc: `Priame 100Gbps prepojenie s globálnymi <strong>TIER&nbsp;1 operátormi</strong>
      a členstvo v peeringových centrách zaisťuje maximálnu rýchlosť, dostupnosť a spoľahlivosť pripojenia. Prevádzkujeme
      <strong>vlastný CDN a kľúčové optické trasy</strong>. Naša infraštruktúra je postavená na <strong>špičkovom hardvéri</strong>.`
  },
  team: {
    title: `Kvalitný tím s 13 rokmi skúseností.`,
    desc: `Tím 50+ profesionálov Vám poskytne konzultáciu vhodného riešenia, zabezpečí návrh infraštruktúry,
      správu s ohľadom na spoľahlivosť, bezpečnosť a následnú optimalizáciu a škálovanie.
        Máme unikátne rýchle <strong>reakčné doby v jednotkách minút</strong>.`,
  },
  openButton: `Kliknutím otvoríte v novom okne`
};

const PL = {
  datacenter: {
    title: `Nasze centrum danych hostuje ponad 8000 serwerów fizycznych.`,
    desc: `ServerPark to jedno z najnowocześniejszych i najbezpieczniejszych centrów danych. Wszystkie aktywne elementy mają
      <strong>podwójną redundancję</strong>, zarządzanie centrum danych jest zapewniane przez<strong>zespół on-site 24/7</strong>.`
  },
  connectivity: {
    title: `Najszybsze możliwe połączenie dzięki połączeniu TIER&nbsp;1.`,
    desc: `Bezpośrednie połączenie 100 Gbps z globalnymi <strong>operatorami TIER&nbsp;1</strong>
      i członkostwo w centrum peeringowym zapewnia maksymalną szybkość, dostępność i niezawodność połączeń. Prowadzimy
      <strong>własne CDN i kluczowe trasy optyczne</strong>. Nasza infrastruktura zbudowana jest
      na <strong>najnowocześniejszym hardware</strong>.`
  },
  team: {
    title: `Wysokiej jakości zespół z 13-letnim doświadczeniem.`,
    desc: `Zespół 50+ specjalistów zapewni właściwą konsultację i odpowiednie rozwiązanie, zapewni projektowanie infrastruktury,
      zarządzanie w odniesieniu do niezawodności i bezpieczeństwa oraz optymalizację i
      skalowanie. Mamy wyjątkowo <strong>szybki czas reakcji w minutach</strong>.`,
  },
  openButton: `Kliknij, aby otworzyć w nowym oknie`
};

const EN = {
  datacenter: {
    title: `Our data center hosts over 8 thousand physical servers.`,
    desc: `ServerPark is one of the safest and most modern data centers. All active elements run with a
      <strong>double redundancy</strong> and the data center is managed by a <strong>team that is on site 24/7</strong>.`
  },
  connectivity: {
    title: `Fastest connection possible thanks to TIER&nbsp;1.`,
    desc: `A direct 100 Gbps connection with global TIER 1 operators and membership in several peering centers ensures
      maximum connection speed, availability, and reliability. We operate our <strong>own key optical paths and CDN</strong>.
      Our infrastructure is built on <strong>top of the line hardware</strong>.`
  },
  team: {
    title: `Great team with over 13 years of experience.`,
    desc: `Our team of 50+ professionals will consult you on the best solution, provide an infrastructural design, management
      with respect to reliability and security, and ensuing optimization and scaling. We have a uniquely short reaction time
      (in a matter of minutes).`,
  },
  openButton: `Click to open in a new window`
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class AboutPopTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        aboutPop: CS
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        aboutPop: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        aboutPop: PL
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        aboutPop: EN
      }
    }, true);

  }
}
