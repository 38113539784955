import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  items: {
    services: 'Služby a řešení',
    about: 'O nás',
    contact: 'Kontakt',
    documents: 'Dokumenty ke stažení',
    technical: 'Technické zázemí',
    blog: 'Blog',
    references: 'Reference a Případové studie',
    hiring: 'Pracujte s námi',
    clientZone: 'Klientská zóna',
    fearCalculator: 'Kalkulačka strachu'
  }
};

const SK = {
  items: {
    services: 'Služby a riešenia',
    about: 'O nás',
    contact: 'Kontakt',
    documents: 'Dokumenty k stiahnutiu',
    technical: 'Technické zázemie',
    blog: 'Blog',
    references: 'Referencie a Prípadové štúdie',
    hiring: 'Pracujte s nami',
    clientZone: 'Klientská zóna',
    fearCalculator: 'kalkulačka strachu'
  }
};

const PL = {
  items: {
    services: 'Usługi i rozwiązania',
    about: 'O nas',
    contact: 'Kontakt',
    documents: 'Dokumenty do pobrania',
    technical: 'Zaplecze techniczne',
    blog: 'Blog',
    references: 'Odniesienie i Studium przypadku',
    hiring: 'Pracuj z nami',
    clientZone: 'Client zone',
    fearCalculator: 'kalkulator strachu'
  }
};

const EN = {
  items: {
    services: 'Services and Solutions',
    about: 'About Us',
    contact: 'Contact',
    documents: 'Documents for download',
    technical: 'Technical background',
    blog: 'Blog',
    references: 'References & Case Studies',
    hiring: 'Join Our Team',
    clientZone: 'Client zone',
    fearCalculator: 'Fear calculator'
  }
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class MenuTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      features: {
        menu: CS
      }
    }, true);

    this._translate.setTranslation('sk', {
      features: {
        menu: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      features: {
        menu: PL
      }
    }, true);

    this._translate.setTranslation('en', {
      features: {
        menu: EN
      }
    }, true);

  }
}
