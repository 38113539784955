import { Component, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  group
} from '@angular/animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router, NavigationStart } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from '@ngrx/store';
import { getDialogState, DialogClose } from '@zerops/fe/dialog';
import {
  map,
  filter,
  distinctUntilChanged,
  skip,
  withLatestFrom,
  switchMap,
  tap,
  catchError,
  takeUntil
} from 'rxjs/operators';
import { AppState } from '@app/models';
import { Subject, of, merge } from 'rxjs';
import nl2br from 'nl2br';
import { environment } from 'environments/environment';
import { IntercomApi } from '@app/core/intercom';
import { selectSettingsCoreCurrency } from '@app/core/settings-core';

@Component({
  selector: 'vsh-contact-pop',
  templateUrl: './contact-pop.component.html',
  styleUrls: [ './contact-pop.component.scss' ],
  animations: [
    trigger('open', [
      transition(
        ':enter',
        [
          group([
            query('.__overlay', [
              style({
                opacity: 0
              })
            ]),
            query('.__close-button-wrap, .__progress-wrap', [
              style({
                opacity: 0
              })
            ]),
            query('.__content', [
              style({
                opacity: 0,
                transform: 'translate3d(0, -4px, 0)'
              })
            ])
          ]),
          query('.__overlay', [
            animate(300, style({
              opacity: 1
            }))
          ]),
          group([
            query('.__close-button-wrap, .__progress-wrap', [
              animate(300, style({
                opacity: 1
              }))
            ]),
            query('.__content', [
              animate(400, style({
                opacity: 1,
                transform: 'translate3d(0, 0, 0)'
              }))
            ])
          ])
        ]
      ),
      transition(
        ':leave',
        [
          group([
            query('.__content', [
              animate(300, style({
                opacity: 0,
                transform: 'translate3d(0, 4px, 0)'
              }))
            ]),
            query('.__close-button-wrap, .__progress-wrap', [
              animate(300, style({
                opacity: 0
              }))
            ])
          ]),
          query('.__overlay', [
            animate(400, style({
              opacity: 0
            }))
          ])
        ]
      )
    ])
  ]
})
export class ContactPopComponent {
  dialogKey = 'vsh:contact';
  defaultHiddenText = true;
  sending = false;
  form = new FormGroup({
    mail: new FormControl('', [ Validators.required ]),
    text: new FormControl('')
  });
  selectedAdditionalItems = [];

  @ViewChild('chatSnackTemplateRef', { static: true })
  chatSnackTemplateRef: TemplateRef<any>;

  @ViewChild('successSnackTemplateRef', { static: true })
  successSnackTemplateRef: TemplateRef<any>;

  @ViewChild('failSnackTemplateRef', { static: true })
  failSnackTemplateRef: TemplateRef<any>;

  dialog$ = this._store.pipe(select(getDialogState(this.dialogKey)));
  state$ = this.dialog$.pipe(map((state) => state.state));
  meta$ = this.dialog$.pipe(map((d) => d.meta));
  currency$ = this._store.pipe(select(selectSettingsCoreCurrency));
  onSend$ = new Subject<void>();
  onChat$ = new Subject<void>();

  private _sendAction$ = this.onSend$.pipe(
    withLatestFrom(this.meta$),
    tap(() => this.sending = true),
    switchMap(([ _, meta ]) => {
      return this._http
        .post(environment.mailedEndpoint, { body: nl2br(this.transformedFullText(meta.meta, meta.type)) })
        .pipe(
          takeUntil(this.state$.pipe(filter((s) => s === false))),
          map(() => 'success'),
          catchError(() => of<'error'>('error')),
        );
      }
    ),
    tap(() => this.sending = false),
    map((result) => {
      if (result === 'success') {
        return new DialogClose(this.dialogKey);
      } else {
        this._snack.openFromTemplate(
          this.failSnackTemplateRef, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2500
          }
        );
      }
    }),
    filter((a) => !!a),
    tap(() => {
      this._snack.openFromTemplate(
        this.successSnackTemplateRef, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 5000
        }
      );
    })
  );

  private _chatAction$ = this.onChat$.pipe(
    withLatestFrom(this.meta$),
    tap(([ _, meta ]) => {
      if (this.form.value && this.form.value.mail) {
        if (this.form.value.mail.includes('@')) {
          this._intercom.update({ email: this.form.value.mail });
        } else {
          this._intercom.update({ phone: this.form.value.mail });
        }
      }

      this._intercom.showNewMessage(this.transformedFullText(meta.meta, meta.type));

      this._snack.openFromTemplate(
        this.chatSnackTemplateRef, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 5000
        }
      );

    }),
    map(() => new DialogClose(this.dialogKey))
  );

  constructor(
    private _store: Store<AppState>,
    private _snack: MatSnackBar,
    private _router: Router,
    private _http: HttpClient,
    private _intercom: IntercomApi
  ) {

    this.state$
      .pipe(
        distinctUntilChanged(),
        filter((a) => a === false),
        skip(1)
      )
      .subscribe(() => {
        this.form.reset();
        this.selectedAdditionalItems = [];
        this.sending = false;
        this.defaultHiddenText = true;
      });

    this._router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this._store.dispatch(new DialogClose(this.dialogKey));
      }
    });

    merge(
      this._sendAction$,
      this._chatAction$
    ).subscribe(this._store);

  }

  onSubmit(e: Event) {
    e.preventDefault();

    if (this.form.valid) {
      this.onSend$.next();
    }
  }

  getMetaAsText(meta: any, type: string) {
    if (meta) {
      // contact, inquiry
      if (type !== 'basket') {
        let variant = '';
        let price = '';
        let additional = '';

        if (meta.variant) {
          variant = `\n~\nvariant: ${meta.variant.join('-')}`;
        }

        if (meta.price) {
          price = `\n~\nprice: ${meta.price} / ${meta.currency}`;
        }

        if (this.selectedAdditionalItems.length) {
          additional = `\n~\nadditional: ${this.selectedAdditionalItems.map((itm) => itm.name).join(', ')}`;
        }

        return `\n~\nservice: ${meta.service}${variant}${price}${additional}`;
      // basket
      } else {
        let configuration = '';
        if (meta.basket) {
          configuration = `\n\nconfigurations:\n${meta.basket.reduce((s: string, itm: any) => {
            s = s + `${itm.name} - ${itm.desc}\n\n`;

            return s;
          }, '')}\n`;
        }

        return `\n~\nservice: ${meta.service}${configuration}`;
      }
    } else {
      return '';
    }

  }

  transformedFullText(meta: any, type: string) {
    const metaText = this.getMetaAsText(meta, type);

    let formText = '';
    if (this.form.value.text) {
      formText = `${this.form.value.text}\n~\n`;
    }

    const text = `${formText}contact: ${this.form.value.mail}${metaText}`;

    return text;
  }

  selectAdditionalItem(item: any) {
    this.selectedAdditionalItems = [
      ...this.selectedAdditionalItems,
      item
    ];
  }

  unselectAdditionalItem(item: any) {
    this.selectedAdditionalItems = this.selectedAdditionalItems.filter((itm) => itm.name !== item.name);
  }

  toggleAdditionalItem(event: MatCheckboxChange, item: any) {
    if (!event.checked) {
      this.unselectAdditionalItem(item);
    } else {
      this.selectAdditionalItem(item);
    }

  }

}
