import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '@app/common/pipes';
import { CaseStudyItemComponent } from './case-study-item.component';
import { CaseStudyItemTranslationsModule } from './case-study-item.translations';
import { TildeModule } from '../tilde';
import { WrapModule } from '../wrap';

@NgModule({
  declarations: [ CaseStudyItemComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatCardModule,
    TildeModule,
    PipesModule,
    CaseStudyItemTranslationsModule,
    WrapModule
  ],
  exports: [ CaseStudyItemComponent ]
})
export class CaseStudyItemModule { }
