import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { HeroModule } from '@app/common/hero';
import { TagModule } from '@app/common/tag';
import { FlexModule } from '@app/core/flex';
import { PersonBadgeModule } from '@app/common/person-badge';
import { BlogInfoModule } from '@app/common/blog-info';
import { BlogStripComponent } from './blog-strip.component';

@NgModule({
  declarations: [ BlogStripComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    FlexModule,
    HeroModule,
    TagModule,
    PersonBadgeModule,
    BlogInfoModule
  ],
  exports: [ BlogStripComponent ]
})
export class BlogStripModule { }
