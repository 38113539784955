import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceEntity } from './services-core.model';

@Injectable({ providedIn: 'root' })
export class ServicesCoreApi {
  constructor(private _http: HttpClient) { }

  list$() {
    return this._http.get<ServiceEntity<any>[]>('/assets/services.json');
  }
}
