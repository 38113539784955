import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './home.page';
import { FourOFourPage } from '../-four-o-four/four-o-four.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
    data: {
      animation: 'home',
      topBg: 'page',
      appBarQuickLinkMode: 'contact',
      footerMode: 'full'
    }
  },
  {
    path: '**',
    component: FourOFourPage,
    data: {
      animation: '404',
      topBg: 'page',
      appBarQuickLinkMode: 'contact',
      footerMode: 'full'
    }
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ]
})
export class HomeRoutesModule { }
