import { NgModule } from '@angular/core';
import { CableOneComponent } from './cable-one.component';
import { CableTwoComponent } from './cable-two.component';

@NgModule({
  declarations: [ CableOneComponent, CableTwoComponent ],
  exports: [ CableOneComponent, CableTwoComponent ]
})
export class CablesModule {

}
