<!-- datacenter -->
<vsh-hero
  class="__item  __datacenter"
  [class.__datacenter--lt-lg]="media.isActive('lt-lg')"
  [class.__datacenter--lt-md]="media.isActive('lt-md')"
  [animationOffset]="'-100vh'"
  [images]="[
    { url: '/assets/datacenter5.jpg', id: '1' },
    { url: '/assets/datacenter6.jpg', id: '2' },
    { url: '/assets/datacenter2.jpg', id: '3' },
    { url: '/assets/datacenter3.jpg', id: '4' },
    { url: '/assets/datacenter4.jpg', id: '5' },
    { url: '/assets/datacenter.jpg', id: '6' }
  ]"
  [minHeight]="'500px'"
  [contentPadding]="media.isActive('lt-sm') ? '36px' : '48px'"
  [contentBackground]="media.isActive('lt-lg') ? 'light' : undefined"
  [contentOffsetY]="media.isActive('lt-lg') ? '-250px' : 0"
  [mode]="media.isActive('lt-lg') ? 'vertical' : 'horizontal'"
  [contentAlign]="media.isActive('lt-lg') ? 'left' : 'right'">
  <div class="__item_content  __item_content--no-padding">

    <h3
      class="__item_data  u-color--identityGammaColor"
      [innerHTML]="'common.aboutStrip.first.key' | translate">
    </h3>

    <h2
      class="__item_title"
      translate="common.aboutStrip.first.title">
    </h2>

    <p
      class="__item_desc"
      [innerHTML]="'common.aboutStrip.first.desc' | translate">
    </p>

    <div class="__item_buttons">

      <a
        [routerLink]="[ '/tech' ]"
        mat-stroked-button
        color="alpha"
        class="[ c-button  c-button--dense  c-button--gamma ]  [ __item_button  __item_button--main ]"
        [class.c-button--full]="media.isActive('lt-md')">
        <span translate="common.aboutStrip.first.moreButton"></span>
      </a>

      <button
        mat-button
        pageScroll
        *ngIf="hideSecondaryButton === false"
        href="#services"
        class="[ c-button  c-button--dense ]  [ __item_button  __item_button--secondary ]"
        [class.c-button--full]="media.isActive('lt-md')">
        <span translate="common.aboutStrip.nextButton"></span> <mat-icon>expand_more</mat-icon>
      </button>

    </div>

  </div>
</vsh-hero>

<!-- connectivity -->
<div class="__connectivity-wrapper">

  <div class="__about-tilde-wrap">
    <vsh-tilde class="__about-tilde" [color]="'alpha'"></vsh-tilde>
  </div>


  <img src="/assets/world.svg" class="__connectivity-wrapper_image" />

  <vsh-hero
    class="__item  __connectivity-item"
    [class.__connectivity-item--lt-sm]="media.isActive('lt-sm')"
    [class.__connectivity-item--lt-md]="media.isActive('lt-md')"
    [class.__item-vertical--lt-md]="media.isActive('lt-md')"
    [animationOffset]="'-70vh'"
    [minHeight]="media.isActive('lt-sm') ? '230px' : '210px'"
    disableShadow
    [leftSize]="55"
    [rightSize]="45"
    [contentPadding]="media.isActive('lt-sm') ? '36px' : '48px'"
    [mode]="media.isActive('lt-md') ? 'vertical' : 'horizontal'"
    [contentAlign]="'left'"
    [contentPosition]="'end'"
    [mode]="'horizontal'">

    <div
      *vshHeroContent
      class="__item_content"
      [class.__item_content--no-padding]="media.isActive('lt-sm')"
      [class.__connectivity-content--lt-md]="media.isActive('lt-sm')">

      <h3
        class="__item_data  u-color--identityGammaColor"
        [innerHTML]="'common.aboutStrip.second.key' | translate">
      </h3>

      <h2
        class="__item_title"
        translate="common.aboutStrip.second.title">
      </h2>

      <p
        class="__item_desc"
        [innerHTML]="'common.aboutStrip.second.desc' | translate">
      </p>

    </div>

    <div
      *vshHeroFeature
      class="__connectivity-wrap">
      <div
        class="__connectivity-inner"
        [class.__connectivity-inner--lt-md]="media.isActive('lt-md')">
        <div
          fxLayoutAlign="center center"
          fxLayoutGap="24px grid"
          fxLayoutGap.lt-md="0px grid"
          fxLayout="row wrap">

          <div fxFlex="50">
            <img class="__connectivity-logo" src="/assets/nix_hor.svg" />
          </div>

          <div fxFlex="50">
            <img class="__connectivity-logo" src="/assets/nixsk_hor.svg" />
          </div>

          <div fxFlex="50">
            <img class="__connectivity-logo" src="/assets/telia.svg" />
          </div>

          <div fxFlex="50">
            <img class="__connectivity-logo" src="/assets/cogent.svg" />
          </div>

        </div>
      </div>
    </div>

  </vsh-hero>

</div>

<div class="__about-tilde-wrap  __about-tilde-wrap--last">
  <vsh-tilde class="__about-tilde" [color]="'alpha'"></vsh-tilde>
</div>

<!-- team / about -->
<vsh-hero
  class="__item  __about-team-item"
  [class.__item-vertical--lt-md]="media.isActive('lt-md')"
  [class.__about-team-item--lt-sm]="media.isActive('lt-sm')"
  [animationOffset]="'-70vh'"
  [minHeight]="'550px'"
  [contentMaxWidth]="'560px'"
  [contentOffsetY]="'-250px'"
  [contentBackground]="'light'"
  [contentPadding]="'24px'"
  [contentAlign]="'left'"
  [leftSize]="55"
  [rightSize]="45"
  [image]="'/assets/team.jpg'"
  [mode]="'vertical'">

  <div
    class="__item_content"
    [class.__item_content--no-padding]="media.isActive('lt-sm')">

    <h3
      class="__item_data  u-color--identityGammaColor"
      [innerHTML]="'common.aboutStrip.third.key' | translate">
    </h3>

    <h2
      class="__item_title"
      translate="common.aboutStrip.third.title">
    </h2>

    <p
      class="__item_desc"
      [innerHTML]="'common.aboutStrip.third.desc' | translate">
    </p>

    <div class="__item_buttons">

      <a
        [routerLink]="[ '/about' ]"
        mat-stroked-button
        color="alpha"
        class="[ c-button  c-button--dense  c-button--gamma ]  [ __item_button  __item_button--main ]"
        [class.c-button--full]="media.isActive('lt-md')">
        <span translate="common.aboutStrip.third.moreButton"></span>
      </a>

      <button
        mat-button
        pageScroll
        href="#services"
        *ngIf="hideSecondaryButton === false"
        class="[ c-button  c-button--dense ]  [ __item_button  __item_button--secondary ]"
        [class.c-button--full]="media.isActive('lt-md')">
        <span translate="common.aboutStrip.nextButton"></span> <mat-icon>expand_more</mat-icon>
      </button>

    </div>

  </div>

</vsh-hero>
