import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimItemsComponent } from './claim-items.component';

@NgModule({
  declarations: [ ClaimItemsComponent ],
  imports: [ CommonModule ],
  exports: [ ClaimItemsComponent ]
})
export class ClaimItemsModule { }
