import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vsh-tilde',
  templateUrl: './tilde.component.html',
  styleUrls: [ './tilde.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TildeComponent {
  @Input()
  color: 'alpha' | 'beta' | 'gamma' | 'grey' | 'black';
}
