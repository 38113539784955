import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SocialComponent } from '@app/common/social/social.component';

@NgModule({
  declarations: [ SocialComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ SocialComponent ]
})
export class SocialModule { }
