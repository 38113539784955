import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MediaObserver } from '@angular/flex-layout';
import { Platform } from '@angular/cdk/platform';
import { DialogOpen } from '@zerops/fe/dialog';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AppState } from '@app/models';
import {
  selectServicesCoreAll,
  getLowHighPrice
} from '@app/core/services-core';
import {
  selectSettingsCoreLanguage,
  selectSettingsCoreCurrency
} from '@app/core/settings-core';

@Component({
  selector: 'vsh-services',
  templateUrl: './services.component.html',
  styleUrls: [ './services.component.scss' ]
})
export class ServicesComponent {
  // # Data
  // -- angular
  @Input()
  expanded: boolean;

  // -- sync
  activeTags = [];
  possibleTags = [ 'managed', 'server', 'cloud', 'dedicated', 'mail', 'database' ];
  servicesEventTags = {
    'managed-server': {
      main: {
        'event_category': 'managed server/cloud',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'managed server/cloud-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'managed server/cloud-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'managed-cluster': {
      main: {
        'event_category': 'managed cluster',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'managed cluster-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'managed cluster-detail služby',
        'event_label': 'https://vshosting.cz/',
      }
    },
    'dedicated-server': {
      main: {
        'event_category': 'dedicated server',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'dedicated server-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'dedicated server-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'managed-kubernetes': {
      main: {
        'event_category': 'platform for kubernetes',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'platform for kubernetes-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'platform for kubernetes-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'private-cloud': {
      main: {
        'event_category': 'private cloud',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'private cloud-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'private cloud-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'managed-database': {
      main: {
        'event_category': 'managed database',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'managed database-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'managed database-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'cloud-mail': {
      main: {
        'event_category': 'cloudmail',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'cloudmail-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'cloudmail-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'bulk-mailing': {
      main: {
        'event_category': 'hromadný mailing',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'hromadný mailing-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'hromadný mailing-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    },
    'cdn': {
      main: {
        'event_category': 'cdn',
        'event_label': 'https://vshosting.cz/'
      },
      secondary: {
        'event_category': 'cdn-detail služby',
        'event_label': 'https://vshosting.cz/'
      },
      support: {
        'event_category': 'cdn-detail služby',
        'event_label': 'https://vshosting.cz/'
      }
    }
  };

  // -- async
  language$ = this._store.pipe(select(selectSettingsCoreLanguage));
  currency$ = this._store.pipe(select(selectSettingsCoreCurrency));
  servicesBase$ = combineLatest(
    this._store.pipe(select(selectServicesCoreAll)),
    this.language$
  ).pipe(map(([ data, lang ]) => data.map((itm) => ({
    ...itm,
    // only name and shortdesc translated needed
    name: itm.name[lang],
    shortDesc: itm.shortDesc[lang]
  }))));
  services$ = combineLatest(
    this.servicesBase$,
    this.currency$
  ).pipe(
    map(([ data, currency ]) => data.map((itm) => {
      const currVariants = itm.variants.map((va) => ({
        ...va,
        price: va.price[currency]
      }));

      let fromToPrice = getLowHighPrice(currVariants);

      if (itm.id === 'private-cloud') {
        fromToPrice = undefined;

        // const otherPrices = itm.otherPrices[currency];

        // if (otherPrices) {
        //   fromToPrice = {
        //     from: (fromToPrice.from * 2) + otherPrices[0] + otherPrices[1],
        //     to: (fromToPrice.to * 12) + otherPrices[0] + (otherPrices[1] * 4)
        //   };
        // }
      }

      return {
        ...itm,
        variants: currVariants,
        fromToPrice
      };
    }))
  );

  constructor(
    public platform: Platform,
    public media: MediaObserver,
    private _store: Store<AppState>,
    private _cdRef: ChangeDetectorRef
  ) { }

  addActiveTag(tag: string) {
    this.activeTags = [ ...this.activeTags, tag ];
    this._cdRef.markForCheck();
  }

  removeActiveTag(tag: string) {
    this.activeTags = this.activeTags.filter((t) => t !== tag);
    this._cdRef.markForCheck();
  }

  toggleActiveTag(tag: string) {
    if (this.activeTags.includes(tag)) {
      this.removeActiveTag(tag);
    } else {
      this.addActiveTag(tag);
    }
    this._cdRef.markForCheck();
  }

  tagIncludes(tags: string[]) {
    return this.activeTags.every((t) => tags.includes(t));
  }

  onContact(service: string) {
    this._store.dispatch(
      new DialogOpen(
        'vsh:contact',
        {
          type: 'inquiry',
          meta: { service }
        }
      )
    );
  }

  trackBy(_, item: any) {
    return item;
  }
}
