import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  tap,
  delay
} from 'rxjs/operators';
import {
  ListRequest,
  ActionTypes,
  ListLocalSuccess,
  ListFail,
  DetailRequest,
  DetailLocalSuccess,
  DetailFail,
  TagsRequest,
  TagsLocalSuccess,
  TagsFail
} from './blog-core.action';
import { BlogCoreApi } from './blog-core.api';
import { AppState } from 'app/models';
import { Store, select } from '@ngrx/store';
import { selectSettingsCoreLanguage } from '@app/core/settings-core';
import { environment } from 'environments/environment';

const LanguageEnum = environment.blogTranslationCategories;

@Injectable()
export class BlogCoreEffect {

  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    delay(0),
    withLatestFrom(this._store.pipe(select(selectSettingsCoreLanguage))),
    switchMap(([ { payload }, language ]) => this._api
      .list$({
        ...payload,
        language: LanguageEnum[language]
      })
      .pipe(
        map((data) => new ListLocalSuccess(
          data.body,
          parseInt(data.headers.get('X-WP-Total'), 10)
        )),
        catchError((data) => of(new ListFail(data)))
      )
    )
  );

  @Effect()
  private _onTagsRequest$ = this._actions$.pipe(
    ofType<TagsRequest>(ActionTypes.TagsRequest),
    switchMap(() => this._api
      .tags$()
      .pipe(
        map((data) => new TagsLocalSuccess(data)),
        catchError((data) => of(new TagsFail(data)))
      )
    )
  );

  @Effect()
  private _onDetailRequest$ = this._actions$.pipe(
    ofType<DetailRequest>(ActionTypes.DetailRequest),
    switchMap(({ payload }) => this._api
      .detail$(payload)
      .pipe(
        map((data) => new DetailLocalSuccess(data)),
        catchError((data) => of(new DetailFail(data)))
      )
    )
  );

  // @Effect()
  // private _onDetailLocalSuccessLoadMedia$ = this._actions$.pipe(
  //   ofType<DetailLocalSuccess>(ActionTypes.DetailLocalSuccess),
  //   filter(({ payload }) => !!payload.featured_media),
  //   map(({ payload }) => payload.featured_media),
  //   map((id) => new WpCoreLoadMediaRequest([ id ]))
  // );

  constructor(
    private _actions$: Actions,
    private _api: BlogCoreApi,
    private _store: Store<AppState>
  ) { }
}
