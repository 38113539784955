import {
  Component,
  NgZone,
  HostBinding,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { MediaObserver } from '@angular/flex-layout';
import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { ActiveRouteDataService } from '@app/services';
import { AppState } from '@app/models';
import { selectSettingsCoreCurrency, SetLanguage, selectSettingsCoreLanguage } from '@app/core/settings-core';
import { SetCurrency } from '@app/core/settings-core';

const getScrollTop = () => {
  if (typeof pageYOffset !== 'undefined') { return pageYOffset; }

  const B = document.body;
  let D = document.documentElement;
  D = (D.clientHeight) ? D : B;

  return D.scrollTop;
};

@Component({
  selector: 'vsh-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: [ './app-bar.component.scss' ]
})
export class AppBarComponent {
  // # Event Streams
  onSetCurrency$ = new Subject<string>();
  onSetLanguage$ = new Subject<string>();

  // # Data
  // -- angular
  @Input()
  menuOpen: boolean;

  @HostBinding('class.is-retracted')
  get isRetractedClass() {
    return this._retracted;
  }

  @HostBinding('class.is-hidden')
  get isHiddenClass() {
    return this._hidden;
  }

  @HostBinding('class.has-menu-open')
  get hasMenuOpenClass() {
    return this.menuOpen;
  }

  @HostBinding('class.is-theme--page')
  get isThemePageClass() {
    return this.theme === 'page';
  }

  @HostBinding('class.is-theme--light')
  get isThemeLightClass() {
    return this.theme === 'light';
  }

  @HostBinding('class.is-theme--grey')
  get isThemeGreyClass() {
    return this.theme === 'grey';
  }

  @HostBinding('class.is-theme--gamma')
  get isThemeGammaClass() {
    return this.theme === 'gamma';
  }

  @Output()
  menuClicked = new EventEmitter<void>();

  // -- sync
  currencies = [
    {
      key: 'czk',
      name: 'CZK'
    },
    {
      key: 'eur',
      name: 'EUR'
    },
    {
      key: 'pln',
      name: 'PLN'
    }
  ];
  languages = [
    {
      key: 'en',
      name: 'English'
    },
    {
      key: 'cs',
      name: 'Česky'
    },
    {
      key: 'sk',
      name: 'Slovensky'
    },
    {
      key: 'pl',
      name: 'Polsky'
    }
  ];

  _retracted = false;
  _hidden = false;
  theme = 'page';

  // -- async
  quickLinkMode$ = this._activeRouteData
    .getData$()
    .pipe(
      map((data) => data ? data.appBarQuickLinkMode : 'contact'),
      distinctUntilChanged()
    );
  activeCurrency$ = this._store.pipe(
    select(selectSettingsCoreCurrency),
    map((curr) => this.currencies.find((c) => c.key === curr))
  );
  activeLanguage$ = this._store.pipe(select(selectSettingsCoreLanguage));

  // Action Streams
  private _setCurrencyAction$ = this.onSetCurrency$.pipe(
    map((curr) => new SetCurrency(curr))
  );
  private _setLanguageAction$ = this.onSetLanguage$.pipe(
    map((lang) => new SetLanguage(lang))
  );

  private readonly _retractTriggerHeight = 36;
  private readonly _hideTriggerHeight = 900;
  private _lastScrollPosition = 0;

  constructor(
    public media: MediaObserver,
    private _activeRouteData: ActiveRouteDataService,
    private _scrollDispatcher: ScrollDispatcher,
    private _zone: NgZone,
    private _store: Store<AppState>
  ) {

    this._activeRouteData
      .getData$()
      .subscribe((data) => {
        if (data.topBg) {
          this.theme = data.topBg;
        } else {
          this.theme = 'page';
        }
      });

    merge(
      this._setCurrencyAction$,
      this._setLanguageAction$
    ).subscribe(this._store);

    this._scrollDispatcher
      .scrolled()
      .subscribe(() => {
        const scrollTop = getScrollTop();

        if (!this._retracted && scrollTop > this._retractTriggerHeight) {
          this._zone.run(() => {
            this._retracted = true;
          });
        }

        if (this._retracted && scrollTop < this._retractTriggerHeight) {
          this._zone.run(() => {
            this._retracted = false;
          });
        }

        if (!this._hidden && scrollTop > this._hideTriggerHeight && scrollTop > this._lastScrollPosition) {
          this._zone.run(() => {
            this._hidden = true;
          });
        }

        if (this._hidden && scrollTop < this._lastScrollPosition) {
          this._zone.run(() => {
            this._hidden = false;
          });
        }

        this._lastScrollPosition = scrollTop;

      });

  }
}
