<ng-container *ngIf="pagedData && pagedData.length">
  <div class="__wrap">

    <div
      *ngIf="pageSize === 1"
      class="__mobile_controls-wrap">

      <div
        *ngIf="currentPage > 0"
        (click)="paginate(currentPage - 1)"
        class="__mobile_control--left">
        <button
          mat-icon-button
          type="button">
          <mat-icon class="__arrow">chevron_left</mat-icon>
        </button>
      </div>

      <div
        *ngIf="maxPageSize !== currentPage + 1"
        (click)="paginate(currentPage + 1)"
        class="__mobile_control--right">
        <button
          mat-icon-button
          type="button">
          <mat-icon class="__arrow">chevron_right</mat-icon>
        </button>
      </div>

    </div>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="center stretch"
      fxLayoutGap="24px grid">
      <vsh-case-study-item
        *ngFor="let item of pagedData"
        [data]="item">
      </vsh-case-study-item>
    </div>

    <div
      *ngIf="maxPageSize !== currentPage + 1 && pageSize !== 1"
      (click)="paginate(currentPage + 1)"
      class="__control __control--right">
      <button
        mat-icon-button
        type="button">
        <mat-icon class="__arrow">chevron_right</mat-icon>
      </button>
    </div>

    <div
      *ngIf="currentPage > 0 && pageSize !== 1"
      (click)="paginate(currentPage - 1)"
      class="__control __control--left">
      <button
        mat-icon-button
        type="button">
        <mat-icon class="__arrow">chevron_left</mat-icon>
      </button>
    </div>

  </div>
</ng-container>

