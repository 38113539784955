<div
  class="__content"
  (@slideAnimation.start)="closing = true"
  (@slideAnimation.done)="closing = false"
  [@slideAnimation]="open ? 'open' : 'closed'">

  <div
    class="__header"
    fxLayout
    fxLayoutAlign="center center">

    <div fxFlex="50">
      <vsh-tilde color="black" class="__logo-tilde  a-slide-animation"></vsh-tilde>
    </div>

    <div fxFlex="50">
      <div class="u-text--right">
        <button
          class="__close-button"
          mat-icon-button
          type="button"
          (click)="onClose()">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>

  </div>

  <!-- <span class="__break"></span> -->

  <mat-nav-list disableRipple class="__nav  __nav--main">
    <a
      *ngFor="let items of serviceItems"
      class="__item  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="serviceEventIdsMap[items.id]"
      (click)="onClose()"
      [routerLink]="items.link"
      mat-list-item>
      {{ items.name }}
    </a>
  </mat-nav-list>

  <vsh-break class="__break  a-slide-animation"></vsh-break>

  <mat-nav-list disableRipple dense class="__nav  __nav--secondary  __nav--dense">

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'služby a řešení-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/services' ]"
      mat-list-item>
      <span
        class="u-color--identityGammaColor"
        translate="features.menu.items.services">
      </span>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'reference-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/references' ]"
      mat-list-item>
      <span
        class="u-color--identityGammaColor"
        translate="features.menu.items.references">
      </span>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'o nás-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/about' ]"
      mat-list-item>
      <span translate="features.menu.items.about"></span>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'technické zázemí-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/tech' ]"
      mat-list-item>
      <span translate="features.menu.items.technical"></span>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'blog-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/blog', 'list' ]"
      mat-list-item>
      <span translate="features.menu.items.blog"></span>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'kontakt-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/contact' ]"
      mat-list-item>
      <span translate="features.menu.items.contact"></span>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'documents-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/documents' ]"
      mat-list-item>
      <span translate="features.menu.items.documents"></span>
    </a>

    <!-- <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'kalkulačka strachu-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      [routerLink]="[ '/fear-calculator' ]"
      mat-list-item>
      <span translate="features.menu.items.fearCalculator"></span>
    </a> -->

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      angulartics2On="click"
      angularticsAction="Click"
      [angularticsProperties]="{
        'event_category': 'pracujte s námi-menu',
        'event_label': 'https://vshosting.cz'
      }"
      (click)="onClose()"
      href="https://www.rostemespolecne.cz"
      target="_blank"
      rel="nofollow"
      mat-list-item>
      <span translate="features.menu.items.hiring"></span>
      <mat-icon class="__item_icon">open_in_new</mat-icon>
    </a>

    <a
      class="[ __item  __item--dense ]  a-slide-animation"
      (click)="onClose()"
      href="https://admin.vshosting.cloud"
      target="_blank"
      rel="nofollow"
      mat-list-item>
      <span translate="features.menu.items.clientZone"></span>
      <mat-icon class="__item_icon">open_in_new</mat-icon>
    </a>


  </mat-nav-list>

  <vsh-break class="__break  a-slide-animation"></vsh-break>

  <p class="__quick-info  a-slide-animation">{{ language === 'cs' ? 'obchod@vshosting.cz' : 'sales@vshosting.eu' }}</p>

  <vsh-social class="__social  a-slide-animation"></vsh-social>

</div>

