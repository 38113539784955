<vsh-scroll-scene
  [elementOffset]="animationOffset"
  class="__scene">
  <div
    class="__layout"
    fxLayout="row wrap"
    fxLayoutGap="0px grid"
    fxLayoutAlign="center center">

    <div
      class="__content-wrap"
      [ngClass]="{
        'u-text--center': contentAlign === 'center',
        'u-text--left': contentAlign === 'left',
        'u-text--right': contentAlign === 'right'
      }"
      [fxFlex]="mode === 'vertical' ? 100 : leftSize"
      [fxFlexOrder]="mode === 'vertical' || contentPosition === 'end' ? 2 : 1">
      <div
        class="__content  c-hero__content"
        [ngClass]="{
          'is-light': contentBackground === 'light',
          'is-grey': contentBackground === 'grey',
          'is-alpha': contentBackground === 'alpha',
          'is-beta': contentBackground === 'beta',
          'is-gamma': contentBackground === 'gamma'
        }"
        [style.maxWidth]="contentMaxWidth"
        [style.marginRight]="contentOffsetX"
        [style.marginTop]="contentOffsetY"
        [vshScrollElement]="!disableFeatureAnimation
          ? {
            from: {
              transform: 'translate3d(0px, 0px, 0px)'
            },
            to: {
              transform: 'translate3d(0px, -15px, 0px)'
            }
          }
          : undefined">
        <vsh-wrap
          [style.paddingLeft]="contentPadding"
          [style.paddingRight]="contentPadding">

          <ng-container *ngIf="!contentRef">
            <ng-content></ng-content>
          </ng-container>

          <ng-container *ngIf="contentRef">
            <ng-template [ngTemplateOutlet]="contentRef.templateRef"></ng-template>
          </ng-container>

        </vsh-wrap>
      </div>
    </div>

    <div
      #galleryParentRef
      class="__feature-wrap"
      [class.has-video]="videoId"
      (click)="videoId ? onShowVideo$.next() : undefined"
      [fxFlex]="mode === 'vertical' ? 100 : rightSize"
      [fxFlexOrder]="mode === 'vertical' || contentPosition === 'end' ? 1 : 2">

      <div
        [class.has-disabled-shadow]="disableShadow"
        class="__feature-container"
        [style.minHeight]="minHeight">

        <div
          *ngIf="!featureRef && image"
          [vshScrollElement]="!disableImageAnimation
            ? {
              from: {
                transform: 'translate3d(0px, 0px, 0px) scale(1.15)'
              },
              to: {
                transform: 'translate3d(0px, 20px, 0px) scale(1.15)'
              }
            }
            : undefined"
          [style.backgroundImage]="image
            ? 'url(' + image + ')'
            : undefined"
          class="__image">
        </div>

        <div
          *ngIf="!featureRef && images"
          [vshScrollElement]="!disableImageAnimation
            ? {
              from: {
                transform: 'translate3d(0px, -20px, 0px) scale(1)'
              },
              to: {
                transform: 'translate3d(0px, 20px, 0px) scale(1)'
              }
            }
            : undefined"
          class="__gallery">
          <vsh-gallery
            [fitToParent]="galleryParentRef"
            [backgroundMode]="!!videoId ? 'contain' : 'cover'"
            class="__gallery-comp"
            [data]="images">
          </vsh-gallery>
        </div>

        <div
          class="c-hero__feature-animation"
          [vshScrollElement]="!disableFeatureAnimation
            ? {
              from: {
                transform: 'translate3d(0px, 0px, 0px) scale(1.15)'
              },
              to: {
                transform: 'translate3d(0px, 20px, 0px) scale(1.15)'
              }
            }
            : undefined"
          *ngIf="featureRef">
          <ng-template [ngTemplateOutlet]="featureRef.templateRef"></ng-template>
        </div>

      </div>

      <mat-icon
        *ngIf="videoId"
        class="__play"
        [class.is-vertical]="mode === 'vertical'">
        play_circle_filled
      </mat-icon>

    </div>

  </div>
</vsh-scroll-scene>

<!-- video dialog -->
<zps-dialog
  [options]="{ disableClose: false }"
  (close)="onDialogClose$.next()"
  [open]="state$ | async">
  <iframe
    width="560"
    height="315"
    [src]="videoId$ | async"
    frameborder="0"
    allow="autoplay; encrypted-media"
    allowfullscreen>
  </iframe>
</zps-dialog>
