import { BREAKPOINT, BreakPoint } from '@angular/flex-layout';

export const customBreakpointsProvider = {
  provide: BREAKPOINT,
  useValue: [
    {
      alias: 'sd',
      mediaQuery: 'screen and (min-width: 600px) and (max-width: 700px)',
      priority: 810
    },
    {
      alias: 'md-sd',
      mediaQuery: 'screen and (min-width: 701px) and (max-width: 1279px)',
      priority: 880
    },
    {
      alias: 'lt-sd',
      mediaQuery: 'screen and (max-width: 700px)',
      priority: 860
    },
    {
      alias: 'gt-sd',
      mediaQuery: 'screen and (min-width: 701px)',
      priority: -810
    }
  ] as BreakPoint[],
  multi: true
};
