import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  first: {
    key: `8<strong>tis.+</strong>`,
    title: `Naše datacentrum hostuje přes 8 tisíc fyzických serverů.`,
    desc: `Datacentrum ServerPark je jedním z <strong>nejmodernějších a nejbezpečnějších</strong>.
      Je provozováno se <strong>zdvojenou redundancí</strong> 2xN u všech aktivních prvků (2x chlazení, 2x záložní zdroje apod).
      Správu datacentra zajišťuje <strong>vlastní tým on-site 24/7</strong>. Stavíme ServerPark II pro dalších 25 tis. serverů,
      první stejnosměrně napájené datacentrum v ČR.`,
    moreButton: `Více o datacentru`
  },
  second: {
    key: `1<strong>Tbps+</strong>`,
    title: `Nejrychlejší možné připojení díky TIER 1 propojení.`,
    desc: `Máme 100Gbps propojení s globálními TIER 1 operátory a jsme členem několika peeringových center.
      Provozujeme <strong>vlastní klíčové optické trasy</strong> a vlastní CDN v Evropě a USA.
      Naše síť je postavena na <strong>špičkovém hardware</strong> (Cisco Nexus 7k, Juniper MX960).
      Infrastruktura je navržena s důrazem na maximální možnost škálování propustnosti a spolehlivost (dostupnost 99,9999 %).`
  },
  third: {
    key: `50<strong>+ odborníků</span>`,
    title: `Kvalitní tým s 13 lety zkušeností.`,
    desc: `Týmy profesionálů zajistí návrh infrastruktury, maximální bezpečnost, optimalizaci a
      unikátně rychlé reakční doby v jednotkách minut.`,
    moreButton: `Více o nás`
  },
  nextButton: `Služby a řešení`
};

const EN = {
  first: {
    key: `8<strong>k+</span>`,
    title: `Our data center hosts over 8 thousand physical servers`,
    desc: `Our very own data center ServerPark is one of <strong>the safest and most modern</strong>. It is run with a 2xN double
      redundancy at all active elements (2x cooling, 2x backup resources etc.). The data center is managed by a <strong>dedicated
      team that is on site 24/7</strong>. We are also <strong>building ServerPark II</strong>, a second data center for
      <strong>further 25 thousand servers</strong>, the <strong>first DC-powered data center</strong> in the Czech Republic.`,
    moreButton: `About the data center`
  },
  second: {
    key: `1<strong>Tbps</span>`,
    title: `Fastest connection possible thanks to TIER 1`,
    desc: `We have a <strong>100 Gbps connection with global TIER 1 operators</strong> and we are members of several peering centers.
      We operate our <strong>own key optical paths</strong> and our own CDN both in Europe and in the USA. Our network is built on
      <strong>top of the line hardware</strong> (Cisco Nexus 7k, Juniper MX960). The infrastructure is designed with emphasis on
      <strong>maximum throughput scalability and reliability</strong> (99,9999 % availability).`
  },
  third: {
    key: `50<strong>+ experts</span>`,
    title: `Great team with over 13 years of experience`,
    desc: `Our team of professionals will take care of designing the infrastructure, ensure maximum security, optimization,
      <strong>and uniquely short reaction time</strong> (in a matter of minutes).`,
    moreButton: `About Us`
  },
  nextButton: `Services & Solutions`
};

const SK = {
  first: {
    key: `8<strong>tis.+</span>`,
    title: `Naše datacentrum hostuje vyše 8 tisíc fyzických serverov`,
    desc: `Datacentrum ServerPark je jedným z <strong>najmodernejších a najbezpečnejších</strong>. Je prevádzkované so zdvojenou
      redundanciou 2xN pri všetkých aktívnych prvkoch (2x chladenie, 2x záložné zdroje a pod). Správu datacentra
      zaisťuje vlastný tím on- site 24/7. Staviame <strong>ServerPark II</strong> pre <strong>ďalších 25 tis. serverov</strong>,
      prvé jednosmerne napájané datacentrum v ČR.`,
    moreButton: `Viac o datacentre`
  },
  second: {
    key: `1<strong>Tbps</span>`,
    title: `Najrýchlejšie možné pripojenie vďaka TIER 1 prepojeniu`,
    desc: `Máme <strong>100Gbps prepojenie s globálnymi TIER 1 operátormi</strong> a sme členom niekoľkých peeringových centier.
      Prevádzkujeme <strong>vlastné kľúčové optickej trasy</strong> a vlastné CDN v Európe a USA. Naša sieť je postavená na
      <strong>špičkovom hardvéry</strong> (Cisco Nexus 7k, Juniper MX960). Infraštruktúra je navrhnutá s dôrazom na maximálnu
      možnosť <strong>škálovania priepustnosti a spoľahlivosť</strong> (dostupnosť 99,9999%).`
  },
  third: {
    key: `50<strong>+ odborníkov</span>`,
    title: `Kvalitný tím s 13-timi rokmi skúseností`,
    desc: `Tímy profesionálov zabezpečia návrh infraštruktúry, maximálnu bezpečnosť, optimalizáciu
      a <strong>unikátne rýchle reakčné doby</strong> v jednotkách minút.`,
    moreButton: `O nás`
  },
  nextButton: `Služby a riešenia`
};

const PL = {
  first: {
    key: `8<strong>tys. +</span>`,
    title: `Nasze centrum danych hostuje ponad 8000 serwerów fizycznych`,
    desc: `Centrum danych  ServerPark jest jednym z <strong>najnowocześniejszych i najbezpieczniejszych</strong>. Pracuje z podwójną
      redundancją 2xN dla wszystkich aktywnych elementów (2x chłodzenie, 2x źródła zapasowe itp.). Zarządzanie centrum danych jest
      zapewniane przez jego własny zespół na miejscu przez całą dobę. Budujemy ServerPark II dla
      <strong>kolejnych 25 tysięcy serwerów</strong>, pierwsze centrum danych zasilane prądem stałym w Republice Czeskiej.`,
    moreButton: `Więcej o centrum danych`
  },
  second: {
    key: `1<strong>Tbps</span>`,
    title: `Najszybsze możliwe połączenie dzięki połączeniu TIER 1`,
    desc: `Mamy <strong>100 Gbps połączenie z globalnymi operatorami TIER 1</strong> i jesteśmy członkiem kilku centrów peeringowych.
      Prowadzimy własne trasy optyczne i własne CDN w Europie i USA. Nasza sieć zbudowana jest na
      <strong>najnowocześniejszym sprzęcie</strong> (Cisco Nexus 7k, Juniper MX960). Infrastruktura została zaprojektowana tak,
      aby zmaksymalizować możliwość <strong>skalowalności przepustowości i niezawodność</strong> (dostępność 99,9999%).`
  },
  third: {
    key: `50<strong>+ specjalistów</span>`,
    title: `Wysokiej jakości zespół z 13-letnim doświadczeniem`,
    desc: `Zespoły specjalistów zapewniają projektowanie infrastruktury, <strong>maksymalne bezpieczeństwo, optymalizację</strong> i
      wyjątkowo szybki czas reakcji w minutach.`,
    moreButton: `O nas`
  },
  nextButton: `Usługi i rozwiązania`
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class AboutStripTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        aboutStrip: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        aboutStrip: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        aboutStrip: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        aboutStrip: PL
      }
    }, true);

  }
}
