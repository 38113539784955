import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vsh-social',
  templateUrl: './social.component.html',
  styleUrls: [ './social.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialComponent {

}
