import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CaseStudyEntity } from '@app/core/case-studies-core';

@Component({
  selector: 'vsh-case-study-item',
  templateUrl: './case-study-item.component.html',
  styleUrls: [ './case-study-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseStudyItemComponent {
  @Input()
  set data(v: CaseStudyEntity) {
    if (v) {
      const services = v.clientServices.length === 1
        ? v.clientServices
        : v.clientServices.reduce((arr, item, index) => {
          if (index !== v.clientServices.length - 1) {
            arr.push(item + ', ');
          } else {
            arr.push(item);
          }
          return arr;
        }, []);

      this._data = {
        ...v,
        clientServices: services
      };
    }
  }

  get data() {
    return this._data;
  }

  private _data: CaseStudyEntity;
}
