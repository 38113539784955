import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'vsh-tailored-button',
  templateUrl: './tailored-button.component.html',
  styleUrls: [ './tailored-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TailoredButtonComponent {
  @Input()
  baseText: string;

  @Input()
  moreText: string;

  @Input()
  isGamma = false;

  @Output()
  clicked = new EventEmitter<MouseEvent>();

}
