import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServicesCoreState, ServiceEntity, ServiceItemEntity } from './services-core.model';
import { ServicesCoreEnum } from './services-core.constant';

export const selectState = createFeatureSelector<ServicesCoreState>(ServicesCoreEnum.Id);

export const selectServices = createSelector(selectState, (state) => state.items);

export const selectMenuServices = createSelector(
  selectServices,
  (services) => services.filter((service) => service.inMenu)
);

export const selectServiceById = <T extends ServiceItemEntity>(id: string) => createSelector(
  selectServices,
  (services) => services.find((s) => s.id === id) as ServiceEntity<T>
);
