<div
  [@slider]="selectedIndex"
  class="__items">
  <div
    class="__item"
    [class.has-background-contain]="backgroundMode === 'contain'"
    [style.backgroundImage]="'url(' + item.url + ')'"
    *ngFor="let item of data; trackBy: trackBy;">
  </div>
</div>

<div
  (click)="setSelectedIndex(selectedIndex + 1)"
  *ngIf="selectedIndex !== (dataLength - 1)"
  class="__control  __control--next">
  <button
    mat-icon-button
    type="button"
    class="__control_button  [ __button  __button--next ]">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div
  (click)="setSelectedIndex(selectedIndex - 1)"
  *ngIf="selectedIndex !== 0"
  class="__control  __control--prev">
  <button
    mat-icon-button
    type="button"
    class="__control_button  [ __button  __button--prev ]">
    <mat-icon>chevron_left</mat-icon>
  </button>
</div>

<span
  [style.paddingBottom.%]="fittedRatio
    ? 100 / (fittedRatio)
    : 100 / (ratio)"
  class="__spanner">
</span>

