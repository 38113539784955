import {
  Component,
  Input,
  HostBinding,
  Directive,
  TemplateRef,
  ContentChild,
  AfterContentInit,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { DialogOpen, getDialogState, DialogClose } from '@zerops/fe/dialog';
import { Subject, merge } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';
import { ScrollSceneComponent } from '@app/common/scroll-scene';
import { GalleryItem } from '@app/common/gallery';
import { AppState } from 'app/models';

@Directive({
  selector: '[vshHeroContent]'
})
export class HeroContentDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[vshHeroFeature]'
})
export class HeroFeatureDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'vsh-hero',
  templateUrl: './hero.component.html',
  styleUrls: [ './hero.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent implements AfterContentInit {

  onShowVideo$ = new Subject<void>();
  onDialogClose$ = new Subject<void>();

  dialogKey = 'vsh:video';

  dialog$ = this._store.pipe(select(getDialogState(this.dialogKey)));
  state$ = this.dialog$.pipe(map((state) => state.state));
  videoId$ = this.dialog$.pipe(
    filter((d) => d.state && d.meta),
    map((d) => this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + d.meta.videoId + '?autoplay=1'))
  );

  @Input()
  image: string;

  @Input()
  images: GalleryItem[];

  @Input()
  videoId: string;

  @Input()
  mode: 'horizontal' | 'vertical' = 'vertical';

  @Input()
  animationOffset: string | number;

  @Input()
  contentPadding: string | number;

  @Input()
  set disableImageAnimation(v) {
    this._disableImageAnimation = coerceBooleanProperty(v);
  }
  get disableImageAnimation() {
    return this._disableImageAnimation;
  }

  @Input()
  set disableFeatureAnimation(v) {
    this._disableFeatureAnimation = coerceBooleanProperty(v);
  }
  get disableFeatureAnimation() {
    return this._disableFeatureAnimation;
  }

  @Input()
  minHeight: string | number;

  @Input()
  set disableShadow(v) {
    this._disableShadow = coerceBooleanProperty(v);
  }

  get disableShadow() {
    return this._disableShadow;
  }

  @Input()
  contentBackground: 'light' | 'grey' | 'alpha' | 'beta' | 'gamma';

  @Input()
  contentPosition: 'start' | 'end' = 'start';

  @Input()
  contentMaxWidth: string | number;

  @Input()
  contentAlign: 'center' | 'left' | 'right' = 'right';

  @Input()
  leftSize = 45;

  @Input()
  rightSize = 55;

  @Input()
  contentOffsetX: number;

  @Input()
  contentOffsetY: number;

  @HostBinding('class.is-vertical')
  get isVerticalClass() {
    return this.mode === 'vertical';
  }

  @ContentChild(HeroContentDirective, { static: false })
  contentRef: HeroContentDirective;

  @ContentChild(HeroFeatureDirective, { static: false })
  featureRef: HeroFeatureDirective;

  @ViewChild(ScrollSceneComponent, { static: true })
  scrollSceneRef: ScrollSceneComponent;

  private _disableShadow = false;
  private _disableImageAnimation = false;
  private _disableFeatureAnimation = false;

  private _openDialogAction$ = this.onShowVideo$.pipe(
    map(() => new DialogOpen(
      this.dialogKey,
      {
        videoId: this.videoId
      }
    ))
  );

  private _dialogCloseAction$ = this.onDialogClose$.pipe(
    map(() => new DialogClose(this.dialogKey))
  );

  constructor(
    private _store: Store<AppState>,
    private _sanitizer: DomSanitizer
  ) {

    merge(
      this._openDialogAction$,
      this._dialogCloseAction$
    ).subscribe(this._store);
  }

  ngAfterContentInit() {
    // console.log(this.featureRef);
  }

  refreshScenes() {
    if (this.scrollSceneRef) {
      this.scrollSceneRef.refresh();
    }
  }

}
