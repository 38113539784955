import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BlogCoreEnum } from './blog-core.constant';
import { BlogCoreEffect } from './blog-core.effect';
import { reducer } from './blog-core.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ BlogCoreEffect ]),
    StoreModule.forFeature(BlogCoreEnum.Id, reducer)
  ]
})
export class BlogCoreModule {

}
