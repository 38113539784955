import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Angulartics2Module } from 'angulartics2';
import { FlexModule } from '@app/core/flex';
import { TildeModule } from '@app/common/tilde';
import { PriceRangeModule } from '@app/common/price-range';
import { ServicesComponent } from './services.component';
import { ServicesTranslationsModule } from './services.translations';

@NgModule({
  declarations: [ ServicesComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    Angulartics2Module,
    FlexModule,
    TildeModule,
    ServicesTranslationsModule,
    PriceRangeModule
  ],
  exports: [ ServicesComponent ]
})
export class ServicesModule { }
