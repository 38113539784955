import * as i0 from "@angular/core";
export class IntercomApi {
    showNewMessage(message) {
        if (window.Intercom) {
            window.Intercom('showNewMessage', message);
        }
    }
    update(data) {
        if (window.Intercom) {
            window.Intercom('update', data);
        }
    }
}
IntercomApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntercomApi_Factory() { return new IntercomApi(); }, token: IntercomApi, providedIn: "root" });
