import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  published: 'publikoval&nbsp;dne'
};

const EN = {
  published: 'published&nbsp;on'
};

const SK = {
  published: 'publikoval&nbsp;dňa'
};

const PL = {
  published: 'opublikowano&nbsp;na'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class BlogInfoTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        blogInfo: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        blogInfo: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        blogInfo: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        blogInfo: PL
      }
    }, true);

  }
}
