import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CablesModule } from '@app/common/cables';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@app/core/flex';
import { TildeModule } from '@app/common/tilde';
import { PipesModule } from '@app/common/pipes';
import { WrapModule } from '@app/common/wrap';
import { MiniReferenceModule } from '@app/common/mini-reference';
import { ClientsStripComponent } from './clients-strip.component';
import { ClientsStripTranslationsModule } from './clients-strip.translations';
import { CaseStudyItemModule } from '../case-study-item';
import { CaseStudyCarouselModule } from '../case-study-carousel';

@NgModule({
  declarations: [ ClientsStripComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    TildeModule,
    WrapModule,
    FlexModule,
    CablesModule,
    ClientsStripTranslationsModule,
    PipesModule,
    MiniReferenceModule,
    CaseStudyItemModule,
    CaseStudyCarouselModule
  ],
  exports: [ ClientsStripComponent ]
})
export class ClientsStripModule { }
