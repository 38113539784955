import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapModule } from '@app/common/wrap';
import { TildeModule } from '@app/common/tilde';
import {
  SectionComponent,
  SectionDescDirective,
  SectionHeaderContentDirective
} from './section.component';

@NgModule({
  declarations: [
    SectionComponent,
    SectionDescDirective,
    SectionHeaderContentDirective
  ],
  imports: [
    CommonModule,
    WrapModule,
    TildeModule
  ],
  exports: [
    SectionComponent,
    SectionDescDirective,
    SectionHeaderContentDirective
  ]
})
export class SectionModule { }
