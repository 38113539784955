/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./price-range.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../price/price.component.ngfactory";
import * as i4 from "../price/price.component";
import * as i5 from "@angular/common";
import * as i6 from "./price-range.component";
var styles_PriceRangeComponent = [i0.styles];
var RenderType_PriceRangeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PriceRangeComponent, data: {} });
export { RenderType_PriceRangeComponent as RenderType_PriceRangeComponent };
function View_PriceRangeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "__no_tax"], ["translate", "app.noTaxPrices"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "app.noTaxPrices"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PriceRangeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "__from"], ["translate", "app.from"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "vsh-price", [], null, null, null, i3.View_PriceComponent_0, i3.RenderType_PriceComponent)), i1.ɵdid(5, 49152, null, 0, i4.PriceComponent, [], { price: [0, "price"], currency: [1, "currency"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriceRangeComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app.from"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.priceFrom; var currVal_2 = _co.currency; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.noTax; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_PriceRangeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriceRangeComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.priceFrom !== null) && (_co.priceTo !== null)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PriceRangeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-price-range", [], null, null, null, View_PriceRangeComponent_0, RenderType_PriceRangeComponent)), i1.ɵdid(1, 49152, null, 0, i6.PriceRangeComponent, [], null, null)], null, null); }
var PriceRangeComponentNgFactory = i1.ɵccf("vsh-price-range", i6.PriceRangeComponent, View_PriceRangeComponent_Host_0, { priceFrom: "priceFrom", priceTo: "priceTo", currency: "currency", inline: "inline", noTax: "noTax" }, {}, []);
export { PriceRangeComponentNgFactory as PriceRangeComponentNgFactory };
