/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-scene.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scroll-scene.component";
import * as i3 from "./scroll-scene.service";
var styles_ScrollSceneComponent = [i0.styles];
var RenderType_ScrollSceneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollSceneComponent, data: {} });
export { RenderType_ScrollSceneComponent as RenderType_ScrollSceneComponent };
export function View_ScrollSceneComponent_0(_l) { return i1.ɵvid(2, [i1.ɵncd(null, 0)], null, null); }
export function View_ScrollSceneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vsh-scroll-scene", [], null, null, null, View_ScrollSceneComponent_0, RenderType_ScrollSceneComponent)), i1.ɵdid(1, 1228800, null, 1, i2.ScrollSceneComponent, [i1.ElementRef, i3.ScrollSceneService], null, null), i1.ɵqud(603979776, 1, { els: 1 })], null, null); }
var ScrollSceneComponentNgFactory = i1.ɵccf("vsh-scroll-scene", i2.ScrollSceneComponent, View_ScrollSceneComponent_Host_0, { offset: "offset", duration: "duration", elementOffset: "elementOffset" }, {}, ["*"]);
export { ScrollSceneComponentNgFactory as ScrollSceneComponentNgFactory };
