import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InViewDirective } from './in-view.directive';

@NgModule({
  declarations: [ InViewDirective ],
  imports: [ ScrollingModule ],
  exports: [ InViewDirective ],
})
export class InViewModule { }
