import { Component } from '@angular/core';

@Component({
  selector: 'vsh-cable-two',
  templateUrl: './cable-two.component.html',
  styleUrls: [ './cable-two.component.scss' ]
})
export class CableTwoComponent {

}
