import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { ofRoute } from 'ngrx-router';
import { BlogCoreListRequest } from '@app/core/blog-core';

@Injectable()
export class HomeEffect {

  @Effect()
  private _loadBlogRequest$ = this._actions$.pipe(
    ofRoute(''),
    map(() => new BlogCoreListRequest({ page: 1, tags: [] }))
  );

  constructor(private _actions$: Actions) { }
}
