import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Angulartics2Module } from 'angulartics2';
import { WrapModule } from '@app/common/wrap';
import { SocialModule } from '@app/common/social';
import { TildeModule } from '@app/common/tilde';
import { LogoModule } from '@app/common/logo';
import { BreakModule } from '@app/common/break';
import { MenuComponent } from './menu.component';
import { MenuTranslationsModule } from './menu.translations';

@NgModule({
  declarations: [ MenuComponent ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    Angulartics2Module,
    WrapModule,
    LogoModule,
    TildeModule,
    SocialModule,
    BreakModule,
    MenuTranslationsModule
  ],
  exports: [ MenuComponent ]
})
export class MenuModule { }
