import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SatPopoverModule } from '@app/common/popover';
import { FlexModule } from '@app/core/flex';
import { AboutPopComponent } from './about-pop.component';
import { AboutPopTranslationsModule } from './about-pop.translations';

@NgModule({
  declarations: [ AboutPopComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    SatPopoverModule,
    FlexModule,
    AboutPopTranslationsModule
  ],
  exports: [ AboutPopComponent ]
})
export class AboutPopModule { }
