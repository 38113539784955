import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceBoxComponent } from './invoice-box.component';
import { InvoiceBoxTranslationsModule } from './invoice-box.translations';

@NgModule({
  declarations: [ InvoiceBoxComponent ],
  imports: [
    CommonModule,
    InvoiceBoxTranslationsModule
  ],
  exports: [ InvoiceBoxComponent ]
})
export class InvoiceBoxModule { }
