import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@app/core/flex';
import { AvatarModule } from '@app/common/avatar';
import { MiniReferenceComponent } from './mini-reference.component';

@NgModule({
  declarations: [ MiniReferenceComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FlexModule,
    AvatarModule
  ],
  exports: [ MiniReferenceComponent ]
})
export class MiniReferenceModule { }
