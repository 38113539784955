import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom, delay } from 'rxjs/operators';
import { ActionTypes, ListLocalSuccess, ListFail, DetailLocalSuccess, DetailFail, TagsLocalSuccess, TagsFail } from './blog-core.action';
import { BlogCoreApi } from './blog-core.api';
import { Store, select } from '@ngrx/store';
import { selectSettingsCoreLanguage } from '@app/core/settings-core';
import { environment } from 'environments/environment';
const LanguageEnum = environment.blogTranslationCategories;
export class BlogCoreEffect {
    // @Effect()
    // private _onDetailLocalSuccessLoadMedia$ = this._actions$.pipe(
    //   ofType<DetailLocalSuccess>(ActionTypes.DetailLocalSuccess),
    //   filter(({ payload }) => !!payload.featured_media),
    //   map(({ payload }) => payload.featured_media),
    //   map((id) => new WpCoreLoadMediaRequest([ id ]))
    // );
    constructor(_actions$, _api, _store) {
        this._actions$ = _actions$;
        this._api = _api;
        this._store = _store;
        this._onListRequest$ = this._actions$.pipe(ofType(ActionTypes.ListRequest), delay(0), withLatestFrom(this._store.pipe(select(selectSettingsCoreLanguage))), switchMap(([{ payload }, language]) => this._api
            .list$(Object.assign({}, payload, { language: LanguageEnum[language] }))
            .pipe(map((data) => new ListLocalSuccess(data.body, parseInt(data.headers.get('X-WP-Total'), 10))), catchError((data) => of(new ListFail(data))))));
        this._onTagsRequest$ = this._actions$.pipe(ofType(ActionTypes.TagsRequest), switchMap(() => this._api
            .tags$()
            .pipe(map((data) => new TagsLocalSuccess(data)), catchError((data) => of(new TagsFail(data))))));
        this._onDetailRequest$ = this._actions$.pipe(ofType(ActionTypes.DetailRequest), switchMap(({ payload }) => this._api
            .detail$(payload)
            .pipe(map((data) => new DetailLocalSuccess(data)), catchError((data) => of(new DetailFail(data))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], BlogCoreEffect.prototype, "_onListRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], BlogCoreEffect.prototype, "_onTagsRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], BlogCoreEffect.prototype, "_onDetailRequest$", void 0);
