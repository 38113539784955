/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tilde.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tilde.component";
var styles_TildeComponent = [i0.styles];
var RenderType_TildeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TildeComponent, data: {} });
export { RenderType_TildeComponent as RenderType_TildeComponent };
export function View_TildeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, ":svg:svg", [["class", "__svg"], ["viewBox", "0 0 12.24 4.67"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, ":svg:path", [["class", "__tilde"], ["d", "M8.34 4.67a3 3 0 0 1-1.41-.31 8.64 8.64 0 0 1-1.11-.68L4.86 3a1.71 1.71 0 0 0-1-.31 1.56 1.56 0 0 0-1 .39A4.18 4.18 0 0 0 2 4.2L0 2.67A6.53 6.53 0 0 1 1.94.6a3.73 3.73 0 0 1 2-.6 3 3 0 0 1 1.38.31 9.25 9.25 0 0 1 1.1.69l1 .67a1.69 1.69 0 0 0 1 .31 1.5 1.5 0 0 0 1-.39 4 4 0 0 0 .84-1.12l2 1.53a6.64 6.64 0 0 1-1.96 2.07 3.7 3.7 0 0 1-2 .6"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "__tilde--alpha": 0, "__tilde--beta": 1, "__tilde--gamma": 2, "__tilde--black": 3, "__tilde--animated": 4 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "__tilde"; var currVal_1 = _ck(_v, 4, 0, (_co.color === "alpha"), (_co.color === "beta"), (_co.color === "gamma"), (_co.color === "black"), !_co.color); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_TildeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-tilde", [], null, null, null, View_TildeComponent_0, RenderType_TildeComponent)), i1.ɵdid(1, 49152, null, 0, i3.TildeComponent, [], null, null)], null, null); }
var TildeComponentNgFactory = i1.ɵccf("vsh-tilde", i3.TildeComponent, View_TildeComponent_Host_0, { color: "color" }, {}, []);
export { TildeComponentNgFactory as TildeComponentNgFactory };
