/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about-pop-wrap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../about-pop/about-pop.component.ngfactory";
import * as i3 from "../popover/popover-anchoring.service";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "../about-pop/about-pop.component";
import * as i7 from "./about-pop-wrap.component";
import * as i8 from "@ngx-translate/core";
var styles_AboutPopWrapComponent = [i0.styles];
var RenderType_AboutPopWrapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutPopWrapComponent, data: {} });
export { RenderType_AboutPopWrapComponent as RenderType_AboutPopWrapComponent };
export function View_AboutPopWrapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { aboutPopRef: 0 }), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 16777216, null, null, 2, "vsh-about-pop", [], null, null, null, i2.View_AboutPopComponent_0, i2.RenderType_AboutPopComponent)), i1.ɵprd(131584, null, i3.SatPopoverAnchoringService, i3.SatPopoverAnchoringService, [i4.Overlay, i1.NgZone, [2, i5.Directionality]]), i1.ɵdid(4, 49152, [[1, 4]], 0, i6.AboutPopComponent, [i3.SatPopoverAnchoringService, i1.ViewContainerRef], null, null)], null, null); }
export function View_AboutPopWrapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vsh-about-pop-wrap", [], null, null, null, View_AboutPopWrapComponent_0, RenderType_AboutPopWrapComponent)), i1.ɵprd(135680, null, i3.SatPopoverAnchoringService, i3.SatPopoverAnchoringService, [i4.Overlay, i1.NgZone, [2, i5.Directionality]]), i1.ɵdid(2, 1228800, null, 0, i7.AboutPopWrapComponent, [i1.ElementRef, i8.TranslateService], null, null)], null, null); }
var AboutPopWrapComponentNgFactory = i1.ɵccf("vsh-about-pop-wrap", i7.AboutPopWrapComponent, View_AboutPopWrapComponent_Host_0, {}, {}, ["*"]);
export { AboutPopWrapComponentNgFactory as AboutPopWrapComponentNgFactory };
