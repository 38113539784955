import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { defer, timer, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
  Init,
  ActionTypes,
  ListRequest,
  ListLocalSuccess,
  ListFail
} from './references-core.action';
import { ReferencesCoreApi } from './references-core.api';

@Injectable()
export class ReferencesCoreEffect {
  @Effect()
  private _onInit$ = defer(() => timer(0)).pipe(
    map(() => new Init())
  );

  @Effect()
  private _onInitListRequst$ = this._actions$.pipe(
    ofType<Init>(ActionTypes.Init),
    map(() => new ListRequest())
  );

  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    switchMap(() => this._api
      .list$()
      .pipe(
        map((data) => new ListLocalSuccess(data)),
        catchError(() => of(new ListFail()))
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _api: ReferencesCoreApi
  ) { }
}
