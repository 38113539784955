import { Actions, ActionTypes } from './case-studies-core.action';
import { CaseStudiesCoreState } from './case-studies-core.model';

export const initialState: CaseStudiesCoreState = {
  list: []
};

export function reducer(
  state = initialState,
  action: Actions
): CaseStudiesCoreState {

  switch (action.type) {
    case ActionTypes.ListLocalSuccess:
      return {
        ...state,
        list: action.payload
      };
  }

  return state;
}
