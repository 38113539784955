import { Routes } from '@angular/router';
import { FourOFourPage } from './four-o-four.page';
const ɵ0 = {
    animation: '404',
    topBg: 'beta',
    appBarQuickLinkMode: 'contact',
    footerMode: 'full'
};
const routes = [
    {
        path: '404',
        component: FourOFourPage,
        data: ɵ0
    }
];
export class FourOFourRoutesModule {
}
export { ɵ0 };
