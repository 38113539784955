<vsh-section
  [style.display]="display"
  class="__section  __footer"
  color="light"
  background="gamma">
  <vsh-wrap class="__wrap">
    <div
      *ngIf="mode === 'full'"
      class="__row  __row--first"
      fxLayout="row wrap"
      fxLayoutAlign="top top">

      <div
        class="__item"
        [ngClass]="{
          '__item--sm': media.isActive('sm'),
          '__item--md': media.isActive('md'),
          '__item--lt-sm': media.isActive('lt-sm')
        }"
        fxFlex="25"
        fxFlex.md="50"
        fxFlex.sm="50"
        fxFlex.lt-sm="100">
        <vsh-address-box></vsh-address-box>
      </div>

      <div
        class="__item"
        [ngClass]="{
          '__item--sm': media.isActive('sm'),
          '__item--md': media.isActive('md'),
          '__item--lt-sm': media.isActive('lt-sm')
        }"
        fxFlex="25"
        fxFlex.md="50"
        fxFlex.sm="50"
        fxFlex.lt-sm="100">
        <vsh-invoice-box></vsh-invoice-box>
      </div>

      <div
        class="__item"
        [ngClass]="{
          '__item--sm': media.isActive('sm'),
          '__item--md': media.isActive('md'),
          '__item--lt-sm': media.isActive('lt-sm')
        }"
        fxFlex="25"
        fxFlex.md="50"
        fxFlex.sm="50"
        fxFlex.lt-sm="100">

        <h2 class="__title" translate="features.footer.socialTitle"></h2>

        <vsh-social class="__social"></vsh-social>

      </div>

      <div
        class="__item"
        [ngClass]="{
          '__item--sm': media.isActive('sm'),
          '__item--md': media.isActive('md'),
          '__item--lt-sm': media.isActive('lt-sm')
        }"
        fxFlex="25"
        fxFlex.md="50"
        fxFlex.sm="50"
        fxFlex.lt-sm="100">
        <vsh-tilde class="__tilde" color="beta"></vsh-tilde>
      </div>

    </div>

    <p class="__copy">&copy; VShosting s.r.o, 2019. Made inhouse using <img class="__copy_image" src="/assets/angular.svg" /></p>

  </vsh-wrap>
</vsh-section>
