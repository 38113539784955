import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  trigger,
  transition,
  style,
  animate,
  state,
  query,
  group,
  animateChild,
  sequence
} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { Store, select } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ScrollStateService, ActiveRouteDataService } from '@app/services';
import { selectServicesCoreMenu } from '@app/core/services-core';
import { selectSettingsCoreLanguage } from '@app/core/settings-core';
import { AppState } from './models';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MediaObserver } from '@angular/flex-layout';
// import { ExperimentService } from './services/experiment.service';

const ROUTER_ANIMATION_DURATIONS = {
  active: {
    contentDurationEnter: '600ms',
    contentDurationLeave: '400ms',
    contentShowDelay: '200ms',
    appBarDurationHide: '0ms',
    appBarDurationShow: '0ms',
    appBarDelay: '0ms'

  },
  disabled: {
    contentDurationEnter: '400ms',
    contentDurationLeave: '0ms',
    contentShowDelay: '400ms',
    appBarDurationHide: '0ms',
    appBarDurationShow: '700ms',
    appBarDelay: '150ms'
  }
};

const ROUTER_ANIMATION_TRANFORMS = {
  firefox: {
    contentShowFrom: 'translate3d(0px, 0px, 0px)',
    contentShowTo: 'translate3d(0px, 0px, 0px)',
    contentHideFrom: 'translate3d(0px, 0px, 0px)',
    contentHideTo: 'translate3d(0px, 0px, 0px)'
  },
  others: {
    contentShowFrom: 'translate3d(0px, -2px, 0px)',
    contentShowTo: 'translate3d(0px, 0px, 0px)',
    contentHideFrom: 'translate3d(0px, 0px, 0px)',
    contentHideTo: 'translate3d(0px, 4px, 0px)'
  }
};

@Component({
  selector: 'vsh-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  animations: [
    trigger('appBarAnimation', [
      transition('void => *', [
        style({
          opacity: 0
        }),
        animate('2000ms 100ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
          opacity: 1
        }))
      ]),
      transition('* <=> *', [
        sequence([
          animate('{{appBarDurationHide}} cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 0
          })),
          animate('{{appBarDurationShow}} {{appBarDelay}} cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 1
          }))
        ])
      ], {
        params: {
          appBarDurationHide: ROUTER_ANIMATION_DURATIONS.active.appBarDurationHide,
          appBarDurationShow: ROUTER_ANIMATION_DURATIONS.active.appBarDurationShow,
          appBarDelay: ROUTER_ANIMATION_DURATIONS.active.appBarDelay
        }
      })
    ]),

    trigger('routeAnimation', [
      transition('* <=> *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
          animateChild()
        ], { optional: true }),
        group([
          query(':enter', [
            style({
              opacity: 0,
              transform: '{{contentShowFrom}}'
            }),
            animate('{{contentDurationEnter}} {{contentShowDelay}} cubic-bezier(0.4, 0.0, 0.2, 1)', style({
              opacity: 1,
              transform: '{{contentShowTo}}'
            }))
          ], { optional: true }),
          query(':leave', [
            style({
              opacity: 1,
              transform: '{{contentHideFrom}}'
            }),
            animate('{{contentDurationLeave}} cubic-bezier(0.4, 0.0, 0.2, 1)', style({
              opacity: 0,
              transform: '{{contentHideTo}}'
            }))
          ], { optional: true })
        ])
      ], {
          params: {
            contentDurationLeave: ROUTER_ANIMATION_DURATIONS.active.contentDurationLeave,
            contentShowDelay: ROUTER_ANIMATION_DURATIONS.active.contentShowDelay,
            contentDurationEnter: ROUTER_ANIMATION_DURATIONS.active.contentDurationEnter,
            contentHideFrom: ROUTER_ANIMATION_TRANFORMS.others.contentHideFrom,
            contentHideTo: ROUTER_ANIMATION_TRANFORMS.others.contentHideTo,
            contentShowFrom: ROUTER_ANIMATION_TRANFORMS.others.contentShowFrom,
            contentShowTo: ROUTER_ANIMATION_TRANFORMS.others.contentShowTo
          }
        }
      )
    ]),

    trigger('menuAnimation', [
      state('open',
        style({ display: 'block' })
      ),
      state('closed',
        style({ display: 'none' })
      ),
      transition('closed => open', [
        style({
          opacity: 0,
          display: 'block',
          transform: 'translate3d(15px, 0, 0)'
        }),
        animate('350ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        }))
      ]),
      transition('open => closed', [
        style({
          opacity: 1,
          display: 'block'
        }),
        animate('350ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
          opacity: 0,
          transform: 'translate3d(15px, 0, 0)'
        }))
      ])
    ])

  ]
})
export class AppComponent implements AfterViewInit {
  // # Data
  // -- sync
  set menuOpen(v) {
    this._menuOpen = coerceBooleanProperty(v);

    if (this._menuOpen === true && this.menuRef && this.menuRef.nativeElement.scrollTo) {
      this.menuRef.nativeElement.scrollTo(0, 0);
    }
  }

  get menuOpen() {
    return this._menuOpen;
  }

  init = false;
  routeAnimationParams = undefined;

  // -- angular
  @ViewChild('menuRef', { static: true })
  menuRef: ElementRef;

  // -- async
  language$ = this._store.pipe(select(selectSettingsCoreLanguage));
  menuServicesItems$ = this._store.pipe(
    select(selectServicesCoreMenu),
    withLatestFrom(this.language$),
    map(([ data, lang ]) => data.map((itm) => ({
      ...itm,
      // only translated name needed
      name: itm.name[lang]
    })))
  );

  routeAnimationParams$ = this._scrollState.getScrolled$(100).pipe(
    map((v) => v.top <= 0
      ? ROUTER_ANIMATION_DURATIONS.active
      : ROUTER_ANIMATION_DURATIONS.disabled
    ),
    map((v) => {
      const b = this.platform.FIREFOX
        ? ROUTER_ANIMATION_TRANFORMS.firefox
        : ROUTER_ANIMATION_TRANFORMS.others;

      return {
        ...v,
        ...b
      };
    })
  );

  private _menuOpen = false;

  constructor(
    public platform: Platform,
    public media: MediaObserver,
    private _scrollState: ScrollStateService,
    private _activeRouteData: ActiveRouteDataService,
    private _cdRef: ChangeDetectorRef,
    private _store: Store<AppState>,
    private _angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    // private _experiment: ExperimentService
  ) {

    this._angulartics2GoogleTagManager.startTracking();

    // this._experiment.chooseVariant();

    /*const b = this.platform.FIREFOX
      ? ROUTER_ANIMATION_TRANFORMS.firefox
      : ROUTER_ANIMATION_TRANFORMS.others;*/

    const b = ROUTER_ANIMATION_TRANFORMS.others;

    this.routeAnimationParams = {
      contentDurationLeave: ROUTER_ANIMATION_DURATIONS.active.contentDurationLeave,
      contentShowDelay: ROUTER_ANIMATION_DURATIONS.active.contentShowDelay,
      contentDurationEnter: ROUTER_ANIMATION_DURATIONS.active.contentDurationEnter,
      appBarDurationHide: ROUTER_ANIMATION_DURATIONS.active.appBarDurationHide,
      appBarDurationShow: ROUTER_ANIMATION_DURATIONS.active.appBarDurationShow,
      appBarDelay: ROUTER_ANIMATION_DURATIONS.active.appBarDelay,
      ...b
    };

    this.routeAnimationParams$.subscribe((v) => {
      this.routeAnimationParams = v;
      this._cdRef.detectChanges();
    });

    this._activeRouteData
      .getData$()
      .subscribe((data) => {
        if (data.topBg) {
          document.body.className = `has-top-bg--${data.topBg}`;
        } else {
          document.body.className = ``;
        }
      });
  }

  ngAfterViewInit() {
    this.init = true;
  }

  routeAnimationStart() {
    document.body.classList.add('has-router-animation-running');
  }

  routeAnimationDone() {
    document.body.classList.remove('has-router-animation-running');
  }

  prepareRoute(outlet: RouterOutlet) {
    const r = outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
      ? outlet.activatedRouteData['animation']
      : 'void';
    return r;
  }

}
