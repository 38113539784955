/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./invoice-box.component";
var styles_InvoiceBoxComponent = [i0.styles];
var RenderType_InvoiceBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceBoxComponent, data: {} });
export { RenderType_InvoiceBoxComponent as RenderType_InvoiceBoxComponent };
export function View_InvoiceBoxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "__title"], ["translate", "common.invoiceBox.invoicesTitle"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "p", [["class", "__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" VSHosting s.r.o."])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sodomkova 1579/5"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 102 00 Praha 15"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["I\u010C 61505455"])), (_l()(), i1.ɵted(-1, null, [", "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DI\u010C CZ61505455"]))], function (_ck, _v) { var currVal_0 = "common.invoiceBox.invoicesTitle"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InvoiceBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vsh-invoice-box", [], null, null, null, View_InvoiceBoxComponent_0, RenderType_InvoiceBoxComponent)), i1.ɵdid(1, 49152, null, 0, i3.InvoiceBoxComponent, [], null, null)], null, null); }
var InvoiceBoxComponentNgFactory = i1.ɵccf("vsh-invoice-box", i3.InvoiceBoxComponent, View_InvoiceBoxComponent_Host_0, {}, {}, []);
export { InvoiceBoxComponentNgFactory as InvoiceBoxComponentNgFactory };
