import { NgModule } from '@angular/core';
import { SafePipe } from './safe-html.pipe';

@NgModule({
  declarations: [ SafePipe ],
  exports: [ SafePipe ]
})
export class PipesModule {

}
