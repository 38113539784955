import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from '@app/common/avatar';
import { PersonBadgeComponent } from './person-badge.component';

@NgModule({
  declarations: [ PersonBadgeComponent ],
  imports: [
    CommonModule,
    AvatarModule
  ],
  exports: [ PersonBadgeComponent ]
})
export class PersonBadgeModule { }
