<a
  mat-icon-button
  class="__social-button"
  target="_blank"
  rel="nofollow"
  href="https://www.facebook.com/vshosting">
  <mat-icon class="__social-icon" svgIcon="facebook"></mat-icon>
</a>

<a
  mat-icon-button
  class="__social-button"
  target="_blank"
  rel="nofollow"
  href="https://twitter.com/vshosting">
  <mat-icon class="__social-icon" svgIcon="twitter"></mat-icon>
</a>

<a
  mat-icon-button
  class="__social-button"
  target="_blank"
  rel="nofollow"
  href="https://www.instagram.com/vshosting/">
  <mat-icon class="__social-icon" svgIcon="instagram"></mat-icon>
</a>

<a
  mat-icon-button
  class="__social-button"
  target="_blank"
  rel="nofollow"
  href="https://www.linkedin.com/company/vshosting-s-r-o-">
  <mat-icon class="__social-icon" svgIcon="linkedin"></mat-icon>
</a>
