import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { MetaConfig, MetaPayload } from './meta.model';
import { META_CONFIG } from './meta.constant';

@Injectable()
export class MetaService {
  constructor(
    private _title: Title,
    private _meta: Meta,
    @Inject(META_CONFIG)
    private _config: MetaConfig
  ) { }

  set(meta: MetaPayload) {
    const fullTitle = meta.title
      ? meta.title + ' ~ ' + this._config.baseTitle
      : this._config.baseTitle;


    if (meta.title !== undefined) {
      this._title.setTitle(fullTitle);
    }

    if (meta.ogTitle !== undefined) {
      const fullOgTitle = meta.ogTitle
        ? meta.ogTitle + ' ~ ' + this._config.baseTitle
        : this._config.baseTitle;

      this._meta.updateTag({
        content: fullOgTitle,
        property: 'og:title'
      });
    }

    const parsedHref = window.location.href.split('/');
    const href = parsedHref[0] + '//' + parsedHref[2];

    const localHref = meta.image && meta.image.startsWith('/')
      ? href + meta.image
      : meta.image;

    this._meta.updateTag({
      content: localHref,
      property: 'og:image'
    });

    this._meta.updateTag({
      content: meta.description,
      property: 'og:description'
    });

    this._meta.updateTag({
      content: meta.description,
      property: 'description'
    });

    this._meta.updateTag({
      content: window.location.href,
      property: 'og:url'
    });

    this._meta.updateTag({
      content: 'website',
      property: 'og:type'
    });

    if (meta.robots) {
      this._meta.updateTag({
        content: 'noindex, follow',
        name: 'robots'
      });
    } else {
      this._meta.removeTag('name="robots"');
    }

  }
}
