import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger
} from '@angular/animations';
import { ServiceEntityTranslated } from '@app/core/services-core';

@Component({
  selector: 'vsh-menu',
  templateUrl: './menu.component.html',
  styleUrls: [ './menu.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideAnimation', [
      transition(
        'closed => open',
        query('.a-slide-animation', [
          style({
            transform: 'translate3d(8px, 0px, 0px)',
            opacity: 0
          }),
          stagger(10, [
            animate(
              `{{ duration }} {{ delay }} {{ easing }}`,
              style({
                transform: 'translate3d(0px, 0px, 0px)',
                opacity: 1
              })
            ),
          ])
        ]),
        {
          params: {
            duration: '250ms',
            delay: '10ms',
            easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
          }
        }
      ),
      transition(
        'open => closed',
        query('.a-slide-animation', [
          stagger(-10, [
            animate(
              `{{ duration }} {{ delay }} {{ easing }}`,
              style({
                transform: 'translate3d(8px, 0px, 0px)',
                opacity: 0
              })
            ),
          ])
        ]),
        {
          params: {
            duration: '250ms',
            delay: '0ms',
            easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
          }
        }
      )
    ])
  ]
})
export class MenuComponent {
  closing = false;

  serviceEventIdsMap = {
    'managed-server': {
      'event_category': 'managed server/cloud-menu',
      'event_label': 'https://vshosting.cz'
    },
    'dedicated-server': {
      'event_category': 'dedicated server-menu',
      'event_label': 'https://vshosting.cz'
    },
    'managed-kubernetes': {
      'event_category': 'dedicated server-menu',
      'event_label': 'https://vshosting.cz'
    },
    'cloud-mail': {
      'event_category': 'cloudmail-menu',
      'event_label': 'https://vshosting.cz'
    }
  };

  @Output()
  closeClicked = new EventEmitter<void>();

  @Input()
  open: boolean;

  @Input()
  serviceItems: ServiceEntityTranslated<any>[];

  @Input()
  language: string;

  onClose() {
    if (!this.closing) {
      this.closeClicked.emit();
    }
  }
}
