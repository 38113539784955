import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ReferencesCoreApi {
    constructor(_http) {
        this._http = _http;
    }
    list$() {
        return this._http.get('/assets/references.json');
    }
}
ReferencesCoreApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReferencesCoreApi_Factory() { return new ReferencesCoreApi(i0.ɵɵinject(i1.HttpClient)); }, token: ReferencesCoreApi, providedIn: "root" });
