import { WpApiError } from '@app/core/wp-core';
import { Action } from '@app/models';
import { BlogEntity, LanguageEnumEntity } from './blog-core.model';

export enum ActionTypes {
  ListRequest = '[Blog Core] List Request',
  ListFail = '[Blog Core] List Fail',
  ListLocalSuccess = '[Blog Core] List Local Success',

  TagsRequest = '[Blog Core] Tags Request',
  TagsFail = '[Blog Core] Tags Fail',
  TagsLocalSuccess = '[Blog Core] Tags Local Success',

  DetailRequest = '[Blog Core] Detail Request',
  DetailFail = '[Blog Core] Detail Fail',
  DetailLocalSuccess = '[Blog Core] Detail Local Success'
}

export class ListRequest implements Action {
  readonly type = ActionTypes.ListRequest;

  constructor(public payload?: {
    page?: number,
    tags?: number[],
    language?: LanguageEnumEntity
  }) { }
}

export class ListFail implements Action {
  readonly type = ActionTypes.ListFail;

  constructor(public payload: WpApiError) { }
}

export class ListLocalSuccess implements Action {
  readonly type = ActionTypes.ListLocalSuccess;

  constructor(public payload: BlogEntity[], public meta: number) { }
}

export class TagsRequest implements Action {
  readonly type = ActionTypes.TagsRequest;
}

export class TagsFail implements Action {
  readonly type = ActionTypes.TagsFail;

  constructor(public payload: WpApiError) { }
}

export class TagsLocalSuccess implements Action {
  readonly type = ActionTypes.TagsLocalSuccess;

  // TODO: interface
  constructor(public payload: any[]) { }
}

export class DetailRequest implements Action {
  readonly type = ActionTypes.DetailRequest;

  constructor(public payload: string) { }
}

export class DetailFail implements Action {
  readonly type = ActionTypes.DetailFail;

  constructor(public payload: WpApiError) { }
}

export class DetailLocalSuccess implements Action {
  readonly type = ActionTypes.DetailLocalSuccess;
  payload: {
    changes: BlogEntity;
    id: string;
  };

  constructor(data: BlogEntity) {
    this.payload = {
      changes: data,
      id: data.slug
    };
  }
}

export type Actions
  = ListRequest
  | ListFail
  | ListLocalSuccess

  | DetailRequest
  | DetailFail
  | DetailLocalSuccess

  | TagsRequest
  | TagsFail
  | TagsLocalSuccess;
