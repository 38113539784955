import { Routes } from '@angular/router';
import { HomePage } from './home.page';
import { FourOFourPage } from '../-four-o-four/four-o-four.page';
const ɵ0 = {
    animation: 'home',
    topBg: 'page',
    appBarQuickLinkMode: 'contact',
    footerMode: 'full'
}, ɵ1 = {
    animation: '404',
    topBg: 'page',
    appBarQuickLinkMode: 'contact',
    footerMode: 'full'
};
const routes = [
    {
        path: '',
        component: HomePage,
        data: ɵ0
    },
    {
        path: '**',
        component: FourOFourPage,
        data: ɵ1
    }
];
export class HomeRoutesModule {
}
export { ɵ0, ɵ1 };
