import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ofRoute } from 'ngrx-router';
import { BlogCoreListRequest } from '@app/core/blog-core';
export class HomeEffect {
    constructor(_actions$) {
        this._actions$ = _actions$;
        this._loadBlogRequest$ = this._actions$.pipe(ofRoute(''), map(() => new BlogCoreListRequest({ page: 1, tags: [] })));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], HomeEffect.prototype, "_loadBlogRequest$", void 0);
