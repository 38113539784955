<mat-card class="__item" [routerLink]="[ '/', 'references', data.id ]">
  <div class="__content">

    <div
      class="__item_image"
      fxLayout="row wrap"
      fxLayoutAlign="center center">
      <img
        class="__item_image-el"
        [src]="data.clientLogo | safe: 'url'"
      />
    </div>

    <div
      class="__client-stats"
      fxLayout="row wrap"
      fxLayoutAlign="center start"
      fxLayoutGap="0px grid">

      <div fxFlex="100" *ngIf="data.clientBusiness">
        <h3 class="__client-stats_main">
          <ng-container *ngIf="data.clientBusiness">{{ data.clientBusiness }}  - </ng-container>
          <span *ngIf="data.web" class="__link" [innerHTML]="data.web" (click)="$event.stopPropagation()"></span>
        </h3>
      </div>

      <div fxFlex="100" *ngIf="data.stats[0]">
        <h3 class="__client-stats_main">
          {{ data.stats[0].value }} <span>{{ data.stats[0].ofWhat }}</span>
        </h3>
      </div>

      <div fxFlex="100" *ngIf="data.stats[1]">
        <h3 class="__client-stats_main">
          {{ data.stats[1].value }} <span>{{ data.stats[1].ofWhat }}</span>
        </h3>
      </div>

      <div fxFlex="100" *ngIf="data.stats[2]">
        <h3 class="__client-stats_main">
          {{ data.stats[2].value }} <span>{{ data.stats[2].ofWhat }}</span>
        </h3>
      </div>

    </div>

    <div class="__item_separator">
      <vsh-tilde class="__item_separator-tilde" color="beta"></vsh-tilde>
    </div>

    <p class="__item_desc" *ngIf="data.preview" [innerHTML]="data.preview"></p>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="center start"
      fxLayoutGap="36px grid">

      <div fxFlex="65">
        <h3 class="__info-stats_main">
          <span class="__info-stats_secondary" translate="common.caseStudyItem.services"></span>
          <ng-container *ngFor="let item of data.clientServices">
            <div class="__service" [innerHTML]="item" (click)="$event.stopPropagation()"></div>
          </ng-container>
        </h3>
      </div>

      <div fxFlex="35" *ngIf="data.coopLength && !data.coopText">
        <h3 class="__info-stats_main">
          <span class="__info-stats_secondary" translate="common.caseStudyItem.cooperation"></span>
          <span [innerHTML]="('common.caseStudyItem.cooperationPlural' | translate: { years: data.coopLength }) | safe: 'html'"></span>
        </h3>
      </div>

      <div fxFlex="35" *ngIf="data.coopText && !data.coopLength">
        <h3 class="__info-stats_main">
          <span class="__info-stats_secondary" translate="common.caseStudyItem.cooperation"></span>
          <span [innerHTML]="data.coopText"></span>
        </h3>
      </div>

    </div>

    <div class="__item_button-wrap">
      <button
        [routerLink]="!!data.id ? [ '/', 'references', data.id] : undefined"
        mat-button
        class="c-button  c-button--dense  c-button--gamma  c-button--full">
        <span translate="common.caseStudyItem.caseStudyButton"></span>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

  </div>
</mat-card>
