import orderBy from 'lodash-es/orderBy';
import head from 'lodash-es/head';
import last from 'lodash-es/last';

export const getLowHighPrice = (prices: Array<{ price: number[] }>) => {
  const orderedPrices = orderBy(prices.map((itm) => ({
    ...itm,
    price: itm && itm.price
      ? itm.price.filter((s) => s !== null)
      : []
  })), [ (item) => Math.min(...item.price) ]);

  if (!orderedPrices.length) {
    return { from: undefined, to: undefined };
  }

  return {
    from: Math.min(...head(orderedPrices).price),
    to: Math.max(...last(orderedPrices).price)
  };
};
