<vsh-scroll-scene>
  <vsh-section class="__top">

    <vsh-wrap class="__top_card">
      <div
        class="__top_content"
        [ngClass]="{
          '__top-content--xs': media.isActive('xs'),
          '__top-content--sm': media.isActive('sm')
        }">

        <!-- claim -->
        <h1
          [vshScrollElement]="{
            from: {
              transform: 'translate3d(0px, 0px, 0px)'
            },
            to: {
              transform: 'translate3d(0px, -15px, 0px)'
            }
          }"
          class="__top_title  __top_anim">
          <div class="__top_title-line">
            <span class="__top_title-block" translate="routed.home.claimPre"></span>&nbsp;<vsh-claim-items class="__top_title-block"></vsh-claim-items>
          </div>
          <span class="__top_title-block" translate="routed.home.claimPost"></span>
        </h1>

        <!-- buttons -->
        <div
          fxLayout="row grid"
          fxLayoutGap="24px"
          fxLayoutGap.lt-sm="0px"
          class="__top_buttons  __top_anim"
          [class.__top_buttons--lt-sd]="media.isActive('lt-sd')"
          [vshScrollElement]="{
            from: {
              transform: 'translate3d(0px, 0px, 0px)'
            },
            to: {
              transform: 'translate3d(0px, -25px, 0px)'
            }
          }">

          <div
            class="__top_button-flex"
            fxFlex="none"
            [fxFlex.lt-sd]="100">
            <a
              pageScroll
              href="#services"
              angulartics2On="click"
              angularticsAction="Click"
              [angularticsProperties]="{
                'event_category': 'služby a řešení',
                'event_label': 'https://vshosting.cz'
              }"
              mat-button
              class="[ c-button  c-button--primary  c-button--beta ]  [ u-text--uppercase ]  [ __top_button ]">
              <!--<mat-icon class="__top_buttons-icon  __top_buttons-icon--main">apps</mat-icon>&nbsp;--><span translate="routed.home.mainServicesButton"></span>
            </a>
          </div>

          <div
            class="__top_button-flex"
            fxFlex="none"
            [fxFlex.lt-sd]="100">
            <button
              mat-stroked-button
              (click)="onContact()"
              angulartics2On="click"
              angularticsAction="Contact_Form_Initiated"
              [angularticsProperties]="{
                'event_category': 'kontaktujte nás',
                'event_label': 'https://vshosting.cz'
              }"
              class="[ c-button ]  __top_button">
              <mat-icon class="__top_buttons-icon  __top_buttons-icon--alt">mail_outline</mat-icon>&nbsp;<span translate="routed.home.mainSupportButton"></span>
              <!-- <mat-icon svgIcon="intercom-chat" class="__top_buttons-icon  __intercom-icon"></mat-icon>&nbsp;<span translate="routed.home.mainSupportButton"></span> -->
            </button>
          </div>

        </div>

        <!-- separator -->
        <div
          class="__top_separator  __top_anim"
          [vshScrollElement]="{
            from: {
              transform: 'translate3d(0px, 0px, 0px)'
            },
            to: {
              transform: 'translate3d(0px, -30px, 0px)'
            }
          }">
          <vsh-tilde color="beta"></vsh-tilde>
        </div>

        <!-- perex -->
        <div class="__top_perex">

          <p
            class="[ c-perex ]  [ __top_perex-content ]  [ __top_anim ]"
            [vshScrollElement]="{
              from: {
                transform: 'translate3d(0px, 0px, 0px)'
              },
              to: {
                transform: 'translate3d(0px, -30px, 0px)'
              }
            }"
            [innerHTML]="'routed.home.perexFirst' | translate">
          </p>

          <div
            [vshScrollElement]="{
              from: {
                transform: 'translate3d(0px, 0px, 0px)'
              },
              to: {
                transform: 'translate3d(0px, -40px, 0px)'
              }
            }"
            class="__top_clients  __top_anim">
            <!--
            <img class="__top_client" src="/assets/clients/csfd.svg" />
            <img class="__top_client" src="/assets/clients/symbio.svg" />
            <img class="__top_client" src="/assets/clients/shopsys.svg" />
            -->
            <img class="__top_client" src="/assets/references-logos/csfd.svg" />
            <img class="__top_client" src="/assets/references-logos/symbio.svg" />
            <img class="__top_client" src="/assets/references-logos/notino.svg" />
          </div>

          <p
            class="[ c-perex ]  [ __top_perex-content ]  [ __top_anim ]"
            [vshScrollElement]="{
              from: {
                transform: 'translate3d(0px, 0px, 0px)'
              },
              to: {
                transform: 'translate3d(0px, -35px, 0px)'
              }
            }"
            [innerHTML]="'routed.home.perexSecond' | translate">
          </p>

        </div>

        <button
          pageScroll
          href="#about"
          class="[ c-button  c-button--dense  c-button--gamma ]  __top_more-button"
          mat-button>
          <span translate="routed.home.topNextButton"></span>
          <mat-icon>expand_more</mat-icon>
        </button>

      </div>
    </vsh-wrap>

    <!-- <vsh-cable-two class="__top-cable  __top-cable--first"></vsh-cable-two> -->
    <vsh-cable-two class="__top-cable  __top-cable--second"></vsh-cable-two>

  </vsh-section>
</vsh-scroll-scene>

<!-- services -->
<vsh-section
  id="services"
  [background]="'light'"
  [whitespace]="'big'"
  [name]="'routed.home.servicesTitle' | translate">

  <ng-template vshSectionDesc>
    <vsh-about-pop-wrap>
      <div [innerHTML]="'routed.home.servicesDesc' | translate | safe: 'html'"></div>
    </vsh-about-pop-wrap>
  </ng-template>

  <ng-template vshSectionHeaderContent>

    <vsh-tailored-button
      angulartics2On="click"
      angularticsAction="Intercom_Chat_Initiated"
      [angularticsProperties]="{
        'event_category': 'nechcete hledat?',
        'event_label': 'https://vshosting.cz'
      }"
      (clicked)="intercom.showNewMessage()"
      [baseText]="'routed.home.servicesCtaButtonBase' | translate"
      [moreText]="'routed.home.servicesCtaButtonMore' | translate">
    </vsh-tailored-button>

  </ng-template>

  <vsh-wrap>
    <vsh-services></vsh-services>
  </vsh-wrap>

</vsh-section>

<vsh-section whitespace="big">
  <vsh-clients-strip
    [showCaseStudies]="true"
    [data]="caseStudies$ | async">
  </vsh-clients-strip>
</vsh-section>

<!-- about -->
<vsh-section
  id="about"
  background="grey"
  whitespace="big">
  <vsh-wrap [class.__about-wrap--lt-sm]="media.isActive('lt-sm')">
    <vsh-about-strip></vsh-about-strip>
  </vsh-wrap>
</vsh-section>

<!-- client zone -->
<vsh-section
  whitespace="big"
  background="gamma"
  color="beta"
  [name]="'routed.home.clientZoneTitle' | translate">

  <span *vshSectionDesc class="u-color--copyInvert">
    <span [innerHTML]="'routed.home.clientZoneDesc' | translate"></span>
  </span>

  <vsh-wrap>
    <vsh-browser class="__clientzone_browser">
      <vsh-gallery
        class="__clientzone_video"
        [ratio]="1800/1149"
        [data]="[
          { id: 'cz2', url: '/assets/clientzone2.jpg' },
          { id: 'cz1', url: '/assets/clientzone.jpg' }
        ]">
      </vsh-gallery>
    </vsh-browser>
  </vsh-wrap>

</vsh-section>

<!-- blog -->
<vsh-section
  *ngIf="(blogs$ | async) as blogs"
  whitespace="big"
  [name]="'routed.home.blogTitle' | translate">

  <vsh-wrap
    class="__blogs"
    [class.__blogs--lt-sm]="media.isActive('lt-sm')">
    <vsh-blog-strip [blogs]="blogs"></vsh-blog-strip>
  </vsh-wrap>

</vsh-section>
