import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const CS = {
  caseStudyButton: 'Zobrazit případovou studii',
  cooperationPlural: '{years, plural, =1{1 rok} =2{2 roky} =3{3 roky} =4{4 roky} other{# let}} ',
  services: 'využité služby',
  cooperation: 'spolupráce'
};

const EN = {
  caseStudyButton: 'Show case study',
  cooperationPlural: '{years, plural, =1{1 year} other{# years}} ',
  services: 'services used',
  cooperation: 'cooperation'
};

const SK = {
  caseStudyButton: 'Zobraziť prípadovú štúdiu',
  cooperationPlural: '{years, plural, =1{1 rok} =2{2 roky} =3{3 roky} =4{4 roky} other{# let}} ',
  services: 'využité služby',
  cooperation: 'spolupráca'
};

const PL = {
  caseStudyButton: 'Wyświetlić studium przypadku',
  cooperationPlural: '{years, plural, =1{1 rok} =2{2 lata} =3{3 lata} =4{4 lata} other{# lat}} ',
  services: 'wykorzystane usługi',
  cooperation: 'współpraca'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class CaseStudyItemTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        caseStudyItem: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        caseStudyItem: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        caseStudyItem: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        caseStudyItem: PL
      }
    }, true);

  }
}
