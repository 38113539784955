import { ElementRef, EventEmitter } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { map, auditTime } from 'rxjs/operators';
import { PageScrollService } from 'ngx-page-scroll-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/scrolling";
import * as i2 from "ngx-page-scroll-core";
import * as i3 from "@angular/common";
export class ScrollStateService {
    constructor(_scrollDispatcher, _scroll, _document) {
        this._scrollDispatcher = _scrollDispatcher;
        this._scroll = _scroll;
        this._document = _document;
        this._scrollPosition = {
            top: 0,
            left: 0
        };
        this._scrolled$ = this._scrollDispatcher.scrolled();
        this._scrollPos$ = this._scrolled$.pipe(map(() => this._calculateScrollPosition()));
        this.onDone$ = new EventEmitter();
        this._scrollPos$.subscribe((v) => this._scrollPosition = v);
    }
    getScrollPosition() {
        return this._scrollPosition;
    }
    getScrolled$(audit) {
        if (audit) {
            return this._scrollPos$.pipe(auditTime(audit));
        }
        return this._scrollPos$;
    }
    scrollTo(ref, offset = 0) {
        this._scroll.scroll({
            document: this._document,
            scrollTarget: ref.nativeElement
                ? ref.nativeElement
                : ref,
            scrollOffset: offset,
            scrollFinishListener: this.onDone$
        });
    }
    _calculateScrollPosition() {
        const top = window.pageYOffset || document.documentElement.scrollTop;
        const left = window.pageXOffset || document.documentElement.scrollLeft;
        return { top, left };
    }
}
ScrollStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollStateService_Factory() { return new ScrollStateService(i0.ɵɵinject(i1.ScrollDispatcher), i0.ɵɵinject(i2.PageScrollService), i0.ɵɵinject(i3.DOCUMENT)); }, token: ScrollStateService, providedIn: "root" });
