import { Component, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { ActiveRouteDataService } from 'app/services';

@Component({
  selector: 'vsh-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  // private _scrolled$ = this._scrollDispatcher.scrolled(0);
  // private _scrollPosition = {
  //   top: 0,
  //   left: 0,
  //   max: 0
  // };
  mode: 'full' | 'compact' = 'full';
  display = 'block';
  // visible$ = this._scrolled$.pipe(
  //   map(() => this._calculateScrollPosition()),
  //   map((d) => !!((d.top + 1500) > d.max)),
  //   distinctUntilChanged(),
  // );

  @HostBinding('class.is-full')
  get classIsFull() {
    return this.mode === 'full';
  }

  @HostBinding('class.is-compact')
  get classIsCompact() {
    return this.mode === 'compact';
  }

  constructor(
    public media: MediaObserver,
    private _scrollDispatcher: ScrollDispatcher,
    private _cdRef: ChangeDetectorRef,
    private _activeRouteData: ActiveRouteDataService
  ) {
    this._activeRouteData
      .getData$()
      .subscribe((data: any) => {
        setTimeout(() => {
          this.mode = data.footerMode || 'full';

          this._cdRef.markForCheck();
          this._cdRef.detectChanges();
        });
      });

    // this.visible$.subscribe((s) => {
    //   setTimeout(() => {
    //     this.display = s ? 'block' : 'none';

    //     this._cdRef.markForCheck();
    //     this._cdRef.detectChanges();
    //   });
    // });
  }

  // private _calculateScrollPosition() {
  //   const top = window.pageYOffset || document.documentElement.scrollTop;
  //   const left = window.pageXOffset || document.documentElement.scrollLeft;
  //   const scrollHeight = document.documentElement.scrollHeight;

  //   return { top, left, max: scrollHeight };

  // }
}
