import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReferenceEntity } from './references-core.model';

@Injectable({ providedIn: 'root' })
export class ReferencesCoreApi {
  constructor(private _http: HttpClient) { }

  list$() {
    return this._http.get<ReferenceEntity[]>('/assets/references.json');
  }
}
