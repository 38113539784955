import { Action } from '@app/models';
import { CaseStudyEntity } from './case-studies-core.model';

export enum ActionTypes {
  Init = '[Case Studies Core] Init',

  ListRequest = '[Case Studies Core] List Request',
  ListFail = '[Case Studies Core] List Fail',
  ListLocalSuccess = '[Case Studies Core] List Local Success'
}

export class Init implements Action {
  readonly type = ActionTypes.Init;
}

export class ListRequest implements Action {
  readonly type = ActionTypes.ListRequest;
}

export class ListFail implements Action {
  readonly type = ActionTypes.ListFail;
}

export class ListLocalSuccess implements Action {
  readonly type = ActionTypes.ListLocalSuccess;

  constructor(public payload: CaseStudyEntity[]) { }
}

export type Actions
  = Init
  | ListRequest
  | ListFail
  | ListLocalSuccess;
