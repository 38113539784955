<svg
  class="__svg"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 12.24 4.67">
  <path
    class="__tilde"
    [ngClass]="{
      '__tilde--alpha': color === 'alpha',
      '__tilde--beta': color === 'beta',
      '__tilde--gamma': color === 'gamma',
      '__tilde--black': color === 'black',
      '__tilde--animated': !color
    }"
    d="M8.34 4.67a3 3 0 0 1-1.41-.31 8.64 8.64 0 0 1-1.11-.68L4.86 3a1.71 1.71 0 0 0-1-.31 1.56 1.56 0 0 0-1 .39A4.18 4.18 0 0 0 2 4.2L0 2.67A6.53 6.53 0 0 1 1.94.6a3.73 3.73 0 0 1 2-.6 3 3 0 0 1 1.38.31 9.25 9.25 0 0 1 1.1.69l1 .67a1.69 1.69 0 0 0 1 .31 1.5 1.5 0 0 0 1-.39 4 4 0 0 0 .84-1.12l2 1.53a6.64 6.64 0 0 1-1.96 2.07 3.7 3.7 0 0 1-2 .6"
  />
</svg>
