import { Component, ChangeDetectionStrategy, ElementRef } from '@angular/core';

@Component({
  selector: 'vsh-wrap',
  templateUrl: './wrap.component.html',
  styleUrls: [ './wrap.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrapComponent {
  constructor(public elementRef: ElementRef) { }
}
