import { Actions, ActionTypes, } from './blog-core.action';
import { postsAdapter, tagsAdapter } from './blog-core.adapter';
import { BlogCoreState } from './blog-core.model';

export const initialState = {
  posts: postsAdapter.getInitialState(),
  totalPosts: 0,
  tags: tagsAdapter.getInitialState()
};

export function reducer(
  state = initialState,
  action: Actions
): BlogCoreState {

  switch (action.type) {
    case ActionTypes.ListLocalSuccess:
      return {
        ...state,
        posts: postsAdapter.addAll(action.payload, state.posts),
        totalPosts: action.meta
      };

    case ActionTypes.DetailLocalSuccess:
      return {
        ...state,
        posts: postsAdapter.upsertOne(action.payload.changes, state.posts)
      };

    case ActionTypes.TagsLocalSuccess:
      return {
        ...state,
        tags: tagsAdapter.upsertMany(action.payload, state.tags)
      };
  }

  return state;
}
