import { Router, NavigationEnd } from '@angular/router';
import { map, filter, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class ActiveRouteDataService {
    constructor(_router) {
        this._router = _router;
        this._data = this._router
            .events
            .pipe(filter((e) => !!(e instanceof NavigationEnd)), map(() => {
            let activeRoute = this._router.routerState.snapshot.root;
            while (activeRoute.firstChild) {
                activeRoute = activeRoute.firstChild;
            }
            return activeRoute.data ? activeRoute.data : activeRoute.parent.data;
        }), shareReplay(1));
    }
    getData$() {
        return this._data;
    }
}
ActiveRouteDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActiveRouteDataService_Factory() { return new ActiveRouteDataService(i0.ɵɵinject(i1.Router)); }, token: ActiveRouteDataService, providedIn: "root" });
