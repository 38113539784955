import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  meta: {
    description: `Máme špičkovou infrastrukturu a 13 let zkušeností. Jsme největší poskytovatel
      managed & cloud serverů ve střední Evropě. Poskytujeme cloud server, managed server, dedikovaný server nebo Kubernetes platformu.`,
    ogTitle: 'Jsme největší poskytovatel managed & cloud služeb ve střední Evropě',
    image: '/assets/meta/homepage_cs.png'
  },
  claimPre: `Poslední`,
  claimPost: `který kdy budete potřebovat.`,
  mainServicesButton: 'Služby a řešení',
  mainSupportButton: 'Kontaktujte nás',
  perexFirst: `Jsme experti na <a href="/services/managed-servers">managed služby</a> s více než <strong>13 lety zkušeností</strong>.
    Společně najdeme vhodné řešení, <strong>zajistíme migraci</strong> a váš projekt budeme
    <strong>spravovat, optimalizovat a&nbsp;škálovat</strong>, ať už poroste jakkoliv.`,
  perexSecond: `Úspěšně u nás běží více než <strong>40 % českých a slovenských e-shopů</strong>, vývojářská studia i korporace.
    Díky <strong>skvělému technickému zázemí</strong> a zkušenému týmu si poradíme i s velmi náročnými projekty
    a <strong>požadavky řešíme do 15 minut</strong>.`,
  topNextButton: 'Více o datacentru, konektivitě a týmu',
  servicesTitle: 'Naše služby a řešení',
  servicesDesc: `Všechny služby jsou hostované v našem moderním
    <a target="_blank" data-about-pop="datacenter" href="/tech">datacentru</a>
    se 100Gbps <a target="_blank" data-about-pop="connectivity" href="/tech">konektivitou</a>
    a 24/7 se o ně stará zkušený <a target="_blank" data-about-pop="about" href="/about">tým</a>.`,
  servicesCtaButtonBase: 'Nechcete hledat?',
  servicesCtaButtonMore: 'Pomůžeme vám online vybrat vhodné řešení',
  clientZoneTitle: 'Unikátní klientská zóna',
  clientZoneDesc: `Pohodlné <strong>ovládání serverů, sledování grafů</strong> a přímý kontakt s podporou,
    která na požadavky reaguje do 15&nbsp;minut.`,
  blogTitle: 'Blog',
  blogMoreButton: 'Zobrazit starší články'
};

const EN = {
  meta: {
    description: `We have top of the line infrastructure and 13 years of experience.
      We are the largest provider of managed & cloud servers in central Europe. We provide cloud servers, managed servers,
      dedicated servers or Kubernetes platform.`,
    ogTitle: 'We are the largest provider of managed & cloud services in central Europe',
    image: '/assets/meta/homepage_en.png'
  },
  claimPre: `The last`,
  claimPost: `you will ever have to migrate to.`,
  mainServicesButton: 'Services & solutions',
  mainSupportButton: 'Contact Us',
  perexFirst: `We are experts on <a href="/services/managed-servers">managed services</a> with more than
    <strong>13&nbsp;years of experience</strong>. Together, we’ll find the best solution for you, take care of
    <strong>migration of your project</strong> as well as <strong>manage, optimize and scale it</strong>, no matter how big it gets.`,
  perexSecond: `<strong>Over 40 % of Czech and Slovak e-shops</strong>, as well as numerous <strong>development studios,
    and corporations</strong> run on one of our services. Thanks to our <strong>great technical background</strong> and a highly
    experienced team,  we are able to take on even very demanding projects and solve requests <strong>within 15 minutes</strong>.`,
  topNextButton: 'More about our data center, connectivity, and team',
  servicesTitle: 'Services and solutions',
  servicesDesc: `All services are hosted in our modern <a target="_blank" data-about-pop="datacenter" href="/tech">data center</a>
    utilising 100Gbps <a target="_blank" data-about-pop="connectivity" href="/tech">connectivity</a>
    and taken care of 24/7 by our experienced <a target="_blank" data-about-pop="about" href="/about">team</a>.`,
  servicesCtaButtonBase: `Don't want to search?`,
  servicesCtaButtonMore: `We'll help you find the right solution online`,
  clientZoneTitle: 'Unique Client Zone',
  clientZoneDesc: `Comfortable server management, <strong>monitoring of graphs</strong>, and <strong>direct contact with our
    support</strong>, which <strong>responds to requests within 15 minutes</strong>. All through our user friendly client zone.`,
  blogTitle: `Blog`,
  blogMoreButton: 'Show older blog posts'
};

const SK = {
  meta: {
    description: `Máme špičkovú infraštruktúru a 13 rokov skúseností. Sme najväčší poskytovateľ
      managed & cloud serverov v strednej Európe. Poskytujeme cloud server, managed server, dedikovaný server alebo Kubernetes platformu.`,
    ogTitle: 'Sme najväčší poskytovateľ managed & cloud služieb v strednej Európe',
    image: '/assets/meta/homepage_sk.png'
  },
  claimPre: `Posledný`,
  claimPost: `ktorý kedy budete potrebovať.`,
  mainServicesButton: 'služby s riešenia',
  mainSupportButton: 'Kontaktujte nás',
  perexFirst: `Sme experti na <a href="/services/managed-servers">managed služby</a> s viac ako  <strong>13&nbsp;rokmi skúseností</strong>.
    Spoločne nájdeme vhodné riešenie, <strong>zabezpečíme migráciu</strong> a Váš projekt budeme
    <strong>spravovať, optimalizovať a škálovať</strong>,nech už porastie akokoľvek.`,
  perexSecond: `Úspešne u nás beží <strong>viac ako 40% českých a slovenských e-shopov, vývojárske štúdiá aj korporácie</strong>.
    Vďaka <strong>skvelému technickému zázemiu</strong> a skúsenému tímu si poradíme aj s veľmi náročnými projektami a požiadavky
    riešime <strong>do 15 minút</strong>.`,
  topNextButton: 'Viac o datacentre, konektivite a tíme',
  servicesTitle: 'Služby s riešenia',
  servicesDesc: `Všetky služby sú hosťované v našom modernom <a target="_blank" data-about-pop="datacenter" href="/tech">datacentre</a>
    s 100Gbps <a target="_blank" data-about-pop="connectivity" href="/tech">konektivitou</a>
    a 24/7 sa o ne stará skúsený <a target="_blank" data-about-pop="about" href="/about">tím</a>.`,
  servicesCtaButtonBase: `Nechcete hľadať?`,
  servicesCtaButtonMore: `Pomôžeme vám vybrať vhodné riešenie online`,
  clientZoneTitle: 'Unikátna klientská zóna',
  clientZoneDesc: `Pohodlné <strong>ovládanie serverov</strong>, <strong>sledovanie grafov</strong>
    a priamy kontakt s podporou, ktorá na požiadavky <strong>reaguje do 15 minút</strong>.`,
  blogTitle: `Blog`,
  blogMoreButton: 'Zobraziť staršie články'
};

const PL = {
  meta: {
    description: `Posiadamy najnowocześniejszą infrastrukturę i 13 lat doświadczenia. Jesteśmy największym dostawcą
      i serwery w chmurze w Europie Środkowej. Zapewniamy serwer w chmurze, serwer zarządzany, serwer dedykowany lub platformę Kubernetes.`,
    ogTitle: 'Jesteśmy największym dostawcą usług zarządzanych i chmurowych w Europie Środkowej',
    image: '/assets/meta/homepage_pl.png'
  },
  claimPre: `Ostatni`,
  claimPost: `który kiedykolwiek będziesz potrzebować.`,
  mainServicesButton: 'usługi i rozwiązania',
  mainSupportButton: 'Kontaktuj nas',
  perexFirst: `Jesteśmy ekspertami w zakresie <a href="/services/managed-servers">usług managed</a> z
    ponad <strong>13-letnim doświadczeniem</strong>.Wspólnie znajdujemy odpowiednie rozwiązanie, <strong>zapewniamy migrację</strong> i
    będziemy <strong>zarządzać Twoim projektem, optymalizować i skalować</strong> bez względu na wszystko.`,
  perexSecond: `<strong>Ponad 40% czeskich i słowackich sklepów internetowych, studiów programistycznych i korporacji</strong> działa z
    powodzeniem w naszym kraju. Dzięki <strong>doskonałemu zapleczu technicznemu</strong> i doświadczonemu zespołowi jesteśmy w stanie
    obsłużyć nawet bardzo wymagające projekty i rozwiązujemy wymagania <strong>w ciągu 15 minut</strong>.`,
  topNextButton: 'Więcej o centrum danych, połączeniu i zespole',
  servicesTitle: 'Usługi i rozwiązania',
  servicesDesc: `Wszystkie usługi są hostowane w naszym nowoczesnym
    <a target="_blank" data-about-pop="datacenter" href="/tech">centrum danych</a> z
    <a target="_blank" data-about-pop="connectivity" href="/tech">łącznością</a> 100Gbps i 24/7 przez doświadczony
    <a target="_blank" data-about-pop="about" href="/about">zespół</a>.`,
  servicesCtaButtonBase: `Nie chcesz szukać?`,
  servicesCtaButtonMore: `Pomożemy Ci wybrać odpowiednie rozwiązanie online.`,
  clientZoneTitle: 'Unikalna strefa klienta',
  clientZoneDesc: `<strong>Wygodne sterowanie serwerami</strong>, <strong>śledzenie wykresów</strong> i bezpośredni
    kontakt ze wsparciem, które odpowiada na żądania <strong>w ciągu 15 minut</strong>.`,
  blogTitle: `Blog`,
  blogMoreButton: 'Wyświetlić starsze artykuły'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class HomeTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      routed: {
        home: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      routed: {
        home: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      routed: {
        home: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      routed: {
        home: PL
      }
    }, true);

  }
}
