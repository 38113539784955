import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RevealAnimationComponent } from './reveal-animation.component';

@NgModule({
  declarations: [ RevealAnimationComponent ],
  imports: [ CommonModule ],
  exports: [ RevealAnimationComponent ],
})
export class RevealAnimationModule { }
