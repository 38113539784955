import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vsh-break',
  templateUrl: './break.component.html',
  styleUrls: [ './break.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakComponent {
  @Input()
  size = 30;
}
