import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CaseStudyEntity } from './case-studies-core.model';

@Injectable({ providedIn: 'root' })
export class CaseStudiesCoreApi {
  constructor(private _http: HttpClient) { }

  list$() {
    return this._http.get<CaseStudyEntity[]>('/assets/case-studies.json');
  }
}
