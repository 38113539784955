<ng-content></ng-content>

<vsh-popover
  verticalAlign="below"
  scrollStrategy="close"
  #datacenterPopRef>
  <div class="__pop">

    <div class="__pop_image  __pop_image--datacenter"></div>

    <h3 class="__pop_title" [innerHTML]="'common.aboutPop.datacenter.title' | translate"></h3>

    <p class="__pop_desc" [innerHTML]="'common.aboutPop.datacenter.desc' | translate"></p>

    <div>
      <span class="__pop-open  c-button  c-button--mini">
        <mat-icon class="__pop-open_icon">open_in_new</mat-icon>&nbsp;<span translate="common.aboutPop.openButton"></span>
      </span>
    </div>

  </div>
</vsh-popover>

<vsh-popover
  verticalAlign="below"
  scrollStrategy="close"
  #connectivityPopRef>
  <div class="__pop">

    <div class="__pop_image __pop_image--connectivity">
      <div
        fxLayoutAlign="center center"
        fxLayoutGap="12px grid"
        fxLayoutGap.lt-md="0px grid"
        fxLayout="row wrap">

        <div fxFlex="50">
          <img class="__connectivity-logo" src="/assets/nix_hor.svg" />
        </div>

        <div fxFlex="50">
          <img class="__connectivity-logo" src="/assets/nixsk_hor.svg" />
        </div>

        <div fxFlex="50">
          <img class="__connectivity-logo" src="/assets/telia.svg" />
        </div>

        <div fxFlex="50">
          <img class="__connectivity-logo" src="/assets/cogent.png" />
        </div>

      </div>
    </div>

    <h3 class="__pop_title" [innerHTML]="'common.aboutPop.connectivity.title' | translate"></h3>

    <p class="__pop_desc" [innerHTML]="'common.aboutPop.connectivity.desc' | translate"></p>

    <div>
      <span class="__pop-open  c-button  c-button--mini">
        <mat-icon class="__pop-open_icon">open_in_new</mat-icon>&nbsp;<span translate="common.aboutPop.openButton"></span>
      </span>
    </div>

  </div>
</vsh-popover>

<vsh-popover
  verticalAlign="below"
  scrollStrategy="close"
  #aboutPopRef>
  <div class="__pop">

    <div class="__pop_image  __pop_image--team"></div>

    <h3 class="__pop_title" translate="common.aboutPop.team.title"></h3>

    <p class="__pop_desc" [innerHTML]="'common.aboutPop.team.desc' | translate"></p>

    <div>
      <span class="__pop-open  c-button  c-button--mini">
        <mat-icon class="__pop-open_icon">open_in_new</mat-icon>&nbsp;<span translate="common.aboutPop.openButton"></span>
      </span>
    </div>

  </div>
</vsh-popover>
