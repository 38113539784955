import { Component, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '@app/core/meta';
import { DialogOpen } from '@zerops/fe/dialog';
import { combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AppState } from '@app/models';
import { selectAllBlogs, blogProjected } from '@app/core/blog-core';
import { BlogStripComponent } from '@app/common/blog-strip';
import { IntercomApi } from '@app/core/intercom';
import { selectCaseStudiesCoreList } from '@app/core/case-studies-core';
import { selectSettingsCoreLanguage } from '@app/core/settings-core';

@Component({
  selector: 'vsh-home-page',
  templateUrl: './home.page.html',
  styleUrls: [ './home.page.scss' ]
})
export class HomePage {
  // # Data
  // -- sync
  activeServiceColumn: string;

  // -- angular
  @ViewChild(BlogStripComponent, { static: false })
  blogStripRef: BlogStripComponent;

  // -- async
  blogs$ = this._store.pipe(
    select(selectAllBlogs),
    map((items) => items.map(blogProjected))
  );

  caseStudies$ = combineLatest(
    this._store.pipe(select(selectCaseStudiesCoreList)),
    this._store.pipe(select(selectSettingsCoreLanguage))
  ).pipe(
    map(([ caseStudies, lang ]) => caseStudies.filter((item) => item.language === lang))
  );

  private _metaRobotsParams = [ 'p', 's', 'page_id' ];

  constructor(
    public media: MediaObserver,
    public platform: Platform,
    public intercom: IntercomApi,
    private _store: Store<AppState>,
    private _meta: MetaService,
    private _translate: TranslateService,
    private _activatedRoute: ActivatedRoute
  ) {

    // meta
    this._translate
      .get([
        'routed.home.meta.ogTitle',
        'routed.home.meta.description',
        'routed.home.meta.image'
      ])
      .subscribe((meta: any) => {
        this._meta.set({
          title: '',
          ogTitle: meta['routed.home.meta.ogTitle'],
          description: meta['routed.home.meta.description'],
          image: meta['routed.home.meta.image']
        });
      });

      // meta robots
      this._activatedRoute.queryParams
        .pipe(filter((p) => Object.entries(p).length !== 0))
        .subscribe((params) => this._setMetaRobots(params, this._metaRobotsParams));

  }

  onContact() {
    this._store.dispatch(
      new DialogOpen(
        'vsh:contact',
        {
          type: 'contact'
        }
      )
    );
  }

  trackBy(index: number) {
    return index;
  }

  trackIntercomEvent(event: string) {
    (window as any).Intercom('trackEvent', event);
  }

  private _setMetaRobots(params: Params, metaRobotsParams: string[]) {
    if (metaRobotsParams.some((p) => params[p])) {
      this._meta.set({ robots: true });
    }
  }

}

