import { Actions, ActionTypes } from './services-core.action';
import { ServicesCoreState } from './services-core.model';

export const initialState: ServicesCoreState = {
  items: []
};

export function reducer(
  state = initialState,
  action: Actions
): ServicesCoreState {

  switch (action.type) {
    case ActionTypes.ListLocalSuccess:
      return {
        ...state,
        items: action.payload
      };
  }

  return state;
}
