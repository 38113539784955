import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vsh-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input()
  size: number;

  @Input()
  url: string;
}
