<vsh-app-bar
  class="__app-bar"
  [menuOpen]="menuOpen && !platform.FIREFOX"
  [@appBarAnimation]="{
    value: prepareRoute(outlet),
    params: routeAnimationParams
  }"
  [class.has-menu-open]="menuOpen"
  (menuClicked)="menuOpen = !menuOpen">
</vsh-app-bar>

<div
  class="__content  c-app-content"
  [class.__content--lt-lg]="media.isActive('lt-lg')"
  [class.has-menu-open]="menuOpen && !platform.FIREFOX">
  <div
    class="__content-anim"
    (@routeAnimation.start)="routeAnimationStart()"
    (@routeAnimation.done)="routeAnimationDone()"
    [@routeAnimation]="{
      value: prepareRoute(outlet),
      params: routeAnimationParams
    }">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<div
  class="__menu"
  #menuRef
  [class.has-menu-open]="menuOpen"
  [@menuAnimation]="menuOpen ? 'open' : 'closed'">
  <div
    class="__menu_content"
    [class.has-menu-open]="menuOpen">
    <vsh-menu
      [open]="menuOpen"
      [language]="language$ | async"
      [serviceItems]="menuServicesItems$ | async"
      (closeClicked)="menuOpen = !menuOpen">
    </vsh-menu>
  </div>
</div>

<vsh-footer
  class="__footer"
  [class.__footer--lt-lg]="media.isActive('lt-lg')"
  [class.has-menu-open]="menuOpen">
</vsh-footer>

<vsh-contact-pop></vsh-contact-pop>
