import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WrapModule } from '@app/common/wrap';
import { TildeModule } from '@app/common/tilde';
import { InvoiceBoxModule } from '@app/common/invoice-box';
import { AddressBoxModule } from '@app/common/address-box';
import { SectionModule } from '@app/common/section';
import { LogoModule } from '@app/common/logo';
import { SocialModule } from '@app/common/social';
import { FooterComponent } from './footer.component';
import { FooterTranslationsModule } from './footer.translations';

@NgModule({
  declarations: [ FooterComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    SectionModule,
    FooterTranslationsModule,
    WrapModule,
    LogoModule,
    SocialModule,
    TildeModule,
    InvoiceBoxModule,
    AddressBoxModule
  ],
  exports: [ FooterComponent ]
})
export class FooterModule { }
