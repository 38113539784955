import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { BlogEntityExtended } from '@app/core/blog-core';
import { HeroComponent } from '@app/common/hero';

@Component({
  selector: 'vsh-blog-strip',
  templateUrl: './blog-strip.component.html',
  styleUrls: [ './blog-strip.component.scss' ]
})
export class BlogStripComponent {
  @Input()
  blogs: BlogEntityExtended[];

  @ViewChildren(HeroComponent)
  heroRefs: QueryList<HeroComponent>;

  constructor(public media: MediaObserver) {}

  refreshScenes() {
    if (this.heroRefs) {
      this.heroRefs.forEach((ref) => {
        ref.refreshScenes();
      });
    }
  }

  trackBy(index: number) {
    return index;
  }
}
