import { NgModule } from '@angular/core';
import {
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

const CS = {
  favoriteTag: 'Oblíbené',
  detailButton: 'Detail služby',
  inquiryButton: 'Poptat službu',
  filterHint: `Kliknutím si zobrazíte položky podle typu služby.`
};

const EN = {
  filterHint: `Click to display items according to service type.`,
  favoriteTag: 'Favorite',
  detailButton: 'Service detail',
  inquiryButton: 'Inquire'
};

const SK = {
  filterHint: `Kliknutím zobrazíte položky podľa typu služby.`,
  favoriteTag: 'Obľúbené',
  detailButton: 'Detail služby',
  inquiryButton: 'Poslať dotaz'
};

const PL = {
  filterHint: `Kliknij, aby wyświetlić elementy według typu usługi.`,
  favoriteTag: 'Ulubione',
  detailButton: 'Szczegóły usługi',
  inquiryButton: 'Wyślij zapytanie'
};

@NgModule({
  imports: [ TranslateModule.forChild() ],
  exports: [ TranslateModule ]
})
export class ServicesTranslationsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('cs', {
      common: {
        services: CS
      }
    }, true);

    this._translate.setTranslation('en', {
      common: {
        services: EN
      }
    }, true);

    this._translate.setTranslation('sk', {
      common: {
        services: SK
      }
    }, true);

    this._translate.setTranslation('pl', {
      common: {
        services: PL
      }
    }, true);

  }
}
