import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { DialogModule } from '@zerops/fe/dialog';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { Angulartics2Module } from 'angulartics2';
import { FlexModule } from '@app/core/flex';
import { WrapModule } from '@app/common/wrap';
import { TildeModule } from '@app/common/tilde';
import { SectionModule } from '@app/common/section';
import { ScrollSceneModule } from '@app/common/scroll-scene';
import { TagModule } from '@app/common/tag';
import { BrowserModule } from '@app/common/browser';
import { BreakModule } from '@app/common/break';
import { InViewModule } from '@app/common/in-view';
import { HeroModule } from '@app/common/hero';
import { PersonBadgeModule } from '@app/common/person-badge';
import { ServiceItemModule } from '@app/common/service-item';
import { AboutStripModule } from '@app/common/about-strip';
import { ClientsStripModule } from '@app/common/clients-strip';
import { BlogStripModule } from '@app/common/blog-strip';
import { RevealAnimationModule } from '@app/common/reveal-animation';
import { CablesModule } from '@app/common/cables';
import { AboutPopWrapModule } from '@app/common/about-pop-wrap';
import { ServicesModule } from '@app/common/services';
import { GalleryModule } from '@app/common/gallery';
import { PipesModule } from '@app/common/pipes';
import { TailoredButtonModule } from '@app/common/tailored-button';
import { ClaimItemsModule } from './modules';
import { HomePage } from './home.page';
import { HomeRoutesModule } from './home.routes';
import { HomeTranslationsModule } from './home.translations';
import { HomeEffect } from './home.effect';

@NgModule({
  declarations: [ HomePage ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    EffectsModule.forFeature([ HomeEffect ]),
    NgxPageScrollModule,
    Angulartics2Module,
    FlexModule,
    DialogModule,
    HomeTranslationsModule,
    HomeRoutesModule,
    TildeModule,
    ScrollSceneModule,
    SectionModule,
    BrowserModule,
    PersonBadgeModule,
    TagModule,
    InViewModule,
    RevealAnimationModule,
    BreakModule,
    HeroModule,
    PipesModule,
    AboutPopWrapModule,
    ServiceItemModule,
    WrapModule,
    CablesModule,
    GalleryModule,
    BlogStripModule,
    AboutStripModule,
    ClientsStripModule,
    ServicesModule,
    TailoredButtonModule,
    ClaimItemsModule
  ]
})
export class HomeModule { }
