import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AddressBoxComponent } from './address-box.component';
import { AddressBoxTranslationsModule } from './address-box.translations';

@NgModule({
  declarations: [ AddressBoxComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    AddressBoxTranslationsModule
  ],
  exports: [ AddressBoxComponent ]
})
export class AddressBoxModule { }
