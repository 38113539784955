<div *ngIf="priceFrom !== null && priceTo !== null">

  <div>

    <span class="__from" translate="app.from"></span>

    <vsh-price [price]="priceFrom" [currency]="currency"></vsh-price>

    <span *ngIf="noTax" class="__no_tax" translate="app.noTaxPrices"></span>

  </div>
  <!-- <span
    [class.is-block]="!inline"
    class="c-price-range__price-separator  __price-separator">
    -
  </span>

  <vsh-price [price]="priceTo" [currency]="currency"></vsh-price> -->

</div>
