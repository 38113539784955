import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vsh-person-badge',
  templateUrl: './person-badge.component.html',
  styleUrls: [ './person-badge.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonBadgeComponent {
  @Input()
  size: number;

  @Input()
  url: string;

  @Input()
  name: string;
}
