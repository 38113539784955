import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { defer, timer } from 'rxjs';
import { map, tap, delay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Init, ActionTypes, SetLanguage, SetCurrency } from './settings-core.action';
import { DomainLanguageMap, DomainCurrencyMap, LanguageDomainRedirectMap } from './settings-core.constant';
export class SettingsCoreEffect {
    constructor(_actions$, _translate) {
        this._actions$ = _actions$;
        this._translate = _translate;
        this._onInit$ = defer(() => timer(0)).pipe(map(() => new Init()));
        this._onSetDefaultLanguage$ = this._actions$.pipe(ofType(ActionTypes.Init), map(() => new SetLanguage(this._getDomainLanguage(), false)));
        this._onSetDefaultCurrency$ = this._actions$.pipe(ofType(ActionTypes.Init), map(() => new SetCurrency(this._getDomainCurrency())));
        this._onSetLanguage$ = this._actions$.pipe(ofType(ActionTypes.SetLanguage), tap(({ payload }) => this._translate.use(payload)), tap(({ payload, meta }) => {
            if (environment.redirectLanguage && LanguageDomainRedirectMap[payload] && meta) {
                window.location.replace(LanguageDomainRedirectMap[payload] + window.location.pathname);
            }
        }));
        this._onActivatePrerender$ = this._actions$.pipe(ofType(ActionTypes.Init), delay(100), tap(() => window.prerenderReady = true));
    }
    _getDomain() {
        return window.location.hostname;
    }
    _getDomainLanguage() {
        return DomainLanguageMap[this._getDomain()] || this._translate.getBrowserLang();
    }
    _getDomainCurrency() {
        return DomainCurrencyMap[this._getDomain()] || 'czk';
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SettingsCoreEffect.prototype, "_onInit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SettingsCoreEffect.prototype, "_onSetDefaultLanguage$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SettingsCoreEffect.prototype, "_onSetDefaultCurrency$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SettingsCoreEffect.prototype, "_onSetLanguage$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SettingsCoreEffect.prototype, "_onActivatePrerender$", void 0);
