import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'vsh-price',
  templateUrl: './price.component.html',
  styleUrls: [ './price.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceComponent {
  @Input()
  price: number;

  @Input()
  currency = 'czk';

  @Input()
  rounding = '1.0-0';

  @Input()
  forceSign = false;
}
