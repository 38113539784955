import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PersonBadgeModule } from '@app/common/person-badge';
import { BlogInfoComponent } from './blog-info.component';
import { BlogInfoTranslationsModule } from './blog-info.translations';

@NgModule({
  declarations: [ BlogInfoComponent ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    BlogInfoTranslationsModule,
    PersonBadgeModule
  ],
  exports: [ BlogInfoComponent ]
})
export class BlogInfoModule { }
