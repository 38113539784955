import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { of, defer, timer } from 'rxjs';
import { ServicesCoreApi } from './services-core.api';
import { ListRequest, ActionTypes, ListLocalSuccess, ListFail } from './services-core.action';

@Injectable()
export class ServicesCoreEffect {
  @Effect()
  private _onInit$ = defer(() => timer(0)).pipe(
    map(() => new ListRequest())
  );

  @Effect()
  private _onListRequest$ = this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    switchMap(() => this._api
      .list$()
      .pipe(
        map((data) => new ListLocalSuccess(data)),
        catchError((err) => {
          console.warn(err);
          return of(new ListFail());
        })
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _api: ServicesCoreApi
  ) { }
}
