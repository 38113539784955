import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CaseStudyCarouselComponent } from './case-study-carousel.component';
import { CaseStudyItemModule } from '../case-study-item';

@NgModule({
  declarations: [ CaseStudyCarouselComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    CaseStudyItemModule
  ],
  exports: [ CaseStudyCarouselComponent ]
})
export class CaseStudyCarouselModule { }
