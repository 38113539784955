export enum SettingsCoreEnum {
  Id = 'settingsCore'
}

export const DomainLanguageMap = {
  'vshosting.cz': 'cs',
  'vshosting.pl': 'pl',
  'vshosting.sk': 'sk',
  'vshosting.eu': 'en'
};

export const DomainCurrencyMap = {
  'vshosting.cz': 'czk',
  'vshosting.eu': 'eur',
  'vshosting.pl': 'pln',
  'vshosting.sk': 'eur'
};

export const LanguageDomainRedirectMap = {
  'cs': 'https://vshosting.cz',
  'en': 'https://vshosting.eu',
  'pl': 'https://vshosting.pl',
  'sk': 'https://vshosting.sk'
};
