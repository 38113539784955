import { NgModule } from '@angular/core';
import { BlogCoreModule } from './blog-core';
import { ServicesCoreModule } from './services-core';
import { SettingsCoreModule } from './settings-core';
import { ReferencesCoreModule } from './references-core';
import { CaseStudiesCoreModule } from './case-studies-core';
import { MetaModule } from './meta';
import { IntercomModule } from './intercom';

@NgModule({
  exports: [
    SettingsCoreModule,
    ServicesCoreModule,
    BlogCoreModule,
    ReferencesCoreModule,
    CaseStudiesCoreModule,
    IntercomModule,
    MetaModule
  ]
})
export class CoreModule {

}
